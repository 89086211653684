import {
  Box,
  Button,
  Card,
  Divider,
  Group,
  Image,
  Loader,
  LoadingOverlay,
  Modal,
  ScrollArea,
  SimpleGrid,
  Stack,
  Text,
  TextInput,
  createStyles,
} from "@mantine/core";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { useForm, yupResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Trash } from "tabler-icons-react";
import * as Yup from "yup";
import images from "../../assets/images/index";
import { insertNewFileThunk } from "../../store/slices/file";
import { editOfficeDataThunk } from "../../store/slices/office";
import CustomLoader from "../common/CustomLoader";

const useStyles = createStyles((theme) => ({
  input: {
    height: "auto",
    paddingTop: 18,
  },
  insertButton: {
    alignSelf: "flex-end",
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
  },
  scrollArea: {
    height: "100%",
  },
  root: { marginTop: 10 },
  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: theme.spacing.sm / 2,
    zIndex: 1,
  },
  insertButton: {
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
    color: theme.colors.colorWhite,
  },
  tableIconsButton: {
    padding: "0px",
    "&:hover": {
      backgroundColor: theme.colors.colorGray,
    },
  },
  tableIconsLogo: {
    color: theme.colors.colorDarkGray,
    "&:hover": {
      color: theme.colors.colorBlack,
    },
  },
  dropzoneHidden: {
    display: "none",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    border: "1px",
    borderColor: theme.colors.colorGray,
    borderStyle: "solid",
    borderRadius: "10px",
    width: "30%",
    height: "100%",
    marginLeft: "3px",
    marginBottom: "10px",
  },
  imageSection: {
    width: "100%",
    height: "60%",
    paddingTop: "10px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignContent: "center",
    overflow: "hidden",
    paddingRight: "10px",
    paddingLeft: "10px",
  },
  imgSize: {
    borderRadius: "30px",
  },
  titleSection: {
    width: "70%",
    height: "20%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    alignItems: "center",
    textAlign: "center",
    fontSize: "12px",
  },
  footerSection: {
    height: "8%",
    marginTop: "10px",
    width: "80%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "20px",
  },
  descriptionFont: {
    fontSize: "14px",
  },
  tableIconsButton: {
    padding: "0px",
    "&:hover": {
      backgroundColor: theme.colors.colorGray,
    },
  },
  tableIconsTrash: {
    color: theme.colors.colorBlack,
    "&:hover": {
      color: theme.colors.colorBlack,
    },
  },
  pictureCointainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "transparent",
  },
}));

const OfficeDetails = () => {
  const { t } = useTranslation();

  const { classes } = useStyles();

  const dispatch = useDispatch();
  const office = useSelector((state) => state.office.officesDataById);
  const loading = useSelector((state) => state.office.officeByIdLoading);

  const openRef = React.useRef();

  const [officePhotos, setOfficePhotos] = React.useState([]);
  const [uploading, setUploading] = React.useState(false);

  const schema = Yup.object().shape({
    monday: Yup.string()
      .trim(t("officeDetails.whitespaceValidation"))
      .strict(true)
      .required(t("officeDetails.required"))
      .min(2, t("officeDetails.min"))
      .max(255, t("officeDetails.max")),
    tuesday: Yup.string()
      .trim(t("officeDetails.whitespaceValidation"))
      .strict(true)
      .required(t("officeDetails.required"))
      .min(2, t("officeDetails.min"))
      .max(255, t("officeDetails.max")),
    wednesday: Yup.string()
      .trim(t("officeDetails.whitespaceValidation"))
      .strict(true)
      .required(t("officeDetails.required"))
      .min(2, t("officeDetails.min"))
      .max(255, t("officeDetails.max")),
    thursday: Yup.string()
      .trim(t("officeDetails.whitespaceValidation"))
      .strict(true)
      .required(t("officeDetails.required"))
      .min(2, t("officeDetails.min"))
      .max(255, t("officeDetails.max")),
    friday: Yup.string()
      .trim(t("officeDetails.whitespaceValidation"))
      .strict(true)
      .required(t("officeDetails.required"))
      .min(2, t("officeDetails.min"))
      .max(255, t("officeDetails.max")),
    saturday: Yup.string()
      .trim(t("officeDetails.whitespaceValidation"))
      .strict(true)
      .required(t("officeDetails.required"))
      .min(2, t("officeDetails.min"))
      .max(255, t("officeDetails.max")),
    sunday: Yup.string()
      .trim(t("officeDetails.whitespaceValidation"))
      .strict(true)
      .required(t("officeDetails.required"))
      .min(2, t("officeDetails.min"))
      .max(255, t("officeDetails.max")),
  });

  const form = useForm({
    initialValues: {
      monday: office.businessHoursMon || office.businessHours || "",
      tuesday: office.businessHoursTue || office.businessHours || "",
      wednesday: office.businessHoursWed || office.businessHours || "",
      thursday: office.businessHoursThu || office.businessHours || "",
      friday: office.businessHoursFri || office.businessHours || "",
      saturday: office.businessHoursSat || office.businessHours || "",
      sunday: office.businessHoursSun || office.businessHours || "",
    },
    validate: yupResolver(schema),
  });

  React.useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setOfficePhotos(office.photos);

      form.setValues({
        monday: office.businessHoursMon || office.businessHours || "",
        tuesday: office.businessHoursTue || office.businessHours || "",
        wednesday: office.businessHoursWed || office.businessHours || "",
        thursday: office.businessHoursThu || office.businessHours || "",
        friday: office.businessHoursFri || office.businessHours || "",
        saturday: office.businessHoursSat || office.businessHours || "",
        sunday: office.businessHoursSun || office.businessHours || "",
      });
    }

    return () => {
      isMounted = false;
      setOfficePhotos([]);
    };
  }, [office.id]);

  const uploadPhotos = async (photos) => {
    const uploadPromises = photos.map(async (image) => {
      const formData = new FormData();

      formData.append("file", image, image.name);

      const response = await dispatch(insertNewFileThunk(formData)).unwrap();

      const { location } = response;

      const id = location.substring(
        location.lastIndexOf("/") + 1,
        location.length
      );

      setOfficePhotos((prev) => [
        ...prev,
        {
          id: id,
          url: undefined,
          file: image,
        },
      ]);
    });

    await Promise.all(uploadPromises);
  };

  const photoSelectionInvalid = (photos) => {
    return (
      photos.length > 6 ||
      officePhotos.length >= 6 ||
      photos.length + officePhotos.length > 6
    );
  };

  const addPhotos = async (photos) => {
    if (photoSelectionInvalid(photos)) {
      return showNotification({
        message: t("officeDetails.addPhotosError"),
        color: "red",
      });
    } else {
      try {
        setUploading(true);
        await uploadPhotos(photos);
        showNotification({
          message: t("officeDetails.uploadSuccess"),
          color: "green",
        });
      } catch (error) {
        showNotification({
          message: t("officeDetails.uploadError"),
          color: "red",
        });
      } finally {
        setUploading(false);
      }
    }
  };

  const editOffice = async (value) => {
    const data = {
      businessHours: office.businessHours,
      businessHoursMon: value.monday,
      businessHoursTue: value.tuesday,
      businessHoursWed: value.wednesday,
      businessHoursThu: value.thursday,
      businessHoursFri: value.friday,
      businessHoursSat: value.saturday,
      businessHoursSun: value.sunday,
      photoIds: officePhotos.map((photo) => photo.id),
    };

    try {
      await dispatch(editOfficeDataThunk({ id: office.id, data })).unwrap();
      showNotification({
        message: t("officeDetails.editOfficeSuccess"),
        color: "green",
      });
    } catch (error) {
      showNotification({
        message: t("officeDetails.editOfficeError"),
        color: "red",
      });
    }
  };

  const removePhoto = (id) => {
    setOfficePhotos((prev) => prev.filter((photo) => photo.id !== id));
  };

  const renderPhotos = officePhotos?.map((image, index) => {
    return (
      <Group key={index} spacing={"xs"}>
        <OfficePictureCard
          key={image?.id}
          image={image?.url}
          file={image?.file}
          alt={image?.id}
          name={image?.id}
          onDelete={removePhoto}
        />
      </Group>
    );
  });

  return (
    <>
      <ScrollArea type="scroll" className={classes.scrollArea}>
        {loading ? (
          <LoadingOverlay loader={CustomLoader()} visible />
        ) : (
          <form onSubmit={form.onSubmit(editOffice)}>
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <Divider
                size="xl"
                my="xs"
                label={t("officeDetails.info")}
                labelPosition="center"
              />
              <SimpleGrid cols={2}>
                <Card withBorder>
                  <TextInput
                    classNames={classes}
                    readOnly
                    label={t("officeDetails.name")}
                    defaultValue={office.name}
                  />
                  <TextInput
                    classNames={classes}
                    readOnly
                    label={t("officeDetails.street")}
                    defaultValue={office.street}
                  />
                  <TextInput
                    classNames={classes}
                    readOnly
                    label={t("officeDetails.place")}
                    defaultValue={office.place}
                  />
                  <TextInput
                    classNames={classes}
                    readOnly
                    label={t("officeDetails.safeDepositBox")}
                    defaultValue={office.hasSafeDepositBox ? "YES" : "NO"}
                  />
                </Card>
                <Card withBorder>
                  <TextInput
                    classNames={classes}
                    readOnly
                    label={t("officeDetails.telephone")}
                    defaultValue={office.telephone}
                  />
                  <TextInput
                    classNames={classes}
                    readOnly
                    label={t("officeDetails.email")}
                    defaultValue={office.emailAddress}
                  />
                  <TextInput
                    classNames={classes}
                    readOnly
                    label={t("officeDetails.currentStatus")}
                    defaultValue={office.status}
                  />
                </Card>
              </SimpleGrid>
              <Divider
                size="xl"
                my="xs"
                label={t("officeDetails.updateHours")}
                labelPosition="center"
              />
              <Card
                withBorder
                w={"100%"}
                style={{
                  display: "flex",
                  flexDirection: "row"
                }}
              >
                <div 
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "50%",
                    paddingRight: "1rem",
                  }}
                >
                  <TextInput
                    classNames={classes}
                    label={t("officeDetails.monday")}
                    value={office.monday}
                    {...form.getInputProps("monday")}
                  />
                  <TextInput
                    classNames={classes}
                    label={t("officeDetails.wednesday")}
                    value={office.wednesday}
                    {...form.getInputProps("wednesday")}
                  />
                  <TextInput
                    classNames={classes}
                    label={t("officeDetails.friday")}
                    value={office.friday}
                    {...form.getInputProps("friday")}
                  />
                  <TextInput
                    classNames={classes}
                    label={t("officeDetails.sunday")}
                    value={office.sunday}
                    {...form.getInputProps("sunday")}
                  />
                </div>
                <div 
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "50%",
                    paddingLeft: "1rem",
                    marginLeft: "0.5rem"
                  }}
                >
                  <TextInput
                    classNames={classes}
                    label={t("officeDetails.tuesday")}
                    value={office.tuesday}
                    {...form.getInputProps("tuesday")}
                  />
                  <TextInput
                    classNames={classes}
                    label={t("officeDetails.thursday")}
                    value={office.thursday}
                    {...form.getInputProps("thursday")}
                  />
                  <TextInput
                    classNames={classes}
                    label={t("officeDetails.saturday")}
                    value={office.saturday}
                    {...form.getInputProps("saturday")}
                  />
                </div>
              </Card>
              <Divider
                size="xl"
                my="xs"
                label={t("officeDetails.updatePhotos")}
                labelPosition="center"
              />
              <Group sx={{ alignItems: "flex-start" }} noWrap>
                <Image
                  src={images.addPicture}
                  width={120}
                  fit="cover"
                  alt="logo"
                  onClick={() => openRef.current()}
                  sx={{ cursor: "pointer", padding: 8 }}
                />
                <Group>
                  {renderPhotos}
                  {uploading && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: 120,
                        height: 100,
                        alignItems: "center",
                      }}
                    >
                      <Loader />
                    </Box>
                  )}
                </Group>
                <Dropzone
                  maxSize={1024 * 1024}
                  onReject={(e) =>
                    showNotification({
                      message: t("officeDetails.fileSizeError"),
                      color: "red",
                    })
                  }
                  onDrop={addPhotos}
                  openRef={openRef}
                  className={classes.dropzoneHidden}
                  accept={IMAGE_MIME_TYPE}
                  {...form.getInputProps("photoIds")}
                />
              </Group>
              <Divider size="xl" my="xs" />
              <Group
                sx={{ alignItems: "flex-start", justifyContent: "flex-end" }}
                noWrap
              >
                <Button
                  type="submit"
                  variant="subtle"
                  onClick={() => {}}
                  className={classes.insertButton}
                >
                  {t("officeDetails.save")}
                </Button>
              </Group>
            </Box>
          </form>
        )}
      </ScrollArea>
    </>
  );
};

export default OfficeDetails;

const OfficePictureCard = ({ image, file, name, onDelete }) => {
  const { classes } = useStyles();
  const [opened, setOpened] = React.useState(false);

  return (
    <>
      <Stack
        sx={(theme) => ({
          padding: 8,
          border: `1px solid ${theme.colors.gray[2]}`,
          borderRadius: theme.radius.md,
        })}
      >
        <Image
          onClick={() => {
            setOpened(true);
          }}
          radius="md"
          key={name.id}
          src={image ? image : URL.createObjectURL(file)}
          height={120}
          width={120}
          fit="contain"
          sx={{ cursor: "zoom-in" }}
          imageProps={{ onLoad: () => (image ? image : file) }}
        />

        <Text size="sm" sx={{ width: "15ch" }}>
          {"Name: " + name}
        </Text>
        <Button
          variant="subtle"
          className={classes.tableIconsButton}
          onClick={() => {
            onDelete(name);
          }}
        >
          <Trash className={classes.tableIconsTrash} />
        </Button>
      </Stack>
      <OfficePictureModal
        opened={opened}
        image={image}
        file={file}
        name={name}
        onClose={() => setOpened(false)}
      />
    </>
  );
};

const OfficePictureModal = ({ opened, onClose, image, file, name }) => {
  const { classes } = useStyles();
  return (
    <>
      <Modal
        opened={opened}
        onClose={onClose}
        withCloseButton={false}
        size="auto"
      >
        <Image
          onClick={() => {
            onClose();
          }}
          key={name}
          src={image ? image : URL.createObjectURL(file)}
          imageProps={{ onLoad: () => image }}
          width={860}
          fit="contain"
        />
      </Modal>
    </>
  );
};
