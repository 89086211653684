import {
  Box,
  Button,
  Card,
  Group,
  Loader,
  ScrollArea,
  Text,
  createStyles,
} from "@mantine/core";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { X } from "tabler-icons-react";
import images from "../../../../assets/images";
import dayjs from "dayjs";

const useStyles = createStyles((theme) => ({
  mainChatDiv: {
    height: "100vh",
  },
  scrollArea: {
    borderRight: "1px solid #EBEEF0",
    height: "100vh",
    overflowY: "auto",
    position: "relative",
  },
  systemDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    opacity: 0.5,
    flexWrap: "inherit",
  },

  agentDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "inherit",
  },
  agentCard: {
    background: "#CCD2DA",
    width: "50%",
    borderRadius: 30,
    marginLeft: 10,
    marginTop: 10,
    marginBottom: 10,
    overflowWrap: "anywhere",
  },

  userDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "inherit",
  },
  userCard: {
    background: "#EBEEF0",
    borderRadius: 30,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10,
    overflowWrap: "anywhere",
  },
  chatHeader: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: "#FFFFFF",
    padding: 16,
  },

  buttonCheckboxDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  chatMsgsDiv: {
    display: "flex",
    flexDirection: "column-reverse",
    alignItems: "flex-start",
  },
  iconMsgsDiv: {
    display: "flex",
  },
  imgDiv: {
    display: "flex",
    height: 35,
    width: 35,
    borderRadius: "50%",
    background: "#A7ADB9",
    marginRight: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  loaderDiv: {
    diplay: "flex",
    alignItems: "center",
    alignSelf: "center",
  },
  formDiv: {
    display: "flex",
    background: "#EAEBEC",
    height: "10vh",
    justifyContent: "center",
    alignItems: "center",
  },
  btnSubmit: {
    borderRadius: "50%",
    width: 35,
    height: 35,
    padding: 0,
    marginLeft: 5,
  },
}));

const ArchivedChat = (props) => {
  const { setOpenChatArchive } = props;

  const { classes } = useStyles();

  const bottomRef = useRef(null);

  const status = useSelector((state) => state.chat.status);
  const chatThreadDataById = useSelector(
    (state) => state?.chat?.chatThreadDataById || ""
  );

  const chatThreadMsgs = useSelector(
    (state) => state?.chat?.chatThreadMsgs || []
  );

  const checkMsgType = (msg) => {
    switch (true) {
      case msg?.metadata?.messageSenderType === "SYSTEM":
        return (
          <Box className={classes.systemDiv}>
            <Text weight={500}>{msg?.content?.message}</Text>
          </Box>
        );

      case msg?.metadata?.messageSenderType === "AGENT":
        return (
          <Box className={classes.agentDiv}>
            <Card className={classes.agentCard}>
              <Box className={classes.iconMsgsDiv}>
                <Box className={classes.imgDiv}>
                  <img src={images.agent} style={{ height: 20, width: 35 }} />
                </Box>
                <Box>
                  <Text weight={500}>{msg?.senderDisplayName}</Text>

                  <Text>{msg?.content?.message}</Text>
                </Box>
              </Box>
              <Text
                size={"xs"}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                {dayjs(msg.createdOn).format("DD.MM.YYYY HH:mm")}
              </Text>
            </Card>
          </Box>
        );

      case msg?.metadata?.messageSenderType === "CUSTOMER":
        return (
          <Box className={classes.userDiv}>
            <Card className={classes.userCard}>
              <Box className={classes.iconMsgsDiv}>
                <Box className={classes.imgDiv}>
                  <img src={images.user} style={{ height: 20, width: 35 }} />
                </Box>
                <Box>
                  <Text weight={500}>{msg?.senderDisplayName}</Text>

                  <Text>{msg?.content?.message}</Text>
                </Box>
              </Box>
              <Text
                size={"xs"}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                {dayjs(msg?.createdOn).format("DD.MM.YYYY HH:mm")}
              </Text>
            </Card>
          </Box>
        );
      default:
        return <div />;
    }
  };

  useEffect(() => {
    bottomRef?.current?.scrollIntoView();
  }, [chatThreadMsgs]);

  return (
    <div className={classes.mainChatDiv}>
      <ScrollArea className={classes.scrollArea}>
        <Group className={classes.chatHeader} position="apart">
          <Text weight={650} size={"lg"}>
            {
              chatThreadDataById?.participants?.find(
                (t) => t?.type === "CUSTOMER"
              )?.name
            }
          </Text>
          <Box className={classes.buttonCheckboxDiv}>
            <Button
              compact
              color="dark"
              variant="subtle"
              onClick={() => {
                setOpenChatArchive(false);
              }}
            >
              <X />
            </Button>
          </Box>
        </Group>
        <Box className={classes.chatMsgsDiv}>
          {chatThreadMsgs?.map((msg) => (
            <Box style={{ display: "flex", width: "100%" }} key={msg?.id}>
              {checkMsgType(msg)}
            </Box>
          ))}
          {status === "pending" && (
            <div className={classes.loaderDiv}>
              <Loader />
            </div>
          )}
        </Box>
        <div ref={bottomRef} />
      </ScrollArea>
    </div>
  );
};

export default ArchivedChat;
