import { useTranslation } from "react-i18next";
import { useForm, yupResolver } from "@mantine/form";
import * as Yup from "yup";
import { Box, Button, createStyles, Modal, TextInput } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { DatePicker } from "@mantine/dates";

import {
  createLegalDocumentsByTypeThunk,
  fetchLegalDocumentsByTypeIdThunk,
  fetchLegalDocumentsByTypeThunk,
  updateLegalDocumentsByTypeThunk,
} from "../../store/slices/legalDocuments";
import config from "../../config";
import { Editor } from "@tinymce/tinymce-react";

const useStyles = createStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingTop: `${theme.spacing.xs}px `,
  },
  root: {
    position: "relative",
    marginTop: 20,
  },

  input: {
    height: "auto",
    paddingTop: 18,
  },

  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: theme.spacing.sm / 2,
    zIndex: 1,
  },
  errorText: {
    color: "#f03e3e",
    fontSize: "12px",
  },
  insertButton: {
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
  },
}));



const CookiePolicyModal=(props)=>{
    const { classes } = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { opened, onClose, isUpdate, updateData, COOKIE_POLICY } = props;
    const locale = useSelector((state) => state.settings.language);
  
    const tableData = useSelector(
      (state) => state?.legalDocumentsByType?.tableData
    );
  
    const schema = Yup.object().shape({
      name: Yup.string()
        .trim(t("cookiePolicyModal.whitespaceValidation"))
        .strict(true)
        .required(t("cookiePolicyModal.required"))
        .min(1, t("cookiePolicyModal.nameValidation"))
        .max(255, t("cookiePolicyModal.nameLength")),
      validFrom: Yup.date()
        .typeError(t("cookiePolicyModal.invalidDate"))
        .test(
          "isFreeTermPeriod",
          t("cookiePolicyModal.isFreeTermPeriod"),
          (value) => {
            const isEdit = updateData?.id;
            const data = isEdit
              ? tableData?.filter((item) => item.id !== updateData?.id)
              : tableData;
  
            const date = data?.find((item) => {
              return (
                new Date(value) >= new Date(item?.validFrom) &&
                new Date(value) <= new Date(item?.validTo)
              );
            });
  
            return date ? false : true;
          }
        ),
      validTo: Yup.date()
        .typeError(t("cookiePolicyModal.invalidDate"))
        .test(
          "isFreeTermPeriod",
          t("cookiePolicyModal.isFreeTermPeriod"),
          function (value) {
            const { validFrom } = this.parent;
            const isEdit = updateData?.id;
  
            const data = isEdit
              ? tableData?.filter((item) => item.id !== updateData?.id)
              : tableData;
  
            const checkAvailabiltyDate = data.find(
              (item) =>
                new Date(item.validFrom).getTime() >= validFrom.getTime() &&
                new Date(item.validTo).getTime() <= new Date(value).getTime()
            );
  
            return checkAvailabiltyDate ? false : true;
          }
        )
        .test(
          "isFreeTermPeriod",
          t("cookiePolicyModal.isFreeTermPeriod"),
          function (value) {
            const isEdit = updateData?.id;
            const data = isEdit
              ? tableData?.filter((item) => item.id !== updateData?.id)
              : tableData;
  
            const date = data?.find((item) => {
              return (
                new Date(value) >= new Date(item?.validFrom) &&
                new Date(value) <= new Date(item?.validTo)
              );
            });
  
            return date ? false : true;
          }
        )
        .test(
          "isGreaterThan",
          t("cookiePolicyModal.isGreaterThan"),
          function (value) {
            const { validFrom } = this.parent;
            return value.getTime() > validFrom.getTime();
          }
        ),
      content: Yup.string()
        .trim(t("cookiePolicyModal.contentWhitespaceValidation"))
        .strict(true)
        .required(t("cookiePolicyModal.required"))
        .min(1, t("cookiePolicyModal.contentValidation"))
        .test(
          "emptyContent",
          t("cookiePolicyModal.contentValidation"),
          function (value) {
            const parsedValue = value.replace(/(<([^>]+)>|&nbsp;)/gi, "");
  
            return parsedValue.trim() === "" ? false : true;
          }
        ),
    });
  
    const form = useForm({
      initialValues: {
        name: "",
        type: COOKIE_POLICY,
        validFrom: "",
        validTo: "",
        content: "",
      },
      validate: yupResolver(schema),
    });
  
    const submitForm = async (data) => {
      let updateItem = [];
      if (isUpdate) {
        await dispatch(fetchLegalDocumentsByTypeIdThunk(updateData.id))
          .unwrap()
          .then((response) => {
            updateItem = response;
          })
          .catch((e) => {
            showNotification({
              message: t("cookiePolicyModal.updateFailed"),
              color: "red",
            });
          });
  
        let updateArrayObject = updateItem.descriptions.filter(
          (item) => item.locale !== "en"
        );
        updateArrayObject = [
          ...updateArrayObject,
          { locale: "en", name: data.name, content: data.content },
        ];
  
        const objectToUpdate = {
          validFrom: data.validFrom,
          validTo: data.validTo,
          contents: updateArrayObject,
        };
        await dispatch(
          updateLegalDocumentsByTypeThunk({
            id: updateData.id,
            data: objectToUpdate,
          })
        )
          .unwrap()
          .then((response) => {
            showNotification({
              message: t("cookiePolicyModal.updateSuccessfull"),
              color: "green",
            });
          })
          .catch((e) => {
            showNotification({
              message: t("privacyStatementModal.updateFailed"),
              color: "red",
            });
          });
      } else {
        await dispatch(createLegalDocumentsByTypeThunk({ locale, data }))
          .unwrap()
          .then(() => {
            form.reset();
            onClose();
            showNotification({
              message: t("cookiePolicyModal.insertSuccessfull"),
              color: "green",
            });
          })
          .catch((e) => {
            showNotification({
              message: t("cookiePolicyModal.insertFailed"),
              color: "red",
            });
          });
      }
      await dispatch(fetchLegalDocumentsByTypeThunk(COOKIE_POLICY));
  
      onClose();
    };
    useEffect(() => {
      form.clearErrors();
      form.reset();
      if (isUpdate) {
        form.setValues({
          name: updateData.name,
          type: updateData.type,
          validFrom: new Date(updateData.validFrom),
          validTo: new Date(updateData.validTo),
          content: updateData.content,
        });
      }
    }, [updateData]);
  
    return (
      <>
        <Modal
          closeOnClickOutside={false}
          centered
          size="xl"
          opened={opened}
          onClose={() => {
            form.clearErrors();
            form.reset();
            onClose();
          }}
          title={t("cookiePolicyModal.modalTitle")}
          sx={() => ({
            ".mantine-Modal-title": {
              fontWeight: "bold",
            },
          })}
        >
         <form onSubmit={form.onSubmit(submitForm)}>
            <TextInput
              label={t("cookiePolicyModal.nameInputTitle")}
              placeholder={t("cookiePolicyModal.nameInputPlaceholder")}
              classNames={classes}
              {...form.getInputProps("name")}
            />
            <TextInput
              readOnly
              label={t("cookiePolicyModal.typeTitle")}
              placeholder={t("cookiePolicyModal.nameInputPlaceholder")}
              classNames={classes}
              defaultValue={
                form?.values?.type === "COOKIE_POLICY"
                  ? "COOKIE POLICY"
                  : form.values.type
              }
            />
            <DatePicker
              minDate={new Date()}
              classNames={classes}
              inputFormat="DD.MM.YYYY"
              label={t("cookiePolicyModal.dateFromTitle")}
              {...form.getInputProps("validFrom")}
            />
  
            <DatePicker
              minDate={new Date()}
              classNames={classes}
              inputFormat="DD.MM.YYYY"
              label={t("cookiePolicyModal.dateToTitle")}
              disabled={form.values?.validFrom === ""}
              {...form.getInputProps("validTo")}
            />
            <Box style={{ marginTop: 20 }}>
              <Editor
                value={form.values.content}
                onEditorChange={(content) => {
                  form.setFieldValue("content", content);
                }}
                apiKey={config.REACT_APP_TINY_MCE_EDITOR_API_KEY}
                init={{
                  height: 300,
                  menubar: false,
                  plugins: "advlist lists",
                  toolbar: "bold italic| casechange blocks | numlist bullist",
                  branding: false,
                }}
              />
  
              {form.errors.content !== null ? (
                <p className={classes.errorText}>{form.errors.content}</p>
              ) : (
                ""
              )}
            </Box>
            <div className={classes.buttonContainer}>
              <Button className={classes.insertButton} type="submit">
                {t("modalCommon.saveButton")}
              </Button>
            </div>
          </form>
        </Modal>
      </>
    );
  };
  


export default CookiePolicyModal