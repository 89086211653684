import { createStyles, Text, TextInput, Button, Group, ScrollArea, Table } from "@mantine/core";

import { useTranslation } from "react-i18next";
import { useState, useEffect, useMemo, useCallback } from "react";

import { useDispatch } from "react-redux";
import { Pencil, Search, World } from "tabler-icons-react";
import TableCell from "../common/TableCell";
import { getPageSeoByIdThunk, getPageSeoThunk, resetSeoParameters } from "../../store/slices/WebSeo";
import WebSeoModal from "./WebSeoModal";
import { useNavigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  tableTitle: {
    fontSize: "1.5rem",
    color: "#000000",
    fontWeight: 500,
    marginTop: "1.5rem",
    marginBottom: "1.5rem"
  },
  th: {
    padding: "0 !important",
  },
  control: {
    width: "100%",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },
  scrollArea: {
    height: "95vh",
  },
  searchAndButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
  },
  thead: {
    borderBottom: `1px solid #dee2e6`,
  },
  tableIconsButton: {
    padding: "0px",
    "&:hover": {
      backgroundColor: theme.colors.colorGray,
    },
  },
  tableIconsLogo: {
    color: theme.colors.colorDarkGray,
    "&:hover": {
      color: theme.colors.colorBlack,
    },
  },
  searchInput: {
    width: "25vw",
    marginBottom: "0px !important",
  }
}));

const WebSeoLayout = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [modalVisible, setModalVisible] = useState(false);
  const [seoId, setSeoId] = useState("");
  const [isEdit, setIsEdit] = useState(true);
  const [tableData, setTableData] = useState({});
  const [unfilteredTableData, setUnfiltered] = useState({});
  const [search, setSearch] = useState("");

  const fetchPageSeo = useCallback(() => {
    return dispatch(getPageSeoThunk())
      .unwrap()
      .then((seoData) => {
        setUnfiltered(seoData);
        setTableData(seoData);
      })
  }, [dispatch])

  const filterTable = useCallback(() => {
    if(search.trim === "") return;
    if(!unfilteredTableData.length) return;

    const filteredData = unfilteredTableData?.filter((item) => {
      const searchLower = search.toLowerCase();
      const checkIncludes = (value) =>
        value && value.toString().toLowerCase().includes(searchLower);
      return (
        checkIncludes(item.code)
      );
    });
    setTableData(filteredData);
  }, [search, unfilteredTableData])

  useEffect(() => {
    fetchPageSeo();
  }, [fetchPageSeo]);

  useEffect(() => {
    filterTable()
  }, [filterTable]);

  const handleSearchChange = (event) => {
    const { value } = event.currentTarget;
    setSearch(value);
  };

  const openModal = (id, isEdit) => {
    setSeoId(id);
    setIsEdit(isEdit)
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    dispatch(resetSeoParameters());
  };

  const rows = useMemo(() => {
    if(!tableData.length) return;
    return tableData?.map((row) => (
      <tr key={row.id}>
        {TableCell(row.code)}
        <td className={classes.buttonColumn}>
          <Group position="right" className={classes.buttonContainer}>
            <Button
              variant="subtle"
              className={classes.tableIconsButton}
              onClick={async () => {
                await dispatch(
                  getPageSeoByIdThunk(row?.id.toString())
                ).unwrap();
                navigate(row.id.toString());
              }}
            >
              <World className={classes.tableIconsLogo} />
            </Button>
            <Button
              className={classes.tableIconsButton}
              variant="subtle"
              onClick={() => openModal(row.id, true)}
            >
              <Pencil className={classes.tableIconsLogo} />
            </Button>
          </Group>
        </td>
      </tr>
    ));
  }, [tableData, classes, navigate, dispatch]);

  return (
    <>
      <div className={classes.searchAndButtonContainer}>
        <TextInput
          className={classes.searchInput}
          placeholder={t("table.search")}
          icon={<Search size={14} />}
          defaultValue={search}
          onChange={handleSearchChange}
        />
      </div>
      <ScrollArea className={classes.scrollArea}>
        <div className={classes.tableTitle}>
          {t("webSeo.tableTitle")}
        </div>

        <Table
          horizontalSpacing="md"
          verticalSpacing="xs"
          sx={{ tableLayout: "fixed", minWidth: 700 }}
        >
          <tbody>
            {rows?.length > 0 ? (
              rows
            ) : (
              <tr>
                <td colSpan={2}>
                  <Text weight={500} align="center">
                    {t("table.nothingFound")}
                  </Text>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </ScrollArea>
      {
        <WebSeoModal
          opened={modalVisible}
          isEdit={isEdit}
          onClose={closeModal}
          seoId={seoId}
        />
      }
    </>
  );
};

export default WebSeoLayout;
