import {
  Button,
  Group,
  Modal,
  Switch,
  Text,
  TextInput,
  createStyles,
} from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  createUserAccountAdministrationDataThunk,
  fetchUserAccountAdministrationPagedDataThunk,
  getUserAccountEmailCheckThunk,
  updateUserAccountAdministrationDataThunk,
} from "../../store/slices/userAccountAdministration";
import { MultiCheckboxSelect } from "./MultiCheckboxSelect";

const useStyles = createStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingTop: `${theme.spacing.xs}px `,
  },
  root: {
    position: "relative",
    marginTop: 20,
    zIndex: 2,
  },

  input: {
    height: "auto",
    paddingTop: 18,
  },

  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: theme.spacing.sm / 2,
    zIndex: 1,
  },

  switch: {
    color: theme.colors.colorBlack,
    "& *": {
      cursor: "pointer",
    },
  },

  insertButton: {
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
  },
  title: {
    lineHeight: 1,
  },
}));

const UserAdministrationAccountsModal = (props) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const rolesData = useSelector((state) => state.roles.data);

  const isNewUserAdministrationAccount = () => !(props.data && props.data.id);

  const schema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, t("userModal.nameValidation"))
      .max(256, t("userModal.lengthValidationName"))
      .trim(t("userModal.nameWhitespaceValidation"))
      .strict(true),
    lastName: Yup.string()
      .min(2, t("userModal.lastNameValidation"))
      .max(256, t("userModal.lengthValidationLastName"))
      .trim(t("userModal.nameWhitespaceValidation"))
      .strict(true),
    email: Yup.string()
      .email(t("userModal.invalidEmail"))
      .required(t("userModal.emailValidation"))
      .max(256, t("userModal.lengthValidationMail"))
      .trim(t("userModal.emailWhitespaceValidation"))
      .strict(true),
    roles: Yup.array().min(1, t("userModal.rolesValidation")),
  });

  const form = useForm({
    initialValues: {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      enabled: true,
      roles: [],
    },
    validate: yupResolver(schema),
  });

  const createUserAccountAdministration = async (data) => {
    await dispatch(getUserAccountEmailCheckThunk({ email: data?.email }))
      .unwrap()
      .then(async (res) => {
        if (res === 200) {
          showNotification({
            message: t("userModal.isUnique"),
            color: "red",
          });
        }
      })
      .catch(async (err) => {
        await dispatch(createUserAccountAdministrationDataThunk(data))
          .unwrap()
          .then((e) => {
            showNotification({
              message: t("userModal.insertSuccessfull"),
              color: "green",
            });
          })
          .catch((error) => {
            showNotification({
              message: t("userModal.insertFailed"),
              color: "red",
            });
          });
      });

    dispatch(
      fetchUserAccountAdministrationPagedDataThunk({
        first: props?.paginationProps?.activePage,
        max: Number(props?.paginationProps?.pageSize) + 1,
      })
    );
  };
  const updateUserAccountAdministration = async (data) => {
    await dispatch(
      getUserAccountEmailCheckThunk({ email: data?.email, userId: data?.id })
    )
      .unwrap()
      .then(async (res) => {
        if (res === 200) {
          showNotification({
            message: t("userModal.isUnique"),
            color: "red",
          });
        }
      })
      .catch(async (err) => {
        await dispatch(
          updateUserAccountAdministrationDataThunk({
            id: props?.data?.id,
            data,
          })
        )
          .unwrap()
          .then(() => {
            showNotification({
              message: t("userModal.updateSuccessfull"),
              color: "green",
            });
          })
          .catch((e) => {
            showNotification({
              message: t("userModal.updateFailed"),
              color: "red",
            });
          });
      });

    dispatch(
      fetchUserAccountAdministrationPagedDataThunk({
        first: props?.paginationProps?.activePage,
        max: Number(props?.paginationProps?.pageSize) + 1,
      })
    );
  };

  const submitForm = async (data) => {
    isNewUserAdministrationAccount()
      ? createUserAccountAdministration(data)
      : updateUserAccountAdministration(data);
    form.reset();
    props.onClose(false);
  };

  useEffect(() => {
    form.reset();
    if (props.data) {
      form.setValues({
        id: props?.data?.id,
        firstName: props?.data?.firstName,
        lastName: props?.data?.lastName,
        email: props?.data?.email,
        enabled: props?.data?.enabled ? true : false,
        roles: props?.data?.roles?.filter((role) => role !== "ADMIN_USER"),
      });
    }
  }, [props]);

  return (
    <>
      <Modal
        closeOnClickOutside={false}
        size={"xl"}
        centered
        opened={props.show}
        onClose={() => props.onClose(false)}
        title={t("userModal.modalTitle")}
        sx={(theme) => ({
          ".mantine-Modal-title": {
            fontWeight: "bold",
          },
        })}
      >
        <form onSubmit={form.onSubmit(submitForm)}>
          <TextInput
            label={t("userModal.firstName")}
            placeholder={t("userModal.firstNamePlaceholder")}
            classNames={classes}
            {...form.getInputProps("firstName")}
          />
          <TextInput
            label={t("userModal.lastName")}
            placeholder={t("userModal.lastNamePlaceholder")}
            classNames={classes}
            {...form.getInputProps("lastName")}
          />
          <TextInput
            label={t("userModal.email")}
            placeholder={t("userModal.emailPlaceholder")}
            classNames={classes}
            disabled={props?.data?.enabled === false || !!props.data}
            {...form.getInputProps("email")}
          />

          <MultiCheckboxSelect
            data={rolesData}
            label={t("userModal.roles")}
            {...form.getInputProps("roles")}
          />

          <Group
            position="apart"
            style={{
              marginTop: 20,
              zIndex: 2,
              paddingLeft: "12px",
              paddingRight: "12px",
            }}
            noWrap
            spacing="xl"
          >
            <div>
              <Text>{t("userModal.activityTitle")}</Text>
              <Text size="xs" color="dimmed">
                {t("userModal.activityText")}
              </Text>
            </div>
            <Switch
              sx={(theme) => ({
                "input:checked+.mantine-adpl6b": {
                  background: theme.colors.colorDarkGray,
                  borderColor: theme.colors.colorDarkGray,
                },
              })}
              className={classes.switch}
              size="lg"
              {...form.getInputProps("enabled", { type: "checkbox" })}
            />
          </Group>

          <div className={classes.buttonContainer}>
            <Button className={classes.insertButton} type="submit">
              {t("userModal.insertButton")}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default UserAdministrationAccountsModal;
