import {
  Box,
  Button,
  Collapse,
  Divider,
  Group,
  Loader,
  ScrollArea,
  Text,
  TextInput,
  createStyles,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchChatThreadsByAgentThunk,
  fetchChatThreadsByCustomerSearchThunk,
  fetchChatThreadsByCustomerThunk,
  fetchChatThreadsThunkById,
  joinChatThreadThunk,
  setPageCustomer,
} from "../../../store/slices/chat";
import { Search, X } from "tabler-icons-react";
import images from "../../../assets/images";
import dayjs from "dayjs";
import jwt_decode from "jwt-decode";
import keycloak from "../../../keycloak";
import { useTranslation } from "react-i18next";
import { InView } from "react-intersection-observer";

const useStyles = createStyles(() => ({
  scrollArea: {
    width: "33%",
    height: "100vh",
    background: "#EAEBEC",
    overflowY: "auto",
  },
  btnSearchDiv: {
    display: "flex",
    flexDirection: "column",
  },
  itemsDiv: {
    display: "flex",
    alignItems: "center",
  },
  btnDiv: {
    background: "#000000",
    borderRadius: 16,
  },
  noNewAccountsDiv: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    paddingLeft: 10,
  },
}));

const NewAccounts = (props) => {
  const { participantIdentityId } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();

  const [opened, setOpened] = useState(false);
  const [page, setPage] = useState(0);

  const totalPages = useSelector((state) => state.chat.totalPagesCustomer);
  const chatThreadDataByCustomer = useSelector(
    (state) => state.chat.chatThreadDataByCustomer || []
  );
  const statusCustomer =
    useSelector((state) => state?.chat?.statusCustomer) || "";

  const dispatch = useDispatch();

  const decodeAccessToken = jwt_decode(keycloak?.token);

  //for fetching customer thunk by specific interval
  useEffect(() => {
    let isMounted = true;
    let intervalId = setInterval(async () => {
      setPage(0);
      try {
        dispatch(setPageCustomer(0));
        await dispatch(
          fetchChatThreadsByCustomerThunk({
            noneOfParticipantType: "AGENT",
            sortBy: ["LAST_MESSAGE_RECEIVED_ON_DESC"],
            page: 0,
            size: 10,
            archived: false,
          })
        ).unwrap();
      } catch (error) {
        console.log(error);
      }
    }, 60000);
    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  });

  React.useEffect(() => {
    (async () => {
      page !== 0 &&
        (await dispatch(
          fetchChatThreadsByCustomerThunk({
            noneOfParticipantType: "AGENT",
            sortBy: ["LAST_MESSAGE_RECEIVED_ON_DESC"],
            page,
            size: 10,
            archived: false,
          })
        ).unwrap());
    })();
  }, [page]);
  React.useEffect(() => {
    (async () => {
      page === 0 &&
        (await dispatch(
          fetchChatThreadsByCustomerThunk({
            noneOfParticipantType: "AGENT",
            sortBy: ["LAST_MESSAGE_RECEIVED_ON_DESC"],
            page: 0,
            size: 10,
            archived: false,
          })
        ).unwrap());
    })();
  }, []);

  return (
    <>
      <ScrollArea className={classes.scrollArea}>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <Group position="apart" style={{ padding: 16 }}>
            <Text weight={650} size={"lg"}>
              {t("customerSupport.newAccounts")}
            </Text>
            <Box className={classes.btnSearchDiv}>
              <Button
                style={{ width: 50 }}
                compact
                color="dark"
                variant="subtle"
                onClick={() => setOpened((o) => !o)}
              >
                {" "}
                <Search />
              </Button>
            </Box>
          </Group>
          <Collapse in={opened}>
            <TextInput
              style={{ padding: 10 }}
              rightSection={<X onClick={() => setOpened((o) => !o)} />}
              onChange={async (e) => {
                if (e?.target?.value?.length === 0) {
                  setPage(0);
                  await dispatch(
                    fetchChatThreadsByCustomerSearchThunk({
                      noneOfParticipantType: "AGENT",
                      participantName: e?.target?.value,
                      sortBy: ["LAST_MESSAGE_RECEIVED_ON_DESC"],
                      archived: false,
                      page: 0,
                      size: 10,
                    })
                  ).unwrap();
                }
                if (e?.target?.value?.length > 2) {
                  await dispatch(
                    fetchChatThreadsByCustomerSearchThunk({
                      noneOfParticipantType: "AGENT",
                      participantName: e?.target?.value,
                      sortBy: ["LAST_MESSAGE_RECEIVED_ON_DESC"],
                      archived: false,
                    })
                  ).unwrap();
                }
              }}
            />
          </Collapse>

          {chatThreadDataByCustomer.length !== 0 ? (
            chatThreadDataByCustomer.map((item) => (
              <Box
                style={{
                  padding: 16,
                }}
                key={item.id}
              >
                <Group position="apart">
                  <Box className={classes.itemsDiv}>
                    <img src={images.chat} />
                    <Box style={{ marginLeft: 10 }}>
                      <Text weight={650}>
                        {
                          item?.participants?.find(
                            (n) => n?.type === "CUSTOMER"
                          )?.name
                        }
                      </Text>
                      <Text weight={450}>{item?.lastMessageAbbreviate}</Text>
                      <Text weight={50}>
                        {item?.lastMessageReceivedOn !== null
                          ? dayjs(item?.lastMessageReceivedOn).format(
                              "DD.MM.YYYY HH:mm"
                            )
                          : ""}
                      </Text>
                    </Box>
                  </Box>
                  <Button
                    className={classes.btnDiv}
                    color="dark"
                    onClick={async () => {
                      try {
                        await dispatch(
                          joinChatThreadThunk({
                            id: item.id,
                            participantName: `${
                              decodeAccessToken.given_name +
                              " " +
                              decodeAccessToken.family_name
                            }`,
                            participantChatDisplayName: `${
                              decodeAccessToken.given_name +
                              " " +
                              decodeAccessToken.family_name
                            }`,
                          })
                        ).unwrap();
                        await dispatch(
                          fetchChatThreadsThunkById({ id: item.id })
                        ).unwrap();
                        await dispatch(
                          fetchChatThreadsByCustomerThunk({
                            noneOfParticipantType: "AGENT",
                            sortBy: ["LAST_MESSAGE_RECEIVED_ON_DESC"],
                            archived: false,
                            page: 0,
                            size: 10,
                          })
                        ).unwrap();
                        await dispatch(
                          fetchChatThreadsByAgentThunk({
                            participantIdentityId: participantIdentityId,
                            hasAnyOfParticipantType: "AGENT",
                            sortBy: ["LAST_MESSAGE_RECEIVED_ON_DESC"],
                            archived: false,
                            page: 0,
                            size: 10,
                          })
                        ).unwrap();
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  >
                    {t("customerSupport.takeOver")}
                  </Button>
                </Group>
                <Divider style={{ marginTop: 15 }} />
              </Box>
            ))
          ) : (
            <Box className={classes.noNewAccountsDiv}>
              <img src={images.accounts} />{" "}
              <Text style={{ marginLeft: 10 }} weight={650}>
                {t("customerSupport.noNewAccounts")}
              </Text>
            </Box>
          )}
          <Box>{statusCustomer === "pending" && <Loader />}</Box>
        </Box>
        {statusCustomer !== "pending" &&
        chatThreadDataByCustomer.length &&
        page < totalPages - 1 ? (
          <InView
            as="div"
            threshold={1}
            onChange={(inView) => {
              inView && setPage(page + 1);
            }}
          >
            <Box>
              <Loader />
            </Box>
            <h4>{t("customerSupport.loadingMore")}</h4>
          </InView>
        ) : null}
      </ScrollArea>
    </>
  );
};

export default NewAccounts;
