export const parseLanguageNames = (languages, filteredLanguage) => {
  const arr = [];
  languages.forEach((elementA) =>
    filteredLanguage.forEach((elementB) => {
      if (elementA.code === elementB) {
        arr.push({
          value: elementB,
          label: elementA.names.find((i) => i.locale === "en").name,
          disabled: elementA.activity === "INACTIVE",
        });
      }
    })
  );
  return arr;
};

export const languageLocaleData = (array1, array2) => {
  return array1
    .filter((obj1) => !array2.map((item) => item?.locale).includes(obj1?.code))
    .map((i) => ({
      value: i?.code,
      label:
        i?.code +
        " - " +
        i?.names.filter((n) => n.locale === "en").map((item) => item.name),
      disabled: i.activity === "INACTIVE",
    }));
};

export const languageLocaleObject = (array1, array2) => {

  return array1
    .filter((obj1) => !array2.map((item) => item).includes(obj1?.code))
    .map((i) => ({
      value: i?.code,
      label:
        i?.code +
        " - " +
        i?.names.filter((n) => n.locale === "en").map((item) => item.name),
      disabled: i.activity === "INACTIVE",
    }));
};
