import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { highlightsApi } from "../../http/services";

const initialState = {
  status: "pending",
  highlights: [],
  highlight: {},
  resource: null,
};

export const fetchHighlightsThunk = createAsyncThunk(
  "highlights/getData",
  async () => {
    return await highlightsApi.getHighlights();
  }
);

export const fetchHighlightByIdThunk = createAsyncThunk(
  "highlight/getDataById",
  async (id) => {
    return await highlightsApi.getHighlightById(id);
  }
);

export const deleteHighlightThunk = createAsyncThunk(
  "highlight/deleteData",
  async (id) => {
    return await highlightsApi.deleteHighlight(id);
  }
);

export const updateHighlightThunk = createAsyncThunk(
  "highlight/updateDataById",
  async (args) => {
    return await highlightsApi.updateHighlight(args);
  }
);

export const createHighlightThunk = createAsyncThunk(
  "highlight/createHighlight",
  async (args) => {
    return highlightsApi.createHighlight(args);
  }
);

const highlights = createSlice({
  name: "highlights",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchHighlightsThunk.fulfilled, (state, action) => {
      return { ...state, status: "fulfilled", highlights: action.payload };
    });
    builder.addCase(fetchHighlightByIdThunk.fulfilled, (state, action) => {
      return { ...state, status: "fulfilled", highlight: action.payload };
    });
    builder.addCase(deleteHighlightThunk.fulfilled, (state, action) => {
      return { ...state, status: "fulfilled" };
    });
    builder.addCase(updateHighlightThunk.fulfilled, (state, action) => {
      return { ...state, status: "fulfilled" };
    });
    builder.addCase(createHighlightThunk.fulfilled, (state, action) => {
      return { ...state, status: "fulfilled" };
    });
    builder.addMatcher(
      isAnyOf(
        fetchHighlightsThunk.rejected,
        fetchHighlightByIdThunk.rejected,
        deleteHighlightThunk.rejected,
        updateHighlightThunk.rejected,
        createHighlightThunk.rejected
      ),
      (state) => ({ ...state, status: "rejected" })
    );
  },
});

const { reducer } = highlights;
export { reducer as highlightsReducer };
