import {
  Button,
  Center,
  Group,
  LoadingOverlay,
  Pagination,
  ScrollArea,
  Select,
  Table,
  Text,
  TextInput,
  Tooltip,
  UnstyledButton,
  createStyles,
} from "@mantine/core";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ChevronDown,
  ChevronUp,
  Package,
  PackageOff,
  Pencil,
  Search,
  Selector,
  World,
} from "tabler-icons-react";
import { fetchCountriesDataThunk } from "../../store/slices/countries";
import { fetchGoldFinenessDataThunk } from "../../store/slices/goldFineness";
import { fetchManufacturersThunk } from "../../store/slices/manufacturers";
import { fetchPackageDimensionDataThunk } from "../../store/slices/packageDimension";
import {
  fetchProductByIdThunk,
  fetchProductDataThunk,
  updateProductThunk,
} from "../../store/slices/product";
import { fetchProductCategoryDataThunk } from "../../store/slices/productCategory";
import { fetchProductMaterialDataThunk } from "../../store/slices/productMaterial";
import CustomLoader from "../common/CustomLoader";
import TableCell from "../common/TableCell";
import ProductModal from "./ProductsModal";
import { showNotification } from "@mantine/notifications";

const useStyles = createStyles((theme) => ({
  th: {
    padding: "0 !important",
  },
  tableStyle: {
    tableLayout: "fixed",
    minWidth: 700,
  },
  control: {
    width: "100%",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,

    "&:hover": {
      backgroundColor: theme.colors.colorLightGray,
    },
  },
  scrollArea: {
    height: "85vh",
  },
  searchAndButtonContainter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
  },
  searchInput: {
    width: "25vw",
    marginBottom: "0px !important",
  },
  buttonContainer: {
    display: "flex",
    width: "280px",
    paddingRight: "16px",
    flexWrap: "wrap",
    "@media (max-width: 1150px)": {
      flexDirection: "column",
    },
  },
  insertButton: {
    alignSelf: "flex-end",
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
  },
  buttonColumn: {
    width: "300px !important",
  },
  tableIconsButton: {
    padding: "0px",
    "&:hover": {
      backgroundColor: theme.colors.colorGray,
    },
  },
  tableIconsLogo: {
    color: theme.colors.colorDarkGray,
    "&:hover": {
      color: theme.colors.colorBlack,
    },
  },
  tableIconsTrash: {
    color: theme.colors.colorRed,
    "&:hover": {
      color: theme.colors.colorRed,
    },
  },
  searchInputMargin: {
    marginBottom: "0px",
  },
  tableHeaders: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    background: "white",
  },
}));

const ProductsTable = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [sortMode, setSortMode] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [updateData, setUpdateData] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [tableState, setTableState] = useState({
    page: 0,
    size: "10",
    sortBy: null,
    searchTerm: "",
  });

  const locale = useSelector((state) => state.settings.language);
  const { content: tableData, totalPages } = useSelector(
    (state) => state.product.tableData
  );
  const status = useSelector((state) => state.product.status);

  useEffect(() => {
    dispatch(fetchProductDataThunk(tableState));
  }, [tableState.page, tableState.size, tableState.sortBy]);

  useEffect(() => {
    dispatch(fetchProductCategoryDataThunk());
    dispatch(fetchCountriesDataThunk());
    dispatch(fetchManufacturersThunk());
    dispatch(fetchGoldFinenessDataThunk());
    dispatch(fetchPackageDimensionDataThunk());
    dispatch(fetchProductMaterialDataThunk());
  }, []);

  const closeModal = async () => {
    setModalVisible(false);
    setIsUpdate(false);
    setUpdateData([]);
  };

  const setSorting = (field) => {
    const reversed = field === sortMode ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortMode(field);
  };

  const timeoutId = useRef(null);
  const prevSearchTerm = useRef("");

  /**
   * Handles the change event of the search input field with a condition
   * to check if the previous search term was longer than the current one, which would indicate that the user is deleting input.
   *
   * This ensures that the data is filtered when the user is typing and the length of value is greater than 2, or when the user is deleting input.
   *
   * There is also a 500ms debounce that prevents the data from being filtered on every keystroke.
   * @param {Event} event - The change event object.
   */

  const handleSearchChange = (event) => {
    const { value } = event.currentTarget;
    setTableState((prev) => ({ ...prev, searchTerm: value }));

    if (timeoutId.current) clearTimeout(timeoutId.current);

    if (value.length > 2 || prevSearchTerm.current.length > value.length) {
      timeoutId.current = setTimeout(async () => {
        if (tableState.page !== 0) {
          setTableState((prev) => ({ ...prev, page: 0 }));
        } else {
          await dispatch(
            fetchProductDataThunk({ ...tableState, searchTerm: value })
          ).unwrap();
        }
      }, 500);
    }

    prevSearchTerm.current = value;
  };

  const Th = ({ children, reversed, sorted, onSort }) => {
    const { classes } = useStyles();
    const Icon = sorted ? (reversed ? ChevronUp : ChevronDown) : Selector;
    return (
      <th className={classes.th}>
        <UnstyledButton onClick={onSort} className={classes.control}>
          <Group position="left">
            <Text weight={500} size="sm">
              {children}
            </Text>
            <Center className={classes.icon}>
              <Icon size={14} />
            </Center>
          </Group>
        </UnstyledButton>
      </th>
    );
  };

  const getLocalizedName = (inputArray = []) => {
    let defaultName = "";
    let localizedName = "";
    for (let i = 0; i < inputArray.length; i++) {
      if (inputArray[i].locale === locale) {
        localizedName = inputArray[i].name;
      } else if (inputArray[i].locale === "en") {
        defaultName = inputArray[i].name;
      }
    }
    return localizedName !== "" ? localizedName : defaultName;
  };

  const getLocalizedDescription = (inputArray = []) => {
    let defaultDescription = "";
    let localizedDescription = "";
    for (let i = 0; i < inputArray.length; i++) {
      if (inputArray[i].locale === locale) {
        localizedDescription = inputArray[i].description;
      } else if (inputArray[i].locale === "en") {
        defaultDescription = inputArray[i].description;
      }
    }
    return localizedDescription !== ""
      ? localizedDescription
      : defaultDescription;
  };

  useEffect(() => {
    switch (sortMode) {
      case "skuName":
        setTableState((prev) => ({
          ...prev,
          sortBy: !reverseSortDirection ? "SKU_NAME_ASC" : "SKU_NAME_DESC",
          page: 0,
        }));
        break;
      case "skuStatus":
        setTableState((prev) => ({
          ...prev,
          sortBy: !reverseSortDirection ? "SKU_STATUS_ASC" : "SKU_STATUS_DESC",
          page: 0,
        }));
        break;
      case "skuId":
        setTableState((prev) => ({
          ...prev,
          sortBy: !reverseSortDirection ? "SKU_ID_ASC" : "SKU_ID_DESC",
          page: 0,
        }));
        break;
      case "name":
        setTableState((prev) => ({
          ...prev,
          sortBy: !reverseSortDirection ? "NAME_ASC" : "NAME_DESC",
          page: 0,
        }));
        break;
      case "category":
        setTableState((prev) => ({
          ...prev,
          sortBy: !reverseSortDirection
            ? "CATEGORY_NAME_ASC"
            : "CATEGORY_NAME_DESC",
          page: 0,
        }));
        break;
      case "manufacturer":
        setTableState((prev) => ({
          ...prev,
          sortBy: !reverseSortDirection
            ? "MANUFACTURER_NAME_ASC"
            : "MANUFACTURER_NAME_DESC",
          page: 0,
        }));
        break;
      case "status":
        setTableState((prev) => ({
          ...prev,
          sortBy: !reverseSortDirection ? "STATUS_ASC" : "STATUS_DESC",
          page: 0,
        }));
        break;
      case "recommendOrdinal":
        setTableState((prev) => ({
          ...prev,
          sortBy: !reverseSortDirection ? "RECOMMEND_ORDINAL_ASC" : "RECOMMEND_ORDINAL_DESC",
          page: 0,
        }));
        break;

      default:
        break;
    }
  }, [sortMode, reverseSortDirection]);

  const prepareTableData = (data) => {
    if (data === undefined) {
      return [];
    } else {
      var tableData = data.map((element) => ({
        id: element.id,
        skuName: element.skuName === null ? "" : element.skuName,
        skuStatus: element.skuStatus === null ? "" : element.skuStatus,
        skuId: element.skuId === null ? "" : element.skuId,
        name: element.name,
        manufacturerName: element.manufacturerName,
        categoryName: element.categoryName,
        status: element.status === null ? "" : element.status,
        recommendOrdinal: element.recommendOrdinal === null ? 0 : element.recommendOrdinal
      }));

      return tableData;
    }
  };

  const updateProduct = (data) => {
    setIsUpdate(true);
    setModalData(tableData);
    setUpdateData(data);
    setModalVisible(true);
  };

  const rows = useMemo(() => {
    return prepareTableData(tableData).map((row) => (
      <tr key={row.id}>
        {TableCell(row.skuName || "—")}
        {TableCell(row.skuStatus || "—")}
        {TableCell(row.skuId || "—")}
        {TableCell(row.name || "—")}
        {TableCell(row.categoryName || "—")}
        {TableCell(row.manufacturerName || "—")}
        {TableCell(row.status || "—")}
        {TableCell(row.recommendOrdinal || 0)}
        <td className={classes.buttonColumn}>
          <Group position="right" className={classes.buttonContainer}>
            {row.status !== "DRAFT" && (
              <Tooltip
                label={
                  row["status"] === "ACTIVE"
                    ? t("productsTable.changeStatusToInactive")
                    : t("productsTable.changeStatusToActive")
                }
              >
                <Button
                  variant="subtle"
                  className={classes.tableIconsButton}
                  onClick={async () => {
                    const res = await dispatch(
                      fetchProductByIdThunk(row.id)
                    ).unwrap();
                    const changeStatus =
                      row["status"] === "ACTIVE" ? "INACTIVE" : "ACTIVE";

                    const newObj = {
                      ...res,
                      name: getLocalizedName(res.names),
                      description: getLocalizedDescription(res.descriptions),
                      shortDescription: getLocalizedDescription(
                        res.shortDescriptions
                      ),
                      status: changeStatus,
                    };

                    try {
                      await dispatch(
                        updateProductThunk({
                          updateData: newObj,
                          updateObject: newObj,
                        })
                      ).unwrap();
                    } catch (error) {
                      console.error(error);
                      showNotification({
                        message: t("common.error"),
                        color: "red",
                      });
                    }
                    await dispatch(fetchProductDataThunk(tableState)).unwrap();
                  }}
                >
                  {row.status === "ACTIVE" ? (
                    <Package className={classes.tableIconsLogo} />
                  ) : (
                    <PackageOff className={classes.tableIconsLogo} />
                  )}
                </Button>
              </Tooltip>
            )}
            <Tooltip label={t("tooltips.localization")}>
              <Button
                disabled={row.categoryId === null ? true : false}
                variant="subtle"
                className={classes.tableIconsButton}
                onClick={async () => {
                  await dispatch(fetchProductByIdThunk(row.id)).unwrap();
                  navigate(row.id);
                }}
              >
                <World
                  className={
                    row.categoryId === null ? null : classes.tableIconsLogo
                  }
                />
              </Button>
            </Tooltip>
            <Tooltip label={t("tooltips.edit")}>
              <Button
                variant="subtle"
                className={classes.tableIconsButton}
                onClick={async () => {
                  const res = await dispatch(
                    fetchProductByIdThunk(row.id)
                  ).unwrap();
                  updateProduct({
                    ...res,
                    name: getLocalizedName(res.names),
                    description: getLocalizedDescription(res.descriptions),
                    shortDescription: getLocalizedDescription(
                      res.shortDescriptions
                    ),
                  });
                }}
              >
                <Pencil className={classes.tableIconsLogo} />
              </Button>
            </Tooltip>
          </Group>
        </td>
      </tr>
    ));
  }, [tableData, tableState]);

  return (
    <>
      <div className={classes.searchAndButtonContainter}>
        <TextInput
          className={classes.searchInput}
          placeholder={t("table.search")}
          mb="md"
          icon={<Search size={14} />}
          value={tableState.searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      <ScrollArea className={classes.scrollArea}>
        <Table
          horizontalSpacing="lg"
          verticalSpacing="xs"
          className={classes.tableStyle}
        >
          <thead className={classes.tableHeaders}>
            <tr>
              <Th
                sorted={sortMode === "skuName"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("skuName")}
              >
                {t("productsTable.skuName")}
              </Th>

              <Th
                sorted={sortMode === "skuStatus"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("skuStatus")}
              >
                {t("productsTable.skuStatus")}
              </Th>

              <Th
                sorted={sortMode === "skuId"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("skuId")}
              >
                {t("productsTable.skuID")}
              </Th>

              <Th
                sorted={sortMode === "name"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("name")}
              >
                {t("productsTable.name")}
              </Th>
              <Th
                sorted={sortMode === "category"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("category")}
              >
                {t("productsTable.category")}
              </Th>

              <Th
                sorted={sortMode === "manufacturer"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("manufacturer")}
              >
                {t("productsTable.manufacturer")}
              </Th>
              <Th
                sorted={sortMode === "status"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("status")}
              >
                {t("productsTable.status")}
              </Th>
              <Th
                sorted={sortMode === "recommendOrdinal"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("recommendOrdinal")}
              >
                {t("productsTable.recommendOrdinal")}
              </Th>

              <th className={classes.buttonColumn}></th>
            </tr>
          </thead>
          <tbody style={{}}>
            {status === "pending" && (
              <tr>
                <td style={{ borderBottom: "none" }}>
                  <LoadingOverlay loader={CustomLoader()} visible />
                </td>
              </tr>
            )}

            {rows.length > 0 ? (
              rows
            ) : (
              <tr>
                <td colSpan={8}>
                  <Text weight={500} align="center">
                    {t("table.nothingFound")}
                  </Text>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </ScrollArea>
      {totalPages > 0 && (
        <Group style={{ bottom: 5, position: "absolute", right: 5 }}>
          <Pagination
            page={tableState.page + 1}
            onChange={(page) =>
              setTableState((prev) => ({ ...prev, page: page - 1 }))
            }
            total={totalPages}
            color={"dark"}
          />
          <Select
            styles={(theme) => ({
              root: {
                width: 65,
              },
              item: {
                // applies styles to selected item

                "&[data-selected]": {
                  "&, &:hover": {
                    backgroundColor: "#e7f5ff",

                    color: theme.colors.colorDarkGray,
                  },
                },
              },
            })}
            data={["10", "25", "50"]}
            placeholder={t("posts.mediaGallery.pageSize")}
            value={tableState.size}
            onChange={(value) =>
              setTableState((prev) => ({ ...prev, page: 0, size: value }))
            }
          />
        </Group>
      )}
      <ProductModal
        opened={modalVisible}
        onClose={closeModal}
        modalData={modalData}
        isUpdate={isUpdate}
        updateData={updateData}
        tableState={tableState}
      />
    </>
  );
};

export default ProductsTable;
