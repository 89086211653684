import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllPageSeo, getPageSeoById, updatePageSeo } from "../../http/services/PageSeo";

export const getPageSeoThunk = createAsyncThunk(
  "pageSeo/getPageSeo",
  async () => {
    return await getAllPageSeo();
  }
);

export const getPageSeoByIdThunk = createAsyncThunk(
  "pageSeo/getPageSeoById",
  async (id) => {
    return await getPageSeoById(id);
  }
);

export const updatePageSeoThunk = createAsyncThunk(
  "pageSeo/updatePageSeo",
  async (data, { dispatch }) => {
    await updatePageSeo(data.id, data);
    return await getPageSeoById(data.id);
  }
);

const initialState = {
  seoParameters: [],
  status: "pending",
};

const webSeoSlice = createSlice({
  name: "parametersAdministration",
  initialState,
  reducers: {
    resetSeoParameters: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPageSeoByIdThunk.pending, (state) => {
        return {
          ...state,
          status: "pending",
        };
      })
      .addCase(getPageSeoByIdThunk.fulfilled, (state, action) => {
        return {
          ...state,
          webSeo: action.payload,
          status: "fulfilled",
        };
      })
      .addCase(getPageSeoByIdThunk.rejected, (state) => {
        return {
          ...state,
          status: "rejected",
        };
      })
      .addCase(updatePageSeoThunk.pending, (state) => {
        return {
          ...state,
          status: "pending",
        };
      })
      .addCase(updatePageSeoThunk.fulfilled, (state, action) => {
        return {
          ...state,
          webSeo: action.payload,
          status: "fulfilled",
        };
      })
      .addCase(updatePageSeoThunk.rejected, (state) => {
        return {
          ...state,
          status: "rejected",
        };
      });
  },
});
const { reducer, actions } = webSeoSlice;
export const { resetSeoParameters } = actions;
export { reducer as webSeoReducer };
