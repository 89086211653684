import {
  Card,
  createStyles,
  Divider,
  SimpleGrid,
  TextInput,
} from "@mantine/core";
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  root: { marginTop: 10 },
  mainContainer: {
    height: "auto",
    borderRadius: 12,
    borderStyle: "outset",
    display: "flex",
    flexDirection: "column",
    padding: 20,
    border: "1px solid #c9c9c9",
  },
  input: {
    height: "auto",
    paddingTop: 18,
  },
  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: theme.spacing.sm / 2,
    zIndex: 1,
  },
}));

export const CardCustomerData = (props) => {
  const {
    givenName,
    familyName,
    street,
    postalCode,
    place,
    countryCode,
    houseNumber,
    emailAddress,
  } = props;

  const { t } = useTranslation();
  const { classes } = useStyles();
  return (
    <>
      <Divider
        size="xl"
        my="xs"
        label={t("cardCustomerData.title")}
        labelPosition="center"
      />
      <SimpleGrid cols={2}>
        <Card withBorder>
          <TextInput
            classNames={classes}
            readOnly
            label={t("cardCustomerData.givenName")}
            defaultValue={givenName}
          />
          <TextInput
            classNames={classes}
            readOnly
            label={t("cardCustomerData.familyName")}
            defaultValue={familyName}
          />
          <TextInput
            classNames={classes}
            readOnly
            label={t("cardCustomerData.street")}
            defaultValue={street}
          />
          <TextInput
            classNames={classes}
            readOnly
            label={t("cardCustomerData.houseNumber")}
            defaultValue={houseNumber}
          />
        </Card>
        <Card withBorder>
          <TextInput
            classNames={classes}
            readOnly
            label={t("cardCustomerData.postalCode")}
            defaultValue={postalCode}
          />
          <TextInput
            classNames={classes}
            readOnly
            label={t("cardCustomerData.place")}
            defaultValue={place}
          />
          <TextInput
            classNames={classes}
            readOnly
            label={t("cardCustomerData.countryCode")}
            defaultValue={countryCode}
          />
          <TextInput
            classNames={classes}
            readOnly
            label={t("cardCustomerData.emailAddress")}
            defaultValue={emailAddress}
          />
        </Card>
      </SimpleGrid>
    </>
  );
};
