import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { parametersAdministrationApi } from "../../http/services";
import { fetchParametersAdministrationThunk } from "./parametersAdministration";

export const fetchCompositionParametersByKey = createAsyncThunk(
  "webComposition/fetchCompositionParametersByKey",
  async (key) => {
    const response =
      await parametersAdministrationApi.getParametersAdministrationByKey(key);

    return { key: response.key, valuesArray: JSON.parse(response.value) };
  }
);

export const updateCompositionParameters = createAsyncThunk(
  "webComposition/updateCompositionParameters",
  async (data, { dispatch }) => {
    await parametersAdministrationApi.updateParametersAdministration(
      data.key,
      JSON.stringify(data.valuesArray)
    );
    await dispatch(fetchParametersAdministrationThunk());
    return await dispatch(fetchCompositionParametersByKey(data.key));
  }
);

const initialState = {
  compositionParameters: [],
  status: "pending",
};

const webCompositionSlice = createSlice({
  name: "parametersAdministration",
  initialState,
  reducers: {
    resetCompositionParameters: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompositionParametersByKey.pending, (state) => {
        return {
          ...state,
          status: "pending",
        };
      })
      .addCase(fetchCompositionParametersByKey.fulfilled, (state, action) => {
        return {
          ...state,
          compositionParameters: action.payload,
          status: "fulfilled",
        };
      })
      .addCase(fetchCompositionParametersByKey.rejected, (state) => {
        return {
          ...state,
          status: "rejected",
        };
      })
      .addCase(updateCompositionParameters.pending, (state) => {
        return {
          ...state,
          status: "pending",
        };
      })
      .addCase(updateCompositionParameters.fulfilled, (state, action) => {
        return {
          ...state,
          compositionParameters: action.payload,
          status: "fulfilled",
        };
      })
      .addCase(updateCompositionParameters.rejected, (state) => {
        return {
          ...state,
          status: "rejected",
        };
      });
  },
});
const { reducer, actions } = webCompositionSlice;
export const { resetCompositionParameters } = actions;
export { reducer as webCompositionReducer };
