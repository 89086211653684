import {
  Button,
  createStyles,
  Group,
  Modal,
  NumberInput,
  Select,
  SimpleGrid,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import * as Yup from "yup";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelBuybackThunk,
  completeBuybackThunk,
  fetchBuybackByIdThunk,
  updateBuybackAppraisalThunk,
  updateBuybackTrackingThunk,
} from "../../store/slices/buyback";
import { useParams } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  insertButton: {
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
    color: theme.colors.colorWhite,
  },
  input: {
    height: "auto",
    paddingTop: 18,
    marginTop: 20,
    zIndex: 2,
  },

  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: theme.spacing.sm / 2,
    zIndex: 1,
  },
}));

const BuybackUpdates = ({ detailsData }) => {
  const dispatch = useDispatch();

  const [openedTracking, setOpenedTracking] = useState(false);
  const [openedAppraisal, setOpenedAppraisal] = useState(false);
  const [openedComplete, setOpenedComplete] = useState(false);
  const [openedCancel, setOpenedCancel] = useState(false);
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { id } = useParams();
  const buybackId = useSelector((state) => state?.buyback?.buybackId);

  const schema = Yup.object().shape({
    userCancelNote: Yup.string().max(
      1000,
      t("buybackUpdates.lengthValidation")
    ),
    userCompleteNote: Yup.string().max(
      1000,
      t("buybackUpdates.lengthValidation")
    ),
  });

  const form = useForm({
    initialValues: {
      trackingNumber: "",
      courierCode: "",
      appraisalAmount: null,
      appraisalCurrency: "EUR",
      userCancelNote: "",
      userCompleteNote: "",
    },
    validate: yupResolver(schema),
  });

  const updateTrackingData = {
    trackingNumber: !!form?.values?.trackingNumber
      ? form?.values?.trackingNumber
      : "",
    courierCode:
      form?.values?.courierCode === "hp"
        ? "HP"
        : form?.values?.courierCode &&
          form?.values?.courierCode === "mail boxes"
        ? "MAIL_BOXES"
        : form?.values?.courierCode,
  };

  const updateAppraisalData = {
    amount: !!form?.values?.appraisalAmount
      ? form?.values?.appraisalAmount
      : "",
    currency: "EUR",
  };

  const submitTrackingForm = async () => {
    try {
      await dispatch(
        updateBuybackTrackingThunk({
          id: buybackId,
          data: updateTrackingData,
        })
      ).unwrap();
      showNotification({
        message: t("buybackUpdates.successTracking"),
        color: "green",
      });
    } catch (error) {
      showNotification({
        message: t("buybackUpdates.error"),
        color: "red",
      });
    }
    await dispatch(fetchBuybackByIdThunk(id));
    setOpenedTracking(false);
  };

  const submitAppraisalForm = async () => {
    try {
      await dispatch(
        updateBuybackAppraisalThunk({
          id: buybackId,
          data: updateAppraisalData,
        })
      ).unwrap();
      showNotification({
        message: t("buybackUpdates.successAppraisal"),
        color: "green",
      });
    } catch (error) {
      showNotification({
        message: t("buybackUpdates.error"),
        color: "red",
      });
    }
    await dispatch(fetchBuybackByIdThunk(id));
    setOpenedAppraisal(false);
  };
  const submitCancelBybck = async (data) => {
    try {
      await dispatch(
        cancelBuybackThunk({
          id: buybackId,
          data: data?.userCancelNote,
        })
      ).unwrap();
      showNotification({
        message: t("buybackUpdates.cancelSuccessfull"),
        color: "green",
      });
    } catch (error) {
      showNotification({
        message: t("buybackUpdates.error"),
        color: "red",
      });
    }
    await dispatch(fetchBuybackByIdThunk(id));
    form.clearErrors();
    setOpenedCancel(false);
  };

  const submitCompleteBybck = async (data) => {
    try {
      await dispatch(
        completeBuybackThunk({
          id: buybackId,
          data: data?.userCompleteNote,
        })
      ).unwrap();
      showNotification({
        message: t("buybackUpdates.completeSuccessfull"),
        color: "green",
      });
    } catch (error) {
      showNotification({
        message: t("buybackUpdates.error"),
        color: "red",
      });
    }
    await dispatch(fetchBuybackByIdThunk(id));
    form.clearErrors();
    setOpenedComplete(false);
  };

  return (
    <>
      <SimpleGrid style={{ display: "flex", marginBottom: 20 }}>
        {detailsData?.status === "USER_COMPLETED_REQUEST" && (
          <Button
            variant="subtle"
            onClick={() => setOpenedComplete(true)}
            className={classes.insertButton}
          >
            {t("buybackUpdates.complete")}
          </Button>
        )}

        {(detailsData?.status === "PENDING" ||
          detailsData?.status === "APPRAISED" ||
          detailsData?.status === "USER_COMPLETED_REQUEST") && (
          <Button
            variant="subtle"
            onClick={() => setOpenedCancel(true)}
            className={classes.insertButton}
          >
            {t("buybackUpdates.cancel")}
          </Button>
        )}

        {detailsData?.tracking === null &&
          detailsData?.productLocation === "ON_PERSON" &&
          detailsData?.delivery?.productDeliveryMethod ===
            "BUYBACK_DELIVERY_KIT" && (
            <Button
              variant="subtle"
              onClick={() => setOpenedTracking(true)}
              className={classes.insertButton}
            >
              {t("buybackUpdates.tracking")}
            </Button>
          )}

        {detailsData?.status === "PENDING" && (
          <Button
            variant="subtle"
            onClick={() => setOpenedAppraisal(true)}
            className={classes.insertButton}
          >
            {t("buybackUpdates.appraisal")}
          </Button>
        )}
      </SimpleGrid>
      <Modal
        closeOnClickOutside={false}
        opened={openedTracking}
        onClose={() => {
          setOpenedTracking(false);
          form.reset();
        }}
        centered
        title={<Text weight={500}>{t("buybackUpdates.tracking")}</Text>}
      >
        <form onSubmit={form.onSubmit(submitTrackingForm)}>
          <TextInput
            classNames={classes}
            label={t("buybackUpdates.trackingLabel1")}
            placeholder={t("buybackUpdates.trackingPlaceholder1")}
            {...form.getInputProps("trackingNumber")}
          />

          <Select
            classNames={classes}
            searchable
            data={["HP", "MAIL_BOXES"]}
            label={t("buybackUpdates.trackingLablel2")}
            placeholder={t("buybackUpdates.trackingPlaceholder2")}
            {...form.getInputProps("courierCode")}
            styles={(theme) => ({
              item: {
                // applies styles to selected item

                "&[data-selected]": {
                  "&, &:hover": {
                    backgroundColor: "#e7f5ff",

                    color: theme.colors.colorDarkGray,
                  },
                },
              },
            })}
          />

          <Group position="right" mt="md">
            <Button
              disabled={
                form.values.trackingNumber === "" ||
                form.values.courierCode === ""
              }
              type="submit"
              className={classes.insertButton}
            >
              {t("buybackUpdates.trackingButton")}
            </Button>
          </Group>
        </form>
      </Modal>
      <Modal
        closeOnClickOutside={false}
        opened={openedAppraisal}
        onClose={() => {
          setOpenedAppraisal(false);
          form.reset();
        }}
        centered
        title={<Text weight={500}>{t("buybackUpdates.appraisal")}</Text>}
      >
        <form onSubmit={form.onSubmit(submitAppraisalForm)}>
          <NumberInput
            classNames={classes}
            hideControls
            min={0}
            parser={(value) => value.replace(/\EUR\s?|(,*)/g, "")}
            formatter={(value) =>
              !Number.isNaN(parseInt(value))
                ? `EUR ${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                : "EUR "
            }
            label={t("buybackUpdates.appraisalLabel1")}
            placeholder={t("buybackUpdates.appraisalPlaceholder1")}
            {...form.getInputProps("appraisalAmount")}
          />

          <Group position="right" mt="md">
            <Button
              disabled={Number.isNaN(parseInt(form?.values?.appraisalAmount))}
              type="submit"
              className={classes.insertButton}
            >
              {t("buybackUpdates.appraisalButton")}
            </Button>
          </Group>
        </form>
      </Modal>

      <Modal
        closeOnClickOutside={false}
        opened={openedComplete}
        onClose={() => {
          setOpenedComplete(false);
          form.reset();
        }}
        centered
        title={<Text weight={500}>{t("buybackUpdates.completeBybck")}</Text>}
      >
        <form onSubmit={form.onSubmit(submitCompleteBybck)}>
          <Textarea
            classNames={classes}
            minRows={4}
            autosize
            label={t("buybackDetails.userNote")}
            {...form.getInputProps("userCompleteNote")}
          />
          <Group position="right" mt="md">
            <Button type="submit" className={classes.insertButton}>
              {t("buybackUpdates.modalButton")}
            </Button>
          </Group>
        </form>
      </Modal>
      <Modal
        opened={openedCancel}
        closeOnClickOutside={false}
        onClose={() => {
          setOpenedCancel(false);
          form.reset();
        }}
        centered
        title={<Text weight={500}>{t("buybackUpdates.cancelBybck")}</Text>}
      >
        <form onSubmit={form.onSubmit(submitCancelBybck)}>
          <Textarea
            classNames={classes}
            minRows={4}
            autosize
            label={t("buybackDetails.userNote")}
            {...form.getInputProps("userCancelNote")}
          />

          <Group position="right" mt="md">
            <Button type="submit" className={classes.insertButton}>
              {t("buybackUpdates.modalButton")}
            </Button>
          </Group>
        </form>
      </Modal>
    </>
  );
};

export default BuybackUpdates;
