import {
  Box,
  Button,
  Collapse,
  Divider,
  Group,
  Loader,
  ScrollArea,
  Text,
  TextInput,
  createStyles,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchChatThreadsArchiveSearch,
  fetchChatThreadsByArchiveThunk,
  fetchChatThreadsMsgsThunk,
  fetchChatThreadsThunkById,
  setOpenedArchive,
} from "../../../../store/slices/chat";
import images from "../../../../assets/images";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Search, X } from "tabler-icons-react";
import { InView } from "react-intersection-observer";

const useStyles = createStyles((theme) => ({
  scrollArea: {
    height: "100vh",
    overflow: "auto",
    flex: 1,
    marginRight: 5,
  },
  btnSearchDiv: {
    display: "flex",
  },
  itemsDiv: {
    "&:hover": { backgroundColor: "#EAEBEC" },
    cursor: "pointer",
    padding: 16,
  },
  imgTextDiv: {
    display: "flex",
    alignItems: "center",
  },
  noActiveAccountsDiv: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
}));

const Archive = (props) => {
  const { openedArchive, setOpenChatArchive, openChat } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [opened, setOpened] = useState(false);
  const [page, setPage] = useState(0);

  const chatThreadArchive = useSelector(
    (state) => state?.chat?.chatThreadArchive || []
  );
  const statusArchive = useSelector((state) => state?.chat?.statusArchive);

  const totalPagesArchive = useSelector(
    (state) => state?.chat?.totalPagesArchive
  );
  const acsIdentityAndTokens = useSelector(
    (state) => state?.chat?.acsIdentityAndTokens
  );
  useEffect(() => {
    dispatch(
      fetchChatThreadsByArchiveThunk({
        archived: openedArchive,
        sortBy: ["LAST_MESSAGE_RECEIVED_ON_DESC"],
        page,
        size: 10,
      })
    );
  }, [page]);

  return (
    <>
      <ScrollArea className={classes.scrollArea}>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <Group position="apart" style={{ padding: 16 }}>
            <Text weight={650} size={"lg"}>
              {t("customerSupport.archive")}
            </Text>
            <Box className={classes.btnSearchDiv}>
              <Button
                compact
                color="dark"
                variant="subtle"
                onClick={async () => {
                  dispatch(setOpenedArchive(false));
                }}
              >
                {" "}
                <img src={images.backArrow} />
              </Button>

              <Button
                compact
                color="dark"
                variant="subtle"
                onClick={() => setOpened((o) => !o)}
              >
                {" "}
                <Search />
              </Button>
              <Button
                compact
                color="dark"
                variant="subtle"
                onClick={() => {
                  setPage(0);
                }}
              >
                {" "}
                <img src={images.archiveActive} />
              </Button>
            </Box>
          </Group>
          <Collapse in={opened}>
            <TextInput
              style={{ padding: 10 }}
              rightSection={<X onClick={() => setOpened((o) => !o)} />}
              onChange={async (e) => {
                if (openedArchive && e?.target?.value?.length === 0) {
                  setPage(0);
                  await dispatch(
                    fetchChatThreadsArchiveSearch({
                      archived: true,
                      sortBy: ["LAST_MESSAGE_RECEIVED_ON_DESC"],
                      participantName: e?.target?.value,
                      page: 0,
                      size: 10,
                    })
                  );
                }
                if (openedArchive && e?.target?.value?.length > 2) {
                  await dispatch(
                    fetchChatThreadsArchiveSearch({
                      archived: true,
                      sortBy: ["LAST_MESSAGE_RECEIVED_ON_DESC"],
                      participantName: e?.target?.value,
                    })
                  );
                }
              }}
            />
          </Collapse>
          {chatThreadArchive.length !== 0 ? (
            chatThreadArchive.map((item) => (
              <Box
                className={classes.itemsDiv}
                key={item.id}
                onClick={async () => {
                  openChat(false);
                  setOpenChatArchive(true);
                  await dispatch(
                    fetchChatThreadsThunkById({ id: item?.id })
                  ).unwrap();
                  await dispatch(
                    fetchChatThreadsMsgsThunk({
                      id: item?.id,
                      startTime: item?.createdOn,
                      participantIdentityId: acsIdentityAndTokens?.id,
                    })
                  ).unwrap();
                }}
              >
                <Group position="apart">
                  <Box className={classes.imgTextDiv}>
                    <img src={images.chat} />
                    <Box style={{ marginLeft: 10 }}>
                      <Text weight={650}>
                        {
                          item?.participants.find((n) => n?.type === "CUSTOMER")
                            ?.name
                        }
                      </Text>
                      <Text weight={450}>{item?.lastMessageAbbreviate}</Text>
                    </Box>
                  </Box>
                  <Text weight={50} align="right">
                    {!!item?.lastMessageReceivedOn
                      ? dayjs(item?.lastMessageReceivedOn).format(
                          "DD.MM.YYYY HH:mm"
                        )
                      : ""}
                  </Text>
                </Group>
                <Divider style={{ marginTop: 15 }} />
              </Box>
            ))
          ) : (
            <Box className={classes.noActiveAccountsDiv}>
              <img src={images.accounts} />{" "}
              <Text style={{ marginLeft: 10 }} weight={650}>
                {t("customerSupport.noArchiveAccounts")}
              </Text>
            </Box>
          )}
        </Box>
        {statusArchive !== "pending" &&
        chatThreadArchive.length &&
        page < totalPagesArchive - 1 ? (
          <InView
            as="div"
            threshold={1}
            onChange={async (inView) => {
              inView && setPage(page + 1);
            }}
          >
            <Box>
              <Loader />
            </Box>
            <h4>{t("customerSupport.loadingMore")}</h4>
          </InView>
        ) : null}
      </ScrollArea>
    </>
  );
};

export default Archive;
