import {
  Box,
  Card,
  Divider,
  SimpleGrid,
  Text,
  TextInput,
  createStyles,
} from "@mantine/core";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  input: {
    height: "auto",
    paddingTop: 18,
  },
  insertButton: {
    alignSelf: "flex-end",
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
  },
  root: { marginTop: 10 },
  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: theme.spacing.sm / 2,
    zIndex: 1,
  },
  insertButton: {
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
    color: theme.colors.colorWhite,
  },
  tableIconsButton: {
    padding: "0px",
    "&:hover": {
      backgroundColor: theme.colors.colorGray,
    },
  },
  tableIconsLogo: {
    color: theme.colors.colorDarkGray,
    "&:hover": {
      color: theme.colors.colorBlack,
    },
  },
}));

const AcceptanceDataSection = (props) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { detailsData } = props;

  const dataObj = {
    productLocation:
      detailsData?.productLocation !== null ? detailsData?.productLocation : "",
    deliveryCountryCode:
      detailsData?.delivery?.deliveryKit !== null
        ? detailsData?.delivery?.deliveryKit?.deliveryAddress.countryCode
        : "",
    deliveryCountryPostalCode:
      detailsData?.delivery?.deliveryKit !== null
        ? detailsData?.delivery?.deliveryKit?.deliveryAddress.postalCode
        : "",
    deliveryCountryPlace:
      detailsData?.delivery?.deliveryKit !== null
        ? detailsData?.delivery?.deliveryKit?.deliveryAddress.place
        : "",
    deliveryCountryStreet:
      detailsData?.delivery?.deliveryKit !== null
        ? detailsData?.delivery?.deliveryKit?.deliveryAddress.street
        : "",
    personalDeliveryId:
      detailsData?.delivery?.deliveryKit === null
        ? detailsData?.delivery?.personalDelivery?.office?.id
        : "",
    personalDeliveryName:
      detailsData?.delivery?.deliveryKit === null
        ? detailsData?.delivery?.personalDelivery?.office?.name
        : "",
    personalDeliveryPostalCode:
      detailsData?.delivery?.deliveryKit === null
        ? detailsData?.delivery?.personalDelivery?.office?.postalCode
        : "",
    personalDeliveryStreet:
      detailsData?.delivery?.deliveryKit === null
        ? detailsData?.delivery?.personalDelivery?.office?.street
        : "",
    personalDeliveryHouseNumber:
      detailsData?.delivery?.deliveryKit === null
        ? detailsData?.delivery?.personalDelivery?.office?.houseNumber
        : "",
    personalDeliveryDate:
      detailsData?.delivery?.deliveryKit === null
        ? dayjs(detailsData?.delivery?.personalDelivery?.deliveryDate).format(
            "DD.MM.YYYY"
          )
        : "",
  };

  return (
    <>
      <Divider
        size="xl"
        my="xs"
        label={t("buybackDetails.acceptanceData")}
        labelPosition="center"
      />
      <Box style={{ width: "100%", position: "relative" }}>
        <TextInput
          classNames={classes}
          readOnly
          label={t("buybackDetails.productLocation")}
          defaultValue={dataObj?.productLocation}
          style={{ marginBottom: 15 }}
        />
      </Box>
      <SimpleGrid cols={2}>
        <Card withBorder>
          <Text weight={500}>{t("buybackDetails.deliveryKit")}</Text>

          <TextInput
            classNames={classes}
            readOnly
            label={t("buybackDetails.deliveryCountryCode")}
            defaultValue={dataObj?.deliveryCountryCode}
          />
          <TextInput
            classNames={classes}
            readOnly
            label={t("buybackDetails.deliveryCountryPostalCode")}
            defaultValue={dataObj?.deliveryCountryPostalCode}
          />
          <TextInput
            classNames={classes}
            readOnly
            label={t("buybackDetails.deliveryCountryPlace")}
            defaultValue={dataObj?.deliveryCountryPlace}
          />
          <TextInput
            classNames={classes}
            readOnly
            label={t("buybackDetails.deliveryCountryStreet")}
            defaultValue={dataObj?.deliveryCountryStreet}
          />
        </Card>
        <Card withBorder>
          <Text weight={500}>{t("buybackDetails.personalDelivery")}</Text>
          <TextInput
            classNames={classes}
            readOnly
            label={t("buybackDetails.personalDeliveryId")}
            defaultValue={dataObj?.personalDeliveryId}
          />
          <TextInput
            classNames={classes}
            readOnly
            label={t("buybackDetails.personalDeliveryName")}
            defaultValue={dataObj?.personalDeliveryName}
          />
          <TextInput
            classNames={classes}
            readOnly
            label={t("buybackDetails.personalDeliveryPostalCode")}
            defaultValue={dataObj.personalDeliveryPostalCode}
          />
          <TextInput
            classNames={classes}
            readOnly
            label={t("buybackDetails.personalDeliveryStreet")}
            defaultValue={dataObj?.personalDeliveryStreet}
          />
          <TextInput
            classNames={classes}
            readOnly
            label={t("buybackDetails.personalDeliveryHouseNumber")}
            defaultValue={dataObj?.personalDeliveryHouseNumber}
          />
          <TextInput
            classNames={classes}
            readOnly
            label={t("buybackDetails.personalDeliveryDate")}
            defaultValue={dataObj?.personalDeliveryDate}
          />
        </Card>
      </SimpleGrid>
    </>
  );
};

export default AcceptanceDataSection;
