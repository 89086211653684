import { useForm, yupResolver } from "@mantine/form";
import { createStyles, Modal, Button, TextInput, Select } from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { showNotification } from "@mantine/notifications";
import * as Yup from "yup";

import {
  fetchProductCategoryByIdThunk,
  updateProductCategoryThunk,
} from "../../store/slices/productCategory";
import { useParams } from "react-router-dom";
import { languageLocaleData } from "../common/parseLanguageNames";

const useStyles = createStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingTop: `${theme.spacing.xs}px `,
  },
  root: {
    position: "relative",
  },

  input: {
    height: "auto",
    paddingTop: 18,
  },

  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: theme.spacing.sm / 2,
    zIndex: 1,
  },
  insertButton: {
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
  },
}));

const ProductCategoryLocalizationTableModal = (props) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let params = useParams();

  const tableLocalizationData = useSelector(
    (state) => state.productCategories.productCategoryLocalizationTableData
  );
  const languageData = useSelector((state) => state.language.tableData);
  const isNewProductCategoryLocaleItem = () => !props?.data;

  const schema = Yup.object().shape({
    name: Yup.string()
      .min(2, t("productCategoryLocalizationModal.nameValidation"))
      .trim(t("productCategoryLocalizationModal.whitespaceValidation"))
      .strict(true),
    locale: Yup.string().min(
      2,
      t("productCategoryLocalizationModal.localeValidation")
    ),
  });

  const form = useForm({
    initialValues: {
      name: "",
      locale: "",
    },
    validate: yupResolver(schema),
  });

  const prepareDataUpdateObject = (data) => {
    const object = {
      names: data,
      parentCategoryId: tableLocalizationData?.parentCategoryId || null,
      ordinalNumber: tableLocalizationData?.ordinalNumber,
      activity: tableLocalizationData?.activity ? "ACTIVE" : "INACTIVE",
    };
    return object;
  };

  const createProductLocalizationItem = async (data) => {
    let updateData = [...tableLocalizationData.names, data];
    let dataForUpdate = prepareDataUpdateObject(updateData);
    await dispatch(
      updateProductCategoryThunk({
        id: params?.productCategoryId,
        data: dataForUpdate,
      })
    )
      .unwrap()
      .then(() => {
        showNotification({
          message: t("productCategoryLocalizationModal.insertSuccessfull"),
          color: "green",
        });
      })
      .catch((e) => {
        showNotification({
          message: t("productCategoryLocalizationModal.insertFailed"),
          color: "red",
        });
      });

    dispatch(fetchProductCategoryByIdThunk({ id: params?.productCategoryId }));
  };

  const updateProductLocalizationItem = async (data) => {
    let updateData = tableLocalizationData.names.filter(
      (item) => item.locale !== data.locale
    );

    updateData = [...updateData, data];
    let dataForUpdate = prepareDataUpdateObject(updateData);
    await dispatch(
      updateProductCategoryThunk({
        id: params?.productCategoryId,
        data: dataForUpdate,
      })
    )
      .unwrap()
      .then(() => {
        showNotification({
          message: t("productCategoryLocalizationModal.updateSuccessfull"),
          color: "green",
        });
      })
      .catch((e) => {
        showNotification({
          message: t("productCategoryLocalizationModal.updateFailed"),
          color: "red",
        });
      });

    dispatch(fetchProductCategoryByIdThunk({ id: params?.productCategoryId }));
  };

  const submitForm = async (data) => {
    isNewProductCategoryLocaleItem()
      ? createProductLocalizationItem(data)
      : updateProductLocalizationItem(data);
    form.reset();
    props.onClose();
  };

  useEffect(() => {
    form.clearErrors();

    if (props.data) {
      form.setValues({
        name: props.data.name,
        locale: props.data.locale,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);
  return (
    <>
      <Modal
        size={"xl"}
        closeOnClickOutside={false}
        centered
        opened={props.show}
        onClose={() => {
          form.reset();
          props.onClose();
        }}
        title={t("productCategoryLocalizationModal.modalTitle")}
        sx={(theme) => ({
          ".mantine-Modal-title": {
            fontWeight: "bold",
          },
        })}
      >
        <form onSubmit={form.onSubmit(submitForm)}>
          <Select
            disabled={props.data}
            styles={(theme) => ({
              root: {
                marginBottom: 20,
                zIndex: 2,
              },
              item: {
                // applies styles to selected item
                "&[data-selected]": {
                  "&, &:hover": {
                    backgroundColor: "#e7f5ff",
                    color: theme.colors.colorDarkGray,
                  },
                },

                // applies styles to hovered item (with mouse or keyboard)
                "&[data-hovered]": {},
              },
            })}
            data={
              isNewProductCategoryLocaleItem()
                ? languageLocaleData(languageData, tableLocalizationData?.names)
                : languageData.map((i) => ({
                    value: i?.code,
                    label:
                      i?.code +
                      " - " +
                      i?.names
                        .filter((n) => n?.locale === "en")
                        .map(({ name }) => name),
                  }))
            }
            label={t("productCategoryLocalizationModal.locale")}
            placeholder={t(
              "productCategoryLocalizationModal.localePlaceholder"
            )}
            classNames={classes}
            searchable
            {...form.getInputProps("locale")}
          />
          <TextInput
            label={t("productCategoryLocalizationModal.nameInputTitle")}
            placeholder={t(
              "productCategoryLocalizationModal.nameInputPlaceholder"
            )}
            classNames={classes}
            {...form.getInputProps("name")}
          />

          <div className={classes.buttonContainer}>
            <Button type="submit" className={classes.insertButton}>
              {isNewProductCategoryLocaleItem()
                ? t("productCategoryLocalizationModal.insertButton")
                : t("productCategoryLocalizationModal.updateButton")}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default ProductCategoryLocalizationTableModal;
