import React from "react";
import { Box, Checkbox, createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  componentWrapper: {
    paddingTop: theme.spacing.sm,
  },
  componentLabel: {
    paddingLeft: theme.spacing.sm,
    marginBottom: theme.spacing.sm,
  },
  componentContent: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "10px",
  },
}));

export const MultiCheckboxSelect = ({
  data = [],
  value = [],
  onChange = () => {},
  label = "",
}) => {
  const { classes } = useStyles();

  const handleCheckboxChange = (checked, item) => {
    if (checked) {
      onChange([...value, item]);
    } else {
      onChange(value.filter((val) => val !== item));
    }
  };

  return (
    <div className={classes.componentWrapper}>
      <Box className={classes.componentLabel}>{label}</Box>
      <div className={classes.componentContent}>
        {data.map((item) => (
          <Checkbox
            key={item}
            checked={value.includes(item)}
            label={item}
            onChange={() => handleCheckboxChange(!value.includes(item), item)}
          />
        ))}
      </div>
    </div>
  );
};
