import {
  Box,
  Card,
  Divider,
  SimpleGrid,
  Text,
  TextInput,
  createStyles,
} from "@mantine/core";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  input: {
    height: "auto",
    paddingTop: 18,
  },
  insertButton: {
    alignSelf: "flex-end",
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
  },
  root: { marginTop: 10 },
  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: theme.spacing.sm / 2,
    zIndex: 1,
  },
  insertButton: {
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
    color: theme.colors.colorWhite,
  },
  tableIconsButton: {
    padding: "0px",
    "&:hover": {
      backgroundColor: theme.colors.colorGray,
    },
  },
  tableIconsLogo: {
    color: theme.colors.colorDarkGray,
    "&:hover": {
      color: theme.colors.colorBlack,
    },
  },
}));

const AppraisalAndTrackingSection = (props) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { detailsData } = props;

  const dataObj = {
    amount: detailsData?.appraisal?.amount
      ? detailsData?.appraisal?.amount
      : "",
    currency: detailsData?.appraisal?.currency
      ? detailsData?.appraisal?.currency
      : "",
    appraisalStatus: detailsData?.appraisal?.status
      ? detailsData?.appraisal?.status
      : "",
    appraisalCreatedAt:
      detailsData?.appraisal?.createdAt !== null
        ? dayjs(detailsData?.appraisal?.createdAt).format("DD.MM.YYYY HH:mm:ss")
        : "",
    appraisalAcceptedAt:
      detailsData?.appraisal?.acceptedAt !== null
        ? dayjs(detailsData?.appraisal?.acceptedAt).format(
            "DD.MM.YYYY HH:mm:ss"
          )
        : "",
    appraisalRejectedAt:
      detailsData?.appraisal?.rejectedAt !== null
        ? dayjs(detailsData?.appraisal?.rejectedAt).format("DD.MM.YYYY")
        : "",

    trackingNumber: detailsData?.tracking?.trackingNumber
      ? detailsData?.tracking?.trackingNumber
      : "",
    trackingCourierCode: detailsData?.tracking
      ? detailsData?.tracking?.courierCode
      : "",
    trackingCreatedAt: detailsData?.tracking
      ? dayjs(detailsData?.tracking?.createdAt).format("DD.MM.YYYY HH:mm:ss")
      : "",
  };

  return (
    <>
      <SimpleGrid cols={2}>
        <Box>
          <Divider
            size="xl"
            my="xs"
            label={t("buybackDetails.appraisalData")}
            labelPosition="center"
          />

          <Card withBorder>
            <Text weight={500}>{t("buybackDetails.appraisal")}</Text>
            <TextInput
              classNames={classes}
              readOnly
              label={t("buybackDetails.amount")}
              defaultValue={dataObj?.amount}
            />

            <TextInput
              classNames={classes}
              readOnly
              label={t("buybackDetails.currency")}
              defaultValue={dataObj?.currency}
            />

            <TextInput
              classNames={classes}
              readOnly
              label={t("buybackDetails.appraisalStatus")}
              defaultValue={dataObj?.appraisalStatus}
            />
            <TextInput
              classNames={classes}
              readOnly
              label={t("buybackDetails.appraisalCreatedAt")}
              defaultValue={dataObj?.appraisalCreatedAt}
            />
            <TextInput
              classNames={classes}
              readOnly
              label={t("buybackDetails.appraisalAcceptedAt")}
              defaultValue={dataObj?.appraisalAcceptedAt}
            />
            <TextInput
              classNames={classes}
              readOnly
              label={t("buybackDetails.appraisalRejectedAt")}
              defaultValue={dataObj?.appraisalRejectedAt}
            />
          </Card>
        </Box>
        <Box>
          <Divider
            size="xl"
            my="xs"
            label={t("buybackDetails.trackingData")}
            labelPosition="center"
          />
          <Card withBorder>
            <Text weight={500}>{t("buybackDetails.tracking")}</Text>
            <TextInput
              classNames={classes}
              readOnly
              label={t("buybackDetails.trackingNumber")}
              defaultValue={dataObj?.trackingNumber}
            />
            <TextInput
              classNames={classes}
              readOnly
              label={t("buybackDetails.courierCode")}
              defaultValue={dataObj?.trackingCourierCode}
            />
            <TextInput
              classNames={classes}
              readOnly
              label={t("buybackDetails.createdAt")}
              defaultValue={dataObj?.trackingCreatedAt}
            />
          </Card>
        </Box>
      </SimpleGrid>
    </>
  );
};

export default AppraisalAndTrackingSection;
