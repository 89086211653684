import { Box, createStyles } from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchACSIdentityAndTokensThunk,
  setOpenedArchive,
} from "../../store/slices/chat";
import { useContext, useEffect, useState } from "react";
import YourAccounts from "./YourAccounts/YourAccounts";
import NewAccounts from "./NewAccounts/NewAccounts";
import dayjs from "dayjs";
import Chat from "./Chat/Chat";
import { StyleContext } from "../../App";
import Archive from "./YourAccounts/Archive/Archive";
import ArchivedChat from "./YourAccounts/Archive/ArchivedChat";
const useStyles = createStyles((theme) => ({
  mainContainer: {
    height: "100%",
    width: "100%",
  },
  languageSelectContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingRight: `${theme.spacing.md}px`,
  },
}));

const CustomerSupport = () => {
  const { setStyle } = useContext(StyleContext);

  const { classes } = useStyles();

  const [openChat, setOpenChat] = useState(false);
  const [openChatArchive, setOpenChatArchive] = useState(false);
  const chatThreadDataByAgent = useSelector(
    (state) => state?.chat?.chatThreadDataByAgent || []
  );
  const chatThreadMsgs = useSelector(
    (state) => state?.chat?.chatThreadMsgs || []
  );
  const acsIdentityAndTokens = useSelector(
    (state) => state?.chat?.acsIdentityAndTokens
  );

  const openedArchive = useSelector((state) => state?.chat?.openedArchive);

  const now = new Date();
  const expirationTime = dayjs(acsIdentityAndTokens?.commsTokenExpiresAt);

  const dispatch = useDispatch();

  const fetchAcsIdentity = async () => {
    if (
      !acsIdentityAndTokens ||
      dayjs(now).isAfter(expirationTime.subtract(30, "minute"))
    ) {
      return await dispatch(fetchACSIdentityAndTokensThunk()).unwrap();
    }
  };

  useEffect(() => {
    setStyle({ padding: 0 });
    dispatch(setOpenedArchive(false));

    return () => {
      setStyle({ padding: "md" });
    };
  }, []);

  useEffect(() => {
    fetchAcsIdentity();
  }, [chatThreadDataByAgent, chatThreadMsgs]);
  return (
    <div className={classes.mainContainer}>
      <Box style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        {openChat && !openChatArchive && (
          <div style={{ width: "33%" }}>
            <Chat openChat={setOpenChat} />
          </div>
        )}
        {openChatArchive && !openChat && (
          <div style={{ width: "33%" }}>
            <ArchivedChat setOpenChatArchive={setOpenChatArchive} />
          </div>
        )}
        {acsIdentityAndTokens?.id && !openedArchive && (
          <YourAccounts
            openChat={setOpenChat}
            setOpenChatArchive={setOpenChatArchive}
            participantIdentityId={acsIdentityAndTokens?.id}
          />
        )}
        {openedArchive && (
          <Archive
            openedArchive={openedArchive}
            setOpenChatArchive={setOpenChatArchive}
            openChat={setOpenChat}
          />
        )}
        <NewAccounts />
      </Box>
    </div>
  );
};

export default CustomerSupport;
