import {
  Button,
  Center,
  Group,
  LoadingOverlay,
  ScrollArea,
  Table,
  Text,
  TextInput,
  Tooltip,
  UnstyledButton,
  createStyles,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import TableCell from "../common/TableCell";
import {
  ChevronDown,
  ChevronUp,
  Pencil,
  Plus,
  Search,
  Selector,
  Trash,
} from "tabler-icons-react";
import { useState } from "react";
import GoldmanGraffTeamLocalizationModal from "./GoldmanGraffTeamLocalizationModal";
import {
  fetchTeamMemberByIdThunk,
  updateTeamMemberThunk,
} from "../../store/slices/teamMembers";
import { showNotification } from "@mantine/notifications";
import DeleteBanner from "../common/DeleteBanner";
import CustomLoader from "../common/CustomLoader";

const useStyles = createStyles((theme) => ({
  th: {
    padding: "0 !important",
  },
  control: {
    width: "100%",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,

    "&:hover": {
      backgroundColor: theme.colors.colorLightGray,
    },
  },
  scrollArea: {
    height: "85vh",
  },
  searchAndButtonContainter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
  },
  buttonColumn: {
    width: "300px !important",
  },
  buttonContainer: {
    display: "flex",
    width: "280px",
    paddingRight: "16px",
    flexWrap: "wrap",
    "@media (max-width: 1150px)": {
      flexDirection: "column",
    },
  },
  searchInput: {
    width: "25vw",
    marginBottom: "0px !important",
  },
  searchInputMargin: {
    marginBottom: "0px",
  },
  insertButton: {
    alignSelf: "flex-end",
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
  },
  tableIconsLogo: {
    color: theme.colors.colorDarkGray,
    "&:hover": {
      color: theme.colors.colorBlack,
    },
  },
  tableIconsTrash: {
    color: theme.colors.colorRed,
    "&:hover": {
      color: theme.colors.colorRed,
    },
  },
  tableIconsButton: {
    padding: "0px",
    "&:hover": {
      backgroundColor: theme.colors.colorGray,
    },
  },
}));

const GoldmanGraffTeamLocalizationTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { classes } = useStyles();

  const tableData = useSelector((state) => state?.teamMembers?.dataById || []);
  const statusById = useSelector(
    (state) => state?.teamMembers?.statusById || ""
  );

  const [updateData, setUpdateData] = useState(null);
  const [deleteData, setDeleteData] = useState(null);
  const [deleteBannerVisible, setDeleteBannerVisible] = useState(false);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [sortBy, setSortBy] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [search, setSearch] = useState("");
  const closeModal = () => {
    setModalVisible(false);
    setUpdateData(null);
  };

  const setSorting = (field) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
  };

  const closeDeleteBanner = () => {
    setDeleteBannerVisible(false);
  };
  const openModal = async (row) => {
    setUpdateData(row ? row : null);
    setModalVisible(true);
  };

  const deleteLocale = async () => {
    let obj = JSON.parse(JSON.stringify(tableData));

    for (const langKey in obj.localizations) {
      if (
        JSON.stringify(obj.localizations[langKey]) ===
        JSON.stringify(deleteData[langKey])
      ) {
        delete obj.localizations[langKey];

        break;
      }
    }

    await dispatch(updateTeamMemberThunk(obj))
      .unwrap()
      .then(() => {
        showNotification({
          message: t("deliveryMethodLocalizationTable.deleteSucessfull"),
          color: "green",
        });
      })
      .catch((e) => {
        showNotification({
          message: t("deliveryMethodLocalizationTable.deleteError"),
          color: "red",
        });
      });
    await dispatch(fetchTeamMemberByIdThunk(obj.id));
    closeDeleteBanner();
  };

  const searchTableData = (data) => {
    const entriesArray = data;
    if (search !== "") {
      const filteredEntries = entriesArray.filter(
        ([key, value]) =>
          key.toLowerCase().includes(search.toLowerCase()) ||
          value.title.toLowerCase().includes(search.toLowerCase()) ||
          value.description.toLowerCase().includes(search.toLowerCase())
      );
      return filteredEntries;
    } else {
      return entriesArray;
    }
  };

  const handleSearchChange = (event) => {
    const { value } = event.currentTarget;
    setSearch(value);
  };

  const sortTableData = (tableData) => {
    if (tableData && tableData?.localizations) {
      const entriesArray = Object.entries(tableData?.localizations || []);

      if (sortBy === "locale") {
        return !reverseSortDirection
          ? entriesArray.sort((a, b) =>
              a[0].toLowerCase().localeCompare(b[0].toLowerCase())
            )
          : entriesArray.sort((a, b) =>
              b[0].toLowerCase().localeCompare(a[0].toLowerCase())
            );
      } else if (sortBy === "title") {
        return !reverseSortDirection
          ? entriesArray.sort((a, b) =>
              a[1].title.toLowerCase().localeCompare(b[1].title.toLowerCase())
            )
          : entriesArray.sort((a, b) =>
              b[1].title.toLowerCase().localeCompare(a[1].title.toLowerCase())
            );
      } else if (sortBy === "description") {
        return !reverseSortDirection
          ? entriesArray.sort((a, b) =>
              a[1].description
                .toLowerCase()
                .localeCompare(b[1].description.toLowerCase())
            )
          : entriesArray.sort((a, b) =>
              b[1].description
                .toLowerCase()
                .localeCompare(a[1].description.toLowerCase())
            );
      }
      return entriesArray;
    } else {
      return [];
    }
  };

  const Th = ({ children, reversed, sorted, onSort }) => {
    const { classes } = useStyles();
    const Icon = sorted ? (reversed ? ChevronUp : ChevronDown) : Selector;

    return (
      <th className={classes.th}>
        <UnstyledButton className={classes.control} onClick={onSort}>
          <Group position="apart">
            <Text weight={500} size="sm">
              {children}
            </Text>
            <Center className={classes.icon}>
              <Icon size={14} />
            </Center>
          </Group>
        </UnstyledButton>
      </th>
    );
  };

  const rows = searchTableData(sortTableData(tableData))?.map(
    ([key, value]) => (
      <tr key={key}>
        {TableCell(key)}
        {TableCell(value?.title)}
        {TableCell(value?.description)}
        <td className={classes.buttonColumn}>
          <Group position="right" className={classes.buttonContainer}>
            <Tooltip label={t("tooltips.edit")}>
              <Button
                className={classes.tableIconsButton}
                variant="subtle"
                onClick={() => {
                  openModal({ [key]: value });
                }}
              >
                <Pencil className={classes.tableIconsLogo} />
              </Button>
            </Tooltip>
            <Tooltip label={t("tooltips.delete")}>
              <Button
                variant="subtle"
                className={classes.tableIconsButton}
                onClick={() => {
                  setDeleteBannerVisible(true);

                  setDeleteData({ [key]: value });
                }}
              >
                <Trash className={classes.tableIconsTrash} />
              </Button>
            </Tooltip>
          </Group>
        </td>
      </tr>
    )
  );

  return (
    <>
      <ScrollArea className={classes.scrollArea}>
        <div className={classes.searchAndButtonContainter}>
          <TextInput
            className={classes.searchInput}
            placeholder={t("table.search")}
            mb="md"
            icon={<Search size={14} />}
            value={search}
            onChange={handleSearchChange}
          />
          <Button
            className={classes.insertButton}
            style={{ alignSelf: "flex-end" }}
            onClick={() => openModal()}
          >
            <Plus size={18} />
          </Button>
        </div>
        <Table
          horizontalSpacing="md"
          verticalSpacing="xs"
          sx={{ tableLayout: "fixed", minWidth: 700 }}
        >
          <thead>
            <tr>
              <Th
                sorted={sortBy === "locale"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("locale")}
              >
                {t("goldmanGraffTeam.locale")}
              </Th>
              <Th
                sorted={sortBy === "title"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("title")}
              >
                {t("goldmanGraffTeam.title")}
              </Th>
              <Th
                sorted={sortBy === "description"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("description")}
              >
                {t("goldmanGraffTeam.description")}
              </Th>
              <th className={classes.buttonColumn}></th>
            </tr>
          </thead>
          <tbody>
            {statusById === "pending" && (
              <tr>
                <td style={{ borderBottom: "none" }}>
                  <LoadingOverlay loader={CustomLoader()} visible />
                </td>
              </tr>
            )}
            {rows.length ? (
              rows
            ) : (
              <tr>
                <td colSpan={3}>
                  <Text weight={500} align="center">
                    {t("table.nothingFound")}
                  </Text>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </ScrollArea>
      <GoldmanGraffTeamLocalizationModal
        opened={modalVisible}
        onClose={closeModal}
        updateData={updateData}
      />
      <DeleteBanner
        deleteMethod={deleteLocale}
        centered={true}
        opened={deleteBannerVisible}
        onClose={closeDeleteBanner}
        message={t("deleteBanner.message")}
        okButtonText={t("deleteBanner.okButton")}
        cancelButtonText={t("deleteBanner.cancelButton")}
      />
    </>
  );
};

export default GoldmanGraffTeamLocalizationTable;
