import { Button, Card, Group, Text, createStyles } from "@mantine/core";
import {
  fetchChatThreadsByAgentThunk,
  fetchChatThreadsByCustomerThunk,
  leaveChatThreadThunk,
  setPage,
  setPageCustomer,
} from "../../../store/slices/chat";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const useStyles = createStyles((theme) => ({
  cardDiv: {
    background: "#000000",
    width: "60%",
    position: "absolute",
    top: "10%",
    borderRadius: 16,
    left: "20%",
  },
  btnCancel: {
    borderRadius: 16,
    background: "black",
    border: "1px solid #FFFFFF",
    "&:hover": { background: "#A7ADB9" },
  },
  btnFinish: {
    background: "#FFFFFF",
    borderRadius: 16,
    color: "#000000",
    "&:hover": { background: "#A7ADB9" },
  },
  bannerMsgBold: {
    textAlign: "center",
    fontWeight: "bold",
    color: "#FFFFFF",
    marginTop: "20%",
  },
  bannerMsg: {
    textAlign: "center",
    color: "#FFFFFF",
  },
}));

const InfoBanner = (props) => {
  const { setChecked, chatThreadDataById, participantIdentityId, openChat } =
    props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Card className={classes.cardDiv}>
      <Group position="center">
        <Text className={classes.bannerMsgBold}>
          {t("customerSupport.leaveChat")}
        </Text>
        <Text className={classes.bannerMsg}>
          {t("customerSupport.accountArchiveMsg")}
        </Text>
        <Group position="center" style={{ marginTop: "20%" }}>
          <Button
            className={classes.btnCancel}
            onClick={() => setChecked(false)}
          >
            {t("customerSupport.cancel")}
          </Button>
          <Button
            className={classes.btnFinish}
            onClick={async () => {
              setChecked(false);
              openChat(false);
              dispatch(setPage(0));
              dispatch(setPageCustomer(0));
              try {
                await dispatch(
                  leaveChatThreadThunk({ id: chatThreadDataById?.id })
                ).unwrap();
                await dispatch(
                  fetchChatThreadsByAgentThunk({
                    participantIdentityId: participantIdentityId?.id,
                    hasAnyOfParticipantType: "AGENT",
                    sortBy: ["LAST_MESSAGE_RECEIVED_ON_DESC"],
                    page: 0,
                    size: 10,
                    archived: false,
                  })
                ).unwrap();
                await dispatch(
                  fetchChatThreadsByCustomerThunk({
                    noneOfParticipantType: "AGENT",
                    sortBy: ["LAST_MESSAGE_RECEIVED_ON_DESC"],
                    archived: false,
                    page: 0,
                    size: 10,
                  })
                ).unwrap();
                setChecked(false);
              } catch (error) {
                console.log(error);
              }
            }}
          >
            {t("customerSupport.finish")}
          </Button>
        </Group>
      </Group>
    </Card>
  );
};

export default InfoBanner;
