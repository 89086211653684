import {
  Box,
  Button,
  Group,
  Image,
  LoadingOverlay,
  Modal,
  NumberInput,
  SimpleGrid,
  Text,
  TextInput,
  Textarea,
  createStyles,
} from "@mantine/core";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { useForm, yupResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TrashX, Upload } from "tabler-icons-react";
import * as Yup from "yup";
import { createFileThunk } from "../../store/slices/file";
import {
  createTeamMemberThunk,
  fetchTeamMembersThunk,
  updateTeamMemberThunk,
} from "../../store/slices/teamMembers";
import CustomLoader from "../common/CustomLoader";

const useStyles = createStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingTop: `${theme.spacing.xs}px `,
  },
  root: {
    position: "relative",
    marginTop: 20,
  },

  input: {
    height: "auto",
    paddingTop: 18,
  },

  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: theme.spacing.sm / 2,
    zIndex: 1,
  },

  insertButton: {
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
  },
  iconGreen: {
    color: theme.colors.green[5],
    "&:hover": {
      color: theme.colors.green[7],
    },
  },
  iconRed: {
    color: theme.colors.red[5],
    "&:hover": {
      color: theme.colors.red[7],
    },
  },
  errorText: {
    color: "#f03e3e",
    fontSize: "12px",
  },
  itemWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },
  iconWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  tableIconsButton: {
    padding: "0px",
    "&:hover": {
      backgroundColor: theme.colors.colorGray,
    },

    position: "absolute",
    top: 5,

    backgroundColor: theme.colors.colorRed,
  },
}));

const GoldmanGraffTeamModal = (props) => {
  const { opened, onClose, updateData } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const teamMember = useSelector((state) => state?.teamMembers?.dataById || []);
  const statusById = useSelector(
    (state) => state?.teamMembers?.statusById || ""
  );

  const dispatch = useDispatch();

  const schema = Yup.object().shape({
    givenName: Yup.string()
      .required(t("goldmanGraffTeam.givenNameRequired"))
      .trim(t("goldmanGraffTeam.givenNameWhitespaceValidation"))
      .strict(true)
      .max(255, t("goldmanGraffTeam.givenNameLength")),
    familyName: Yup.string()
      .required(t("goldmanGraffTeam.familyNameRequired"))
      .trim(t("goldmanGraffTeam.whitespaceValidation"))
      .strict(true)
      .max(255, t("goldmanGraffTeam.familyNameLength")),
    title: Yup.string()
      .required(t("goldmanGraffTeam.titleRequired"))
      .trim(t("goldmanGraffTeam.titleWhitespaceValidation"))
      .strict(true)
      .max(255, t("goldmanGraffTeam.titleLength")),
    ordinal: Yup.number()
      .typeError(t("goldmanGraffTeam.validNumber"))
      .required(t("goldmanGraffTeam.numberRequired")),
    description: Yup.string()
      .required(t("goldmanGraffTeam.descriptionValidation"))
      .trim(t("goldmanGraffTeam.descriptionWhitespaceValidation"))
      .strict(true)
      .max(2000, t("goldmanGraffTeam.descriptionLength")),
    photoUrl: Yup.string().required(t("goldmanGraffTeam.photoUrl")),
    linkedinUrl: Yup.string()
      .max(255, t("goldmanGraffTeam.linkedInLength"))
      .test(
        "isValidLinkedIn",
        t("goldmanGraffTeam.isValidLinkedIn"),
        (validProfile) => {
          if (validProfile === "") {
            return true;
          } else {
            return /^https:\/\/(www\.)?(.*\.)?linkedin\.com\/.*$/.test(
              validProfile
            );
          }
        }
      )
      .trim(t("goldmanGraffTeam.linkedInProfileWhitespaceValidation"))
      .strict(true),
    email: Yup.string()
      .max(255, t("goldmanGraffTeam.emailLength"))
      .email(t("goldmanGraffTeam.invalidEmail"))
      .matches(/^(?!.*@[^,]*,)/),
  });

  const form = useForm({
    initialValues: {
      givenName: "",
      familyName: "",
      title: "",
      ordinal: "",
      description: "",
      photoUrl: "",
      email: "",
      linkedinUrl: "",
    },
    validate: yupResolver(schema),
  });

  const uploadImage = async (image) => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append("file", image[0], image[0].name);

    const response = await dispatch(createFileThunk(formData)).unwrap();

    form.setValues({ photoUrl: response["x-direct-location"] });
    setIsLoading(false);
  };

  const submitForm = async (data) => {
    if (updateData) {
      await dispatch(updateTeamMemberThunk(data))
        .unwrap()
        .then(() => {
          showNotification({
            message: t("deliveryMethodModal.updateSuccessfull"),
            color: "green",
          });
        })
        .catch(() => {
          showNotification({
            message: t("deliveryMethodModal.updateFailed"),
            color: "red",
          });
        });
    } else {
      const obj = {
        ...data,
        localizations: {},
      };
      await dispatch(createTeamMemberThunk(obj))
        .unwrap()
        .then(() => {
          showNotification({
            message: t("deliveryMethodModal.updateSuccessfull"),
            color: "green",
          });
        })
        .catch(() => {
          showNotification({
            message: t("deliveryMethodModal.updateFailed"),
            color: "red",
          });
        });
    }

    await dispatch(fetchTeamMembersThunk());
    form.reset();

    onClose();
  };

  useEffect(() => {
    if (updateData) {
      form.setValues({
        id: teamMember?.id || "",
        givenName: teamMember?.givenName || "",
        familyName: teamMember?.familyName || "",
        title: teamMember?.title || "",
        ordinal: teamMember?.ordinal || "",
        description: teamMember?.description || "",
        photoUrl: teamMember?.photoUrl || "",
        localizations: teamMember?.localizations || "",
        email: teamMember?.email || "",
        linkedinUrl: teamMember?.linkedinUrl || "",
      });
    }
  }, [teamMember]);

  return (
    <>
      {
        <Modal
          closeOnClickOutside={false}
          size={"55rem"}
          centered
          opened={opened}
          onClose={() => {
            onClose();
            form.clearErrors();
            form.reset();
          }}
          title={t("goldmanGraffTeam.team")}
          sx={() => ({
            ".mantine-Modal-title": {
              fontWeight: "bold",
            },
          })}
        >
          <form
            onSubmit={form.onSubmit(submitForm)}
            className={classes.formWrapper}
          >
            {statusById === "pending" && (
              <div>
                <LoadingOverlay loader={CustomLoader()} visible />
              </div>
            )}
            <SimpleGrid cols={2}>
              <TextInput
                label={t("goldmanGraffTeam.givenName")}
                placeholder={t("goldmanGraffTeam.givenName")}
                classNames={classes}
                {...form.getInputProps("givenName")}
              />

              <TextInput
                label={t("goldmanGraffTeam.familyName")}
                placeholder={t("goldmanGraffTeam.familyName")}
                classNames={classes}
                {...form.getInputProps("familyName")}
              />
              <NumberInput
                min={1}
                label={t("goldmanGraffTeam.ordinalNumber")}
                placeholder={t("goldmanGraffTeam.ordinalNumber")}
                classNames={classes}
                {...form.getInputProps("ordinal")}
              />
              <TextInput
                label={t("goldmanGraffTeam.title")}
                placeholder={t("goldmanGraffTeam.title")}
                classNames={classes}
                {...form.getInputProps("title")}
              />
              <TextInput
                label={t("goldmanGraffTeam.email")}
                placeholder={t("goldmanGraffTeam.email")}
                classNames={classes}
                {...form.getInputProps("email")}
              />
              <TextInput
                label={t("goldmanGraffTeam.linkedIn")}
                placeholder={t("goldmanGraffTeam.linkedIn")}
                classNames={classes}
                {...form.getInputProps("linkedinUrl")}
              />
            </SimpleGrid>
            <Textarea
              label={t("goldmanGraffTeam.description")}
              placeholder={t("goldmanGraffTeam.description")}
              minRows={10}
              classNames={classes}
              {...form.getInputProps("description")}
            />

            <Dropzone
              styles={{
                root: {
                  marginTop: 10,
                  height: 250,
                  overflowY: "auto",
                  display: "flex",
                  alignItems: "center",
                },
                inner: { pointerEvents: "all" },
              }}
              loading={isLoading}
              maxSize={1000000}
              onDrop={uploadImage}
              onReject={() =>
                showNotification({
                  message: t("mediaAssets.imgSizeError"),
                  color: "red",
                })
              }
              accept={IMAGE_MIME_TYPE}
            >
              <>
                {!form.values.photoUrl ? (
                  <Group position="center" spacing="xl">
                    <Upload size={50} />

                    <div>
                      <Text size="xl" inline>
                        {t("modalCommon.dropzone_msg_1")}
                      </Text>
                      <Text size="sm" color="dimmed" inline mt={7}>
                        {t("modalCommon.dropzone_msg_2")}
                      </Text>
                    </div>
                  </Group>
                ) : (
                  <Box>
                    {
                      <Image
                        style={{ position: "relative" }}
                        src={form.values.photoUrl}
                        height={200}
                        width={300}
                      />
                    }{" "}
                    {!updateData && (
                      <Button
                        className={classes.tableIconsButton}
                        onClick={(e) => {
                          e.stopPropagation();

                          form.setValues({ photoUrl: "" });
                        }}
                      >
                        <TrashX className={classes.tableIconsTrash} />
                      </Button>
                    )}
                  </Box>
                )}
              </>
            </Dropzone>
            {form.errors.photoUrl && (
              <small className={classes.errorText}>
                {form.errors.photoUrl}
              </small>
            )}

            <div className={classes.buttonContainer}>
              <Button className={classes.insertButton} type="submit">
                {t("goldmanGraffTeam.save")}
              </Button>
            </div>
          </form>
        </Modal>
      }
    </>
  );
};

export default GoldmanGraffTeamModal;
