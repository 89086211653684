import { useState, useCallback, useEffect } from "react";
import { createStyles, TextInput } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  input: {
    height: "auto",
    paddingTop: 18,
  },

  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: theme.spacing.sm / 2,
    zIndex: 1,
  },
}));

const AltField = ({ onAltChange, imageAlts, locale, index, id }) => {
  const { classes } = useStyles();

  const [imageAlt, setImageAlt] = useState(imageAlts.find(x => x.locale === locale)?.alt);

  const _onAltChange = useCallback((alt) => {
    if(imageAlt) {
      onAltChange(id, imageAlt)
    }
  }, [imageAlt, onAltChange, id])

  useEffect(()=>{
    _onAltChange();
  }, [imageAlt]) 

  return (
    <>
      <TextInput
        classNames={classes}
        label={"Alt " + index}
        placeholder={"Alt"}
        value={imageAlt}
        onChange={(event) => setImageAlt(event.target.value)}
      />
    </>
  );
};

export default AltField;
