export const toReadableString = (str) => {
    if (!str) return str;
    return str
      .replace(/_/g, " ")
      .toLowerCase()
      .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
  };
  
  export const formatCurrency = (amount, currency) => {
    // Convert the amount to a string
    let [whole, decimal] = amount.toFixed(2).split(".");
  
    // Add a dot as the thousands separator
    whole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  
    // Replace the decimal point with a comma
    const formattedAmount = `${whole},${decimal}`;
  
    // Append the currency symbol at the end with one space
    return `${formattedAmount} ${currency}`;
  };
  