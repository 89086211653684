import React, { useState, useEffect } from "react";
import {
  createStyles,
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
  TextInput,
  Pagination,
  Select,
  LoadingOverlay,
  Button,
} from "@mantine/core";
import { Search } from "tabler-icons-react";
import { createSelector } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchMobileUserAccountAdministrationPagedDataThunk } from "../../store/slices/mobileUserAccountAdministration";
import TableCell from "../common/TableCell";
import CustomLoader from "../common/CustomLoader";

const useStyles = createStyles((theme) => ({
  th: {
    padding: "0 !important",
  },
  control: {
    width: "100%",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    cursor: "default",
  },
  deleteIcon: {
    color:
      theme.colorScheme === "dark" ? theme.colors.red[5] : theme.colors.red[5],
    marginLeft: theme.spacing.xl,
  },
  worldIcon: {
    marginLeft: theme.spacing.xl,
  },
  icon: {
    width: 21,
    height: 21,
    borderRadius: 21,
  },
  scrollArea: {
    height: "85vh",
  },
  searchAndButtonContainter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
  },
  insertButton: {
    alignSelf: "flex-end",
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
  },
  buttonColumn: {
    width: "300px !important",
  },
  buttonContainer: {
    display: "flex",
    width: "280px",
    paddingRight: "16px",
    flexWrap: "wrap",
    "@media (max-width: 1150px)": {
      flexDirection: "column",
    },
  },
  tableIconsButton: {
    padding: "0px",
    "&:hover": {
      backgroundColor: theme.colors.colorGray,
    },
  },
  tableIconsLogo: {
    color: theme.colors.colorDarkGray,
    "&:hover": {
      color: theme.colors.colorBlack,
    },
  },
  tableIconsTrash: {
    color: theme.colors.colorRed,
    "&:hover": {
      color: theme.colors.colorRed,
    },
  },
  searchInput: {
    width: "25vw",
    marginBottom: "0px !important",
  },
  searchInputMargin: {
    marginBottom: "0px",
  },
  thead: {
    borderBottom: `1px solid #dee2e6`,
  },
  statsParent: {
    display: "flex",
    justifyContent: "center",
    columnGap: 15,
    backgroundImage: "linear-gradient(-60deg, #52545b 0, #25262b 100%)",
    padding: "28px",
    borderRadius: "16px",
    marginBottom: 20,
  },
  stats: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",

    flexDirection: "column",
    "&:not(:first-of-type)": {
      borderLeft: "2px solid #88898b",
      paddingLeft: 15,
    },
  },

  count: {
    fontWeight: 700,
    color: "#FFFFFF",
    fontSize: 32,
  },
  title: {
    fontSize: 20,
    textTransform: "uppercase",
    fontWeight: 700,
    color: "#FFFFFF",
  },
}));

const UserAdminMobileAccountsTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { classes } = useStyles();

  const [search, setSearch] = useState("");

  const status = useSelector(
    (state) => state.mobileAccountAdministration?.status
  );

  const tableData = useSelector(
    (state) => state.mobileAccountAdministration?.tableData
  );

  const handleSearchChange = (event) => {
    const { value } = event.currentTarget;
    setSearch(value);
    if (value.length >= 3) {
      setPaginationProps({ pageSize: "100", activePage: 0 });
    }
    if (value.length === 0) {
      setPaginationProps({ pageSize: "100", activePage: 0 });
    }
  };

  const [paginationProps, setPaginationProps] = useState({
    activePage: 0,
    pageSize: "100",
  });

  const newArr =
    tableData.length > paginationProps.pageSize
      ? [...tableData.slice(0, paginationProps.pageSize)]
      : tableData;

  const Th = ({ children, onSort }) => {
    const { classes } = useStyles();

    return (
      <th className={classes.th}>
        <UnstyledButton onClick={onSort} className={classes.control}>
          <Group position="left">
            <Text weight={500} size="sm">
              {children}
            </Text>
          </Group>
        </UnstyledButton>
      </th>
    );
  };

  const rows = newArr?.map((row, i) => (
    <tr key={i}>
      {TableCell(row.firstName)}
      {TableCell(row.lastName)}
      {TableCell(row.email)}
    </tr>
  ));

  useEffect(() => {
    dispatch(
      fetchMobileUserAccountAdministrationPagedDataThunk({
        first: paginationProps.activePage,
        max: Number(paginationProps.pageSize) + 1,
        search,
      })
    );
  }, [paginationProps]);

  return (
    <>
      <ScrollArea className={classes.scrollArea}>
        <div className={classes.searchAndButtonContainter}>
          <TextInput
            className={classes.searchInput}
            placeholder={t("table.search")}
            mb="md"
            icon={<Search size={14} />}
            value={search}
            onChange={handleSearchChange}
          />
        </div>
        <Table
          horizontalSpacing="md"
          verticalSpacing="xs"
          sx={{ tableLayout: "fixed", minWidth: 700 }}
        >
          <thead className={classes.thead}>
            <tr>
              <Th className={classes.buttonColumn}>{t("user.firstName")}</Th>
              <Th className={classes.buttonColumn}>{t("user.lastName")}</Th>
              <Th className={classes.buttonColumn}>{t("user.email")}</Th>
            </tr>
          </thead>
          <tbody>
            {status === "pending" && (
              <tr>
                <td style={{ borderBottom: "none" }}>
                  <LoadingOverlay loader={CustomLoader()} visible />
                </td>
              </tr>
            )}
            {rows.length > 0 ? (
              rows
            ) : (
              <tr>
                <td colSpan={3}>
                  <Text weight={500} align="center">
                    {t("table.nothingFound")}
                  </Text>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </ScrollArea>
      <Group style={{ bottom: 5, position: "absolute", right: 5 }}>
        <Button
          compact
          disabled={paginationProps.activePage === 0}
          sx={{
            height: 32,
            minWidth: 32,
            background: "white",
            border: "1px solid #ced4da",
            color: "black",
            "&:hover": {
              backgroundColor: "transparent",
            },
            "&[data-disabled]": { background: "#e9ecef" },
          }}
          onClick={() =>
            setPaginationProps((prev) => ({
              ...prev,
              activePage: (prev.activePage -= Number(prev.pageSize)),
            }))
          }
        >
          {"<"}
        </Button>
        <Button
          compact
          sx={{
            height: 32,
            minWidth: 32,
            background: "white",
            border: "1px solid #ced4da",
            color: "black",
            "&:hover": {
              backgroundColor: "transparent",
            },
            "&[data-disabled]": { background: "#e9ecef" },
          }}
          disabled={tableData.length === newArr.length}
          onClick={() =>
            setPaginationProps((prev) => ({
              ...prev,
              activePage: (prev.activePage += Number(prev.pageSize)),
            }))
          }
        >
          {">"}
        </Button>

        <Select
          styles={(theme) => ({
            root: { width: 75 },
            item: {
              "&[data-selected]": {
                "&, &:hover": {
                  backgroundColor: "#e7f5ff",
                  color: theme.colors.colorDarkGray,
                },
              },
            },
          })}
          data={["100", "200", "300"]}
          value={paginationProps.pageSize}
          onChange={(value) =>
            setPaginationProps((prev) => ({
              ...prev,
              activePage: 0,
              pageSize: value,
            }))
          }
        />
      </Group>
    </>
  );
};

export default UserAdminMobileAccountsTable;
