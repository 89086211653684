import header from "./header.png";
import addPicture from "./addPicture.png";
import homepageText from "./homepageText.png";
import homepageLogo from "./homepageLogo.png";
import welcomeNote from "./welcomeNote.svg";
import welcomeNote2 from "./welcomeNote2.svg";
import homepageLogo2 from "./homepageLogo2.svg";
import goldmanGraffEditor from "./goldmanGraffEditor.svg";
import chat from "./chat.svg";
import accounts from "./accounts.svg";
import iosSend from "./iosSend.svg";
import user from "./user.svg";
import agent from "./agent.svg";
import archive from "./archive.svg";
import archiveActive from "./archiveActive.svg";
import backArrow from "./backArrow.svg";

export default {
  header: header,
  addPicture: addPicture,
  homepageLogo: homepageLogo,
  homepageText: homepageText,
  welcomeNote: welcomeNote,
  welcomeNote2: welcomeNote2,
  homepageLogo2: homepageLogo2,
  goldmanGraffEditor:goldmanGraffEditor,
  chat:chat,
  accounts:accounts,
  iosSend:iosSend,
  user:user,
  agent:agent,
  archive:archive,
  backArrow:backArrow,
  archiveActive:archiveActive
};
