import { Button, createStyles, Modal, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import * as Yup from "yup";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { showNotification } from "@mantine/notifications";
import {
  fetchParametersAdministrationThunk,
  updateParametersAdministrationThunk,
} from "../../store/slices/parametersAdministration";

const useStyles = createStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingTop: `${theme.spacing.xs}px `,
  },
  root: {
    position: "relative",
    marginTop: 20,
  },

  input: {
    height: "auto",
    paddingTop: 18,
  },

  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: theme.spacing.sm / 2,
    zIndex: 1,
  },

  insertButton: {
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
  },
}));

const ParameterAdminModal = (props) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const { opened, onClose, isUpdate, updateData } = props;

  const schema = Yup.object().shape({
    value: Yup.string()
      .required(t("parameterAdminModal.valueValidation"))
      .min(1, t("parameterAdminModal.valueMinValidation"))
      .max(256, t("parameterAdminModa.valueLengthValidation"))
      .trim(t("parameterAdminModal.valueWhitespaceValidation"))
      .strict(true),
  });

  const form = useForm({
    initialValues: {
      key: "",
      value: "",
    },
    validate: yupResolver(schema),
  });

  const submitForm = async (data) => {
    await dispatch(updateParametersAdministrationThunk(data))
      .unwrap()
      .then(() => {
        form.reset();
        onClose();
        showNotification({
          message: t("parameterAdminModal.updateSuccessfull"),
          color: "green",
        });
      })
      .catch((e) => {
        showNotification({
          message: t("parameterAdminModal.updateFailed"),
          color: "red",
        });
      });
    await dispatch(fetchParametersAdministrationThunk());
  };
  const toHumanReadableFormat = (key) => {
    switch (key) {
      case "PRODUCT_IN_STOCK_STATISTIC_GLOBAL_RANGE_MAX":
        return "PRODUCT IN STOCK STATISTIC GLOBAL RANGE MAX";
      case "PRODUCT_VISITOR_STATISTIC_GLOBAL_RANGE_MAX":
        return "PRODUCT VISITOR STATISTIC GLOBAL RANGE MAX";
      case "BLOG_POST_SHORT_CONTENT_MAX_LENGTH":
        return "BLOG POST SHORT CONTENT MAX LENGTH";
      case "INSTALLMENTS_PAYMENT_PRICE_PERCENTAGE":
        return "INSTALLMENTS PAYMENT PRICE PERCENTAGE";
      case "BLOG_SEO_TITLE_MAX_LENGTH":
        return "BLOG SEO TITLE MAX LENGTH";
      case "KYC_MANDATORY_FOR_AMOUNT_GREATER_THEN":
        return "KYC MANDATORY FOR AMOUNT GREATER THEN";
      case "GOLD_SAVING_COMPLETION_DECISION_THRESHOLD_IN_DAYS":
        return "GOLD SAVING COMPLETION DECISION THRESHOLD IN DAYS";
      case "PRODUCT_VISITOR_STATISTIC_GLOBAL_RANGE_MIN":
        return "PRODUCT VISITOR STATISTIC GLOBAL RANGE MIN";
      case "PRODUCT_BESTSELLER_FOR_PERIOD_OF":
        return "PRODUCT BESTSELLER FOR PERIOD OF";
      case "CONTACT_QUERY_EMAIL":
        return "CONTACT QUERY EMAIL";
      case "PRODUCT_IN_STOCK_STATISTIC_GLOBAL_RANGE_MIN":
        return "PRODUCT IN STOCK STATISTIC GLOBAL RANGE MIN";
      case "GOLD_SAVING_EXPIRY_THRESHOLD_IN_DAYS":
        return "GOLD SAVING EXPIRY THRESHOLD IN DAYS";
      case "SHOP_ADMIN_EMAIL":
        return "SHOP ADMIN EMAIL";
      case "PRODUCT_LATEST_FOR_PERIOD":
        return "PRODUCT LATEST FOR PERIOD";
      case "GOLD_SAVING_PRODUCT_SKU_ID":
        return "GOLD SAVING PRODUCT SKU ID";
      case "PRODUCT_SOLD_STATISTIC_GLOBAL_RANGE_MAX":
        return "PRODUCT SOLD STATISTIC GLOBAL RANGE MAX";
      case "PRODUCT_SOLD_STATISTIC_GLOBAL_RANGE_MIN":
        return "PRODUCT SOLD STATISTIC GLOBAL RANGE MIN";
      case "KYC_VALID_PERIOD_IN_DAYS":
        return "KYC VALID PERIOD IN DAYS";
      case "PRODUCT_REVIEW_ENABLED":
        return "PRODUCT REVIEW ENABLED";
      case "BLOG_SEO_META_DESCRIPTION_MAX_LENGTH":
        return "BLOG SEO META DESCRIPTION MAX LENGTH";
      case "BUYBACK_REQUEST_CANCEL_THRESHOLD_IN_HOURS":
        return "BUYBACK REQUEST CANCEL THRESHOLD IN HOURS";
      case "BLOG_SEO_META_KEYWORDS_MAX_LENGTH":
        return "BLOG SEO META KEYWORDS MAX LENGTH";
      case "BLOG_SEO_META_ROBOTS_MAX_LENGTH":
        return "BLOG SEO META ROBOTS MAX LENGTH";
      case "BLOG_SLUGS_MAX_LENGTH":
        return "BLOG SLUGS MAX LENGTH";
      case "GOLD_SAVING_MINIMUM_SAVING_PERIOD_IN_MONTHS":
        return "GOLD SAVING MINIMUM SAVING PERIOD IN MONTHS";
      default:
        return key;
    }
  };

  useEffect(() => {
    form.clearErrors();
    form.reset();
    if (isUpdate) {
      form.setValues({
        key: updateData.key,
        value: updateData.value,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData]);

  return (
    <Modal
      closeOnClickOutside={false}
      size={"xl"}
      centered
      opened={opened}
      onClose={() => {
        onClose();
      }}
      title={t("parameterAdminModal.modalTitle")}
      sx={(theme) => ({
        ".mantine-Modal-title": {
          fontWeight: "bold",
        },
      })}
    >
      <form onSubmit={form.onSubmit(submitForm)}>
        <TextInput
          readOnly
          label={t("parameterAdminModal.nameInputTitle")}
          placeholder={t("parameterAdminModal.nameInputPlaceholder")}
          classNames={classes}
          defaultValue={toHumanReadableFormat(form?.values?.key)}
        />

        <TextInput
          label={t("parameterAdminModal.valueInputTitle")}
          placeholder={t("parameterAdminModal.valueInputPlaceholder")}
          classNames={classes}
          {...form.getInputProps("value")}
        />

        <div className={classes.buttonContainer}>
          <Button type="submit" className={classes.insertButton}>
            {t("modalCommon.saveButton")}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ParameterAdminModal;
