import { Button, Modal, TextInput, Textarea, createStyles } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import * as Yup from "yup";
import { showNotification } from "@mantine/notifications";
import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getPageSeoByIdThunk, updatePageSeoThunk } from "../../store/slices/WebSeo";

const useStyles = createStyles((theme) => ({
  saveButtonDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingTop: `${theme.spacing.xs}px `,
  },
  saveButton: {
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
  },
  root: {
    position: "relative",
    marginTop: "1.25rem",
  },
  input: {
    height: "auto",
    lineHeight: 1.55,
    paddingTop: "1.5rem",
    paddingBottom: "0.75rem"
  },
  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: theme.spacing.sm / 2,
    zIndex: 1,
  },
}));

const WebSeoModal = (props) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { opened, isEdit, onClose, seoId } = props;
  const [seoData, setSeoData] = useState({});
  
  const schema = Yup.object().shape({
    title: Yup.string()
      .strict(true)
      .required(t("webSeo.form.validation.title.required"))
      .trim(t("webSeo.form.validation.title.whitespace"))
      .max(255, t("webSeo.form.validation.title.maxLength")),
    metaDescription: Yup.string()
      .strict(true)
      .required(t("webSeo.form.validation.metaDescription.required"))
      .trim(t("webSeo.form.validation.metaDescription.whitespace"))
      .max(2000, t("webSeo.form.validation.metaDescription.maxLength")),
    metaKeywords: Yup.string()
      .strict(true)
      .required(t("webSeo.form.validation.metaKeywords.required"))
      .trim(t("webSeo.form.validation.metaKeywords.whitespace"))
      .max(2000, t("webSeo.form.validation.metaKeywords.maxLength")),
    metaRobots: Yup.string()
      .strict(true)
      .required(t("webSeo.form.validation.metaRobots.required"))
      .trim(t("webSeo.form.validation.metaRobots.whitespace"))
      .max(255, t("webSeo.form.validation.metaRobots.maxLength")),
  })

  const form = useForm({
    initialValues: {
      title: "",
      metaDescription: "",
      metaKeywords: "",
      metaRobots: "",
    },
    validate: yupResolver(schema),
  });

  const setFormData = useCallback((_seoData) => {
    form.setValues({
      title: _seoData.title,
      metaDescription: _seoData.metaDescription,
      metaKeywords: _seoData.metaKeywords,
      metaRobots: _seoData.metaRobots,
    })
  }, [])

  const fetchPageSeo = useCallback(() => {
    if(seoId && opened)
      return dispatch(getPageSeoByIdThunk(seoId))
        .unwrap()
        .then((seoData) => {
          setSeoData(seoData);
          if(isEdit)
            setFormData(seoData);
        })
  }, [opened, seoId, isEdit, dispatch, setFormData])

  useEffect(() => {
    fetchPageSeo();
  }, [fetchPageSeo]);

  const submitForm = async (formData) => {
    let submitData = {
      id: seoData.id,
      code: seoData.code,
      title: formData.title,
      metaDescription: formData.metaDescription,
      metaKeywords: formData.metaKeywords,
      metaRobots:formData.metaRobots,
      localizations: seoData.localizations
    }

    await dispatch(updatePageSeoThunk(submitData))
      .unwrap()
      .then(() => {
        form.reset();
        onClose();
        showNotification({
          message: t("webSeo.success"),
          color: "green",
        });
      })
      .catch((e) => {
        showNotification({
          message: t("webSeo.error"),
          color: "red",
        });
      });
  };

  return (
    <Modal
      closeOnClickOutside={false}
      size={"xl"}
      centered
      opened={opened}
      onClose={() => {
        onClose();
      }}
      title={seoData.code}
      sx={() => ({
        ".mantine-Modal-title": {
          fontWeight: "bold",
        },
      })}
    >
        <form
          onSubmit={form.onSubmit(submitForm)}
          className={classes.formWrapper}
        >
          <TextInput
            classNames={classes}
            label={t("webSeo.form.title")}
            placeholder={t("webSeo.form.title")}
            {...form.getInputProps("title")}
          />
          <Textarea
            classNames={classes}
            label={t("webSeo.form.metaDescription")}
            placeholder={t("webSeo.form.metaDescription")}
            minRows={2}
            autosize
            {...form.getInputProps("metaDescription")}
          />
          <Textarea
            classNames={classes}
            label={t("webSeo.form.metaKeywords")}
            placeholder={t("webSeo.form.metaKeywords")}
            minRows={2}
            autosize
            {...form.getInputProps("metaKeywords")}
          />
          <TextInput
            classNames={classes}
            label={t("webSeo.form.metaRobots")}
            placeholder={t("webSeo.form.metaRobots")}
            {...form.getInputProps("metaRobots")}
          />

          <div className={classes.saveButtonDiv}>
            <Button className={classes.saveButton} type="submit">
              {t("webComposition.save")}
            </Button>
          </div>
        </form>
    </Modal>
  );
};

export default WebSeoModal;
