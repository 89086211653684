import { useForm, yupResolver } from "@mantine/form";
import { createStyles, Modal, Button, Select, Box } from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { showNotification } from "@mantine/notifications";
import { languageLocaleData } from "../common/parseLanguageNames";
import { useParams } from "react-router-dom";
import {
  fetchLegalDocumentsByTypeIdThunk,
  updateLegalDocumentsByTypeThunk,
} from "../../store/slices/legalDocuments";
import { Editor } from "@tinymce/tinymce-react";
import config from "../../config";
import { createFileThunk } from "../../store/slices/file";

const useStyles = createStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingTop: `${theme.spacing.xs}px `,
  },
  root: {
    position: "relative",
  },

  input: {
    height: "auto",
    paddingTop: 18,
    marginTop: 20,
    zIndex: 2,
  },
  errorText: {
    color: "#f03e3e",
    fontSize: "12px",
  },
  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: theme.spacing.sm / 2,
    zIndex: 1,
  },
  insertButton: {
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
  },
}));

const GeneralTermsLocalizationModal = (props) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const params = useParams();

  const tableLocalizationData = useSelector(
    (state) => state?.legalDocumentsByType?.legalDocumentsByTypeId
  );
  const languageData = useSelector((state) => state.language.tableData);

  const { opened, onClose, updateData, isUpdate, modalData } = props;

  const schema = Yup.object().shape({
    locale: Yup.string().min(
      2,
      t("generalTermsLocalizationModal.codeValidation")
    ),
    content: Yup.string()
      .trim(t("generalTermsLocalizationModal.whitespaceValidation"))
      .strict(true)
      .required(t("generalTermsLocalizationModal.required"))
      .min(1, t("generalTermsLocalizationModal.contentValidation"))
      .test(
        "emptyContent",
        t("generalTermsLocalizationModal.contentValidation"),
        function (value) {
          const parsedValue = value.replace(/(<([^>]+)>|&nbsp;)/gi, "");

          return parsedValue.trim() === "" ? false : true;
        }
      ),
  });

  const form = useForm({
    initialValues: {
      locale: "",
      content: "",
      name: tableLocalizationData?.descriptions.find(
        (item) => item?.locale === "en"
      )?.name,
    },
    validate: yupResolver(schema),
  });

  const handleImageUploadEditor = async (blobInfo, success, failure) => {
    const formData = new FormData();
    formData.append("file", blobInfo.blob());

    try {
      const response = await dispatch(createFileThunk(formData)).unwrap();
      success("Uploaded image!");
      return response["x-direct-location"];
    } catch (error) {
      failure("Error uploading image!");
    }
  };

  const prepareUpdateObject = (data) => {
    const object = {
      validFrom: tableLocalizationData.validFrom,
      validTo: tableLocalizationData.validTo,
      contents: data,
    };

    return object;
  };

  const submitForm = async (data) => {
    let updateObject = [];

    if (isUpdate) {
      let updatedData = modalData.descriptions.filter(
        (item) => item.locale !== data.locale
      );

      updatedData = [...updatedData, data];

      updateObject = prepareUpdateObject(updatedData);

      await dispatch(
        updateLegalDocumentsByTypeThunk({
          id: modalData.id,

          data: updateObject,
        })
      )
        .unwrap()
        .then(() => {
          form.reset();
          onClose();
          showNotification({
            message: t("generalTermsLocalizationModal.updateSuccessfull"),
            color: "green",
          });
        })
        .catch((e) => {
          showNotification({
            message: t("generalTermsLocalizationModal.updateFailed"),
            color: "red",
          });
        });
    } else {
      let createdData = tableLocalizationData.descriptions.filter(
        (item) => item.locale !== data.locale
      );
      createdData = [...createdData, data];

      updateObject = prepareUpdateObject(createdData);

      await dispatch(
        updateLegalDocumentsByTypeThunk({
          id: tableLocalizationData.id,

          data: updateObject,
        })
      )
        .unwrap()
        .then(() => {
          form.reset();
          onClose();
          showNotification({
            message: t("generalTermsLocalizationModal.insertSuccessfull"),
            color: "green",
          });
        })
        .catch((e) => {
          showNotification({
            message: t("generalTermsLocalizationModal.insertFailed"),
            color: "red",
          });
        });
    }
    dispatch(fetchLegalDocumentsByTypeIdThunk(params.generalTermsId));
  };

  useEffect(() => {
    form.clearErrors();
    if (isUpdate) {
      form.setValues({
        content: updateData.content,
        locale: updateData.locale,
        name: updateData.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData]);

  return (
    <>
      <Modal
        closeOnClickOutside={false}
        centered
        size="xl"
        opened={opened}
        onClose={() => {
          form.reset();
          onClose();
        }}
        title={t("generalTermsLocalizationModal.modalTitle")}
        sx={(theme) => ({
          ".mantine-Modal-title": {
            fontWeight: "bold",
          },
        })}
      >
        <form onSubmit={form.onSubmit(submitForm)}>
          <Select
            style={{ marginBottom: 20, zIndex: 100 }}
            styles={(theme) => ({
              item: {
                // applies styles to selected item
                "&[data-selected]": {
                  "&, &:hover": {
                    backgroundColor: "#e7f5ff",
                    color: theme.colors.colorDarkGray,
                  },
                },
              },
            })}
            disabled={isUpdate}
            data={
              isUpdate
                ? languageData.map((i) => ({
                    value: i?.code,
                    label:
                      i?.code +
                      " - " +
                      i?.names
                        .filter((n) => n?.locale === "en")
                        .map(({ name }) => name),
                  }))
                : languageLocaleData(
                    languageData,
                    tableLocalizationData?.descriptions
                  )
            }
            label={t("generalTermsLocalizationModal.codeInputTitle")}
            placeholder={t(
              "generalTermsLocalizationModal.codeInputPlaceholder"
            )}
            classNames={classes}
            searchable
            {...form.getInputProps("locale")}
          />
          <Box>
            <Editor
              value={form.values.content}
              onEditorChange={(content) => {
                form.setFieldValue("content", content);
              }}
              apiKey={config.REACT_APP_TINY_MCE_EDITOR_API_KEY}
              init={{
                height: 300,
                menubar: false,
                plugins: "advlist lists image link preview",
                toolbar:
                  "bold italic| image link preview |casechange blocks | numlist bullist",
                branding: false,
                images_upload_handler: handleImageUploadEditor,
              }}
            />

            {form.errors.content !== null ? (
              <p className={classes.errorText}>{form.errors.content}</p>
            ) : (
              ""
            )}
          </Box>

          <div className={classes.buttonContainer}>
            <Button type="submit" className={classes.insertButton}>
              {t("modalCommon.saveButton")}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default GeneralTermsLocalizationModal;
