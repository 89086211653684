import { createStyles } from "@mantine/core";
import UserStatisticLayout from "./UserStatisticLayout"

const useStyles = createStyles((theme) => ({
  mainContainer: {
    height: "100%",
    width: "100%",
  },
}));

const UserStatistic = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.mainContainer}>
      <div>
        <UserStatisticLayout />
      </div>
    </div>
  );
};

export default UserStatistic;
