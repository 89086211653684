import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { officeApi } from "../../http/services";

export const fetchApplicableCountriesThunk = createAsyncThunk(
  "offices/getApplicableCountriesData",
  async () => {
    return await officeApi.getApplicableCountriesData();
  }
);

export const fetchOfficesThunk = createAsyncThunk(
  "offices/getOfficesData",
  async (args) => {
    return await officeApi.getOfficesData(args);
  }
);
export const fetchOfficesByIdThunk = createAsyncThunk(
  "offices/getOfficesDataById",
  async (args) => {
    return await officeApi.getOfficesDataById(args);
  }
);

export const editOfficeDataThunk = createAsyncThunk(
  "offices/editOfficeData",
  async (args, { dispatch }) => {
    await officeApi.editOfficeData(args.id, args.data);
    await dispatch(fetchOfficesByIdThunk(args.id));
  }
);

const initialState = {
  tableData: [],
  applicableCountriesData: [],
  officesDataById: {},
  officeByIdLoading: true,
};

const officesSlice = createSlice({
  name: "offices",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchApplicableCountriesThunk.fulfilled,
      (state, action) => {
        return { ...state, applicableCountriesData: action.payload };
      }
    );
    builder.addCase(fetchApplicableCountriesThunk.rejected, (state, action) => {
      return { ...state, applicableCountriesData: [] };
    });
    builder.addCase(fetchOfficesThunk.fulfilled, (state, action) => {
      return { ...state, tableData: action.payload };
    });
    builder.addCase(fetchOfficesByIdThunk.pending, (state) => {
      return { ...state, officeByIdLoading: true };
    });
    builder.addCase(fetchOfficesByIdThunk.fulfilled, (state, action) => {
      return {
        ...state,
        officesDataById: action.payload,
        officeByIdLoading: false,
      };
    });
    builder.addCase(fetchOfficesByIdThunk.rejected, (state) => {
      return { ...state, officesDataById: {}, officeByIdLoading: false };
    });
    builder.addCase(editOfficeDataThunk.pending, (state) => {
      return { ...state, officeByIdLoading: true };
    });
    builder.addCase(editOfficeDataThunk.fulfilled, (state) => {
      return {
        ...state,
        officeByIdLoading: false,
      };
    });
    builder.addCase(editOfficeDataThunk.rejected, (state) => {
      return { ...state, officeByIdLoading: false };
    });
  },
});
const { reducer } = officesSlice;
export { reducer as officesReducer };
