import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userAccountAdministrationApi } from "../../http/services";

export const fetchUserAccountAdministrationPagedDataThunk = createAsyncThunk(
  "userAccountAdministration/getDataPaged",
  async (args) => {
    return await userAccountAdministrationApi.getUserAccountAdministrationPaged(
      args
    );
  }
);

export const createUserAccountAdministrationDataThunk = createAsyncThunk(
  "createAccountAdministration/createUser",
  async (args,thunkAPI) => {
    try {
      return await userAccountAdministrationApi.createUserAccountAdministration(
        args
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.status);
    }
   
  }
);

export const getUserAccountEmailCheckThunk = createAsyncThunk(
  "getUserAccountEmailCheck",
  async (args,thunkAPI) => {
    try {
      return await userAccountAdministrationApi.getUserAccountEmailCheck(
        args
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.status);
    }
   
  }
);

export const updateUserAccountAdministrationDataThunk = createAsyncThunk(
  "updateAccountAdministration/updateUser",
  async (args) => {
    return await userAccountAdministrationApi.updateUserAccountAdministration(
      args
    );
  }
);

export const deleteUserAccountAdministrationDataThunk = createAsyncThunk(
  "deleteAccountAdministration/deleteUser",
  async (args) => {
    return await userAccountAdministrationApi.deleteUserAccountAdministration(
      args
    );
  }
);

export const getUserAccountAdministrationDataByIdThunk = createAsyncThunk(
  "getAccountAdministrationById",
  async (args) => {
    return await userAccountAdministrationApi.getUserAccountAdministrationById(
      args
    );
  }
);

export const resendUserAccountMailThunk = createAsyncThunk(
  "resendUserAccountMail/resendMail",
  async (args) => {
    return await userAccountAdministrationApi.resendUserAccountMail(args);
  }
);

const initialState = {
  code:0,
  status: "",
  tableDataById: {},
  tableData: [],
};

const userAccountAdministrationSlice = createSlice({
  name: "userAccountAdministration",
  initialState,
  reducers: {
    resetTableDataById: (state) => {
      state.tableDataById = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchUserAccountAdministrationPagedDataThunk.pending,
      (state) => {
        return { ...state, status: "pending" };
      }
    );
    builder.addCase(
      fetchUserAccountAdministrationPagedDataThunk.fulfilled,
      (state, action) => {
        return {
          ...state,
          status: "fulfilled",
          tableData: action.payload,
        };
      }
    );
    builder.addCase(
      updateUserAccountAdministrationDataThunk.pending,
      (state) => {
        return { ...state, status: "pending" };
      }
    );
    builder.addCase(
      updateUserAccountAdministrationDataThunk.fulfilled,
      (state) => {
        return { ...state, status: "fulfilled" };
      }
    );
    builder.addCase(
      createUserAccountAdministrationDataThunk.pending,
      (state) => {
        return { ...state, status: "pending" };
      }
    );
    builder.addCase(
      createUserAccountAdministrationDataThunk.fulfilled,
      (state) => {
        return { ...state, status: "fulfilled" };
      }
    );

    builder.addCase(
      getUserAccountAdministrationDataByIdThunk.pending,
      (state) => {
        return {
          ...state,
          status: "pending",
        };
      }
    );

    builder.addCase(
      getUserAccountAdministrationDataByIdThunk.fulfilled,
      (state, action) => {
        return {
          ...state,
          status: "fulfilled",
          tableDataById: action.payload,
        };
      }
    );

    builder.addCase(
      getUserAccountAdministrationDataByIdThunk.rejected,
      (state) => {
        return {
          ...state,
          status: "rejected",
        };
      }
    );
    builder.addCase(
      getUserAccountEmailCheckThunk.fulfilled,
      (state,action) => {
        return {
          ...state,
          code: action.payload,
        };
      }
    );
  },
});
const { actions, reducer } = userAccountAdministrationSlice;
export const { resetTableDataById } = actions;
export { reducer as userAccountAdministrationReducer };
