import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { teamMembersApi } from "../../http/services";

const initialState = {
  data: [],
  dataById:{},
  status:"",
  statusById:""
};

export const fetchTeamMembersThunk = createAsyncThunk(
  "teamMembers/getData",
  async () => {
    return await teamMembersApi.getTeamMembers();
  }
);


export const fetchTeamMemberByIdThunk = createAsyncThunk(
 "teamMember/getDataById",
  async (id) => {
    return await teamMembersApi.getTeamMemberById(id);
  }

);

export const deleteTeamMemberThunk = createAsyncThunk(
  "teamMember/deleteData",
   async (id) => {
     return await teamMembersApi.deleteTeamMember(id);
   }
 
 );

export const updateTeamMemberThunk = createAsyncThunk(
  "teamMember/updateDataById",
   async (args) => {
     return await teamMembersApi.updateTeamMember(args);
   }
 
 );


export const createTeamMemberThunk = createAsyncThunk(
  "posts/createPosts",
  async (args) => {
    return teamMembersApi.createTeamMember(args);
  }
);

const teamMembers = createSlice({
  name: "teamMembers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTeamMembersThunk.pending, (state) => {
      return { ...state,status:"pending" };
    });
    builder.addCase(fetchTeamMembersThunk.fulfilled, (state, action) => {
      return { ...state,status:"fulfilled", data: action.payload };
    });
    builder.addCase(fetchTeamMembersThunk.rejected, (state) => {
      return { ...state,status:"rejected", data: [] };
    });

    builder.addCase(fetchTeamMemberByIdThunk.pending, (state) => {
      return { ...state,statusById:"pending" };
    });

    builder.addCase(fetchTeamMemberByIdThunk.fulfilled, (state, action) => {
      return { ...state,statusById:"fulfilled", dataById: action.payload };
    });
    builder.addCase(fetchTeamMemberByIdThunk.rejected, (state) => {
      return { ...state,statusById:"rejected", dataById: {} };
    });
  },
});

const { reducer } = teamMembers;
export { reducer as teamMembersReducer };
