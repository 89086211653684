import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { mobileUserAccountAdministrationApi } from "../../http/services";

export const fetchMobileUserAccountAdministrationPagedDataThunk =
  createAsyncThunk(
    "mobileUserAccountAdministration/getDataPaged",
    async (args) => {
      return await mobileUserAccountAdministrationApi.getMobileUserAccountAdministrationPaged(
        args
      );
    }
  );

const initialState = {
  status: "",
  tableData: [],
  totalCount: 0,
};

const mobileUserAccountAdministrationSlice = createSlice({
  name: "mobileUserAccountAdministration",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchMobileUserAccountAdministrationPagedDataThunk.pending,
      (state) => {
        return { ...state, status: "pending" };
      }
    );
    builder.addCase(
      fetchMobileUserAccountAdministrationPagedDataThunk.fulfilled,
      (state, action) => {
        return {
          ...state,
          status: "fulfilled",
          tableData: action.payload,
        };
      }
    );
  },
});
const { reducer } = mobileUserAccountAdministrationSlice;
export { reducer as mobileUserAccountAdministrationReducer };
