import axios from "../axios";

export const getTeamMembers = async () => {
  const response = await axios.get("/gg2/admin/api/v1/team-members");
  return response.data;
};

export const getTeamMemberById = async (id) => {
  const response = await axios.get(`/gg2/admin/api/v1/team-members/${id}`);
  return response.data;
};


export const createTeamMember = async (args) => {
  const response = await axios.post("/gg2/admin/api/v1/team-members", args);
  return response.data;
};

export const updateTeamMember = async (args) => {
  const response = await axios.put(`/gg2/admin/api/v1/team-members/${args.id}`,args);
  return response.status;
};

export const deleteTeamMember = async (id) => {
  const response = await axios.delete(`/gg2/admin/api/v1/team-members/${id}`);
  return response.data;
};



