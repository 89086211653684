import axios from "../axios";

export const createHighlight = async (args) => {
  const response = await axios.post("/gg2/admin/api/v1/highlight", args);
  return response.data;
};

export const getHighlights = async () => {
  const response = await axios.get("/gg2/admin/api/v1/highlights");
  return response.data;
};

export const getHighlightById = async (id) => {
  const response = await axios.get(`/gg2/admin/api/v1/highlight/${id}`);
  return response.data;
};

export const updateHighlight = async (args) => {
  const response = await axios.put(
    `/gg2/admin/api/v1/highlights/${args.id}`,
    args
  );
  return response.data;
};

export const deleteHighlight = async (id) => {
  const response = await axios.delete(`/gg2/admin/api/v1/highlights/${id}`);
  return response.data;
};
