import { createStyles } from "@mantine/core";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchParametersAdministrationThunk } from "../../store/slices/parametersAdministration";
import WebCompositionTable from "./WebCompositionTable";

function toReadable(str) {
  const index = str.indexOf("_");
  if (index === -1) {
    return str; // return original string if no underscore is found
  }

  const secondIndex = str.indexOf("_", index + 1);
  if (secondIndex === -1) {
    return str; // return original string if no second underscore is found
  }

  const lastIndex = str.lastIndexOf("_");
  if (lastIndex === -1) {
    return str; // return original string if no last underscore is found
  }

  const result = str
    .slice(secondIndex + 1, lastIndex) // cut out everything before the second underscore and after the last underscore
    .replace(/_/g, " ") // replace remaining underscores with spaces
    .toLowerCase(); // convert to lower case

  return result.charAt(0).toUpperCase() + result.slice(1); // capitalize first letter of the string
}

const useStyles = createStyles((theme) => ({
  mainContainer: {
    height: "100%",
    width: "100%",
  },
  manufacturersSelectContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingRight: `${theme.spacing.md}px`,
  },
}));

const WebComposition = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchParametersAdministrationThunk());
  }, []);

  const parameters = useSelector(
    (state) => state.parametersAdministration.tableData
  );

  const mappedCompositionKeys = useMemo(
    () =>
      parameters
        .filter((param) => param.key.startsWith("UI_WEB"))
        .map((param) => {
          return {
            name: toReadable(param.key),
            key: param.key,
            value: param.value,
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name)),
    [parameters]
  );

  const { classes } = useStyles();
  return (
    <div className={classes.mainContainer}>
      <div>
        <WebCompositionTable tableData={mappedCompositionKeys} />
      </div>
    </div>
  );
};

export default WebComposition;
