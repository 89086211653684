import {
  Button,
  Center,
  Group,
  LoadingOverlay,
  ScrollArea,
  Table,
  Text,
  TextInput,
  Tooltip,
  UnstyledButton,
  createStyles,
} from "@mantine/core";
import {
  ChevronDown,
  ChevronUp,
  Pencil,
  Plus,
  Search,
  Selector,
  Trash,
  World,
} from "tabler-icons-react";
import TableCell from "../common/TableCell";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import GoldmanGraffTeamModal from "./GoldmanGraffTeamModal";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTeamMemberThunk,
  fetchTeamMemberByIdThunk,
  fetchTeamMembersThunk,
} from "../../store/slices/teamMembers";
import DeleteBanner from "../common/DeleteBanner";
import { showNotification } from "@mantine/notifications";
import CustomLoader from "../common/CustomLoader";

const useStyles = createStyles((theme) => ({
  th: {
    padding: "0 !important",
  },
  control: {
    width: "100%",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  scrollArea: {
    height: "85vh",
  },
  searchAndButtonContainter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
  },

  searchInput: {
    width: "25vw",
    marginBottom: "0px !important",
  },
  searchInputMargin: {
    marginBottom: "0px",
  },
  thead: {
    borderBottom: `1px solid #dee2e6`,
  },
  tableIconsButton: {
    padding: "0px",
    "&:hover": {
      backgroundColor: theme.colors.colorGray,
    },
  },
  tableIconsLogo: {
    color: theme.colors.colorDarkGray,
    "&:hover": {
      color: theme.colors.colorBlack,
    },
  },
  tableIconsTrash: {
    color: theme.colors.colorRed,
    "&:hover": {
      color: theme.colors.colorRed,
    },
  },
  insertButton: {
    alignSelf: "flex-end",
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
  },
}));

const Th = ({ children, reversed, sorted, onSort }) => {
  const { classes } = useStyles();
  const Icon = sorted ? (reversed ? ChevronUp : ChevronDown) : Selector;
  return (
    <th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group position="apart">
          <Text weight={500} size="sm">
            {children}
          </Text>
          <Center className={classes.icon}>
            <Icon size={14} />
          </Center>
        </Group>
      </UnstyledButton>
    </th>
  );
};

const GoldmanGraffTeamTable = ({ tableData }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const status = useSelector((state) => state?.teamMembers?.status || "");

  const [updateData, setUpdateData] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [deleteBannerVisible, setDeleteBannerVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [search, setSearch] = useState("");

  const handleSearchChange = (event) => {
    const { value } = event.currentTarget;
    setSearch(value);
  };
  const setSorting = (field) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
  };

  const searchTableData = (data) => {
    if (search !== "") {
      const filteredData = data.filter(
        (item) =>
          item.givenName.toLowerCase().includes(search.toLowerCase()) ||
          item.familyName.toLowerCase().includes(search.toLowerCase()) ||
          item.title.toLowerCase().includes(search.toLowerCase()) ||
          item.ordinal.toString().toLowerCase().includes(search.toLowerCase())
      );
      return filteredData;
    } else {
      return data;
    }
  };

  const closeModal = () => {
    setModalVisible(false);
    setUpdateData(false);
  };
  const closeDeleteBanner = () => {
    setDeleteBannerVisible(false);
  };
  const openModal = async (row) => {
    setUpdateData(row ? true : false);
    setModalVisible(true);
  };

  const deleteMember = async () => {
    await dispatch(deleteTeamMemberThunk(deleteData.id))
      .unwrap()
      .then(() => {
        showNotification({
          message: t("deliveryMethodLocalizationTable.deleteSucessfull"),
          color: "green",
        });
      })
      .catch((e) => {
        showNotification({
          message: t("deliveryMethodLocalizationTable.deleteError"),
          color: "red",
        });
      });
    await dispatch(fetchTeamMembersThunk());
    closeDeleteBanner();
  };

  const sortTableData = (tableData) => {
    const data = [...tableData];

    if (sortBy === "familyName") {
      return !reverseSortDirection
        ? data
            ?.slice()
            ?.sort((a, b) =>
              a.familyName
                .toLowerCase()
                .localeCompare(b.familyName.toLowerCase())
            )
        : data
            ?.slice()
            ?.sort((a, b) =>
              a.familyName
                .toLowerCase()
                .localeCompare(b.familyName.toLowerCase())
            )
            ?.reverse();
    }
    if (sortBy === "givenName") {
      return !reverseSortDirection
        ? data
            ?.slice()
            ?.sort((a, b) =>
              a.givenName.toLowerCase().localeCompare(b.givenName.toLowerCase())
            )
        : data
            ?.slice()
            ?.sort((a, b) =>
              a.givenName.toLowerCase().localeCompare(b.givenName.toLowerCase())
            )
            ?.reverse();
    }
    if (sortBy === "title") {
      return !reverseSortDirection
        ? data
            ?.slice()
            ?.sort((a, b) =>
              a.title.toLowerCase().localeCompare(b.title.toLowerCase())
            )
        : data
            ?.slice()
            ?.sort((a, b) =>
              a.title.toLowerCase().localeCompare(b.title.toLowerCase())
            )
            ?.reverse();
    }

    if (sortBy === "ordinalNumber") {
      return !reverseSortDirection
        ? data?.slice()?.sort((a, b) => Number(a.ordinal) - Number(b.ordinal))
        : data
            ?.slice()
            ?.sort((a, b) => Number(a.ordinal) - Number(b.ordinal))
            ?.reverse();
    } else {
      return data;
    }
  };

  const rows = useMemo(() => {
    return searchTableData(sortTableData(tableData)).map((row, index) => (
      <tr key={row.id}>
        {TableCell(row.givenName)}
        {TableCell(row.familyName)}
        {TableCell(row.title)}
        {TableCell(row.ordinal)}

        <td className={classes.buttonColumn}>
          <Group position="right" className={classes.buttonContainer}>
            <Tooltip label={t("tooltips.localization")}>
              <Button
                variant="subtle"
                className={classes.tableIconsButton}
                onClick={() => {
                  dispatch(fetchTeamMemberByIdThunk(row?.id.toString()));
                  navigate(row.id.toString());
                }}
              >
                <World className={classes.tableIconsLogo} />
              </Button>
            </Tooltip>
            <Tooltip label={t("tooltips.edit")}>
              <Button
                className={classes.tableIconsButton}
                variant="subtle"
                onClick={() => {
                  dispatch(fetchTeamMemberByIdThunk(row?.id.toString()));
                  openModal(row);
                }}
              >
                <Pencil className={classes.tableIconsLogo} />
              </Button>
            </Tooltip>
            <Tooltip label={t("tooltips.delete")}>
              <Button
                className={classes.tableIconsButton}
                variant="subtle"
                onClick={() => {
                  setDeleteBannerVisible(true);
                  setDeleteData(row);
                }}
              >
                <Trash className={classes.tableIconsTrash} />
              </Button>
            </Tooltip>
          </Group>
        </td>
      </tr>
    ));
  }, [sortBy, search, reverseSortDirection, tableData]);
  return (
    <>
      <ScrollArea className={classes.scrollArea}>
        <div className={classes.searchAndButtonContainter}>
          <TextInput
            className={classes.searchInput}
            placeholder={t("table.search")}
            mb="md"
            icon={<Search size={14} />}
            value={search}
            onChange={handleSearchChange}
          />

          <Button className={classes.insertButton} onClick={() => openModal()}>
            <Plus size={18} />
          </Button>
        </div>
        <Table
          horizontalSpacing="md"
          verticalSpacing="xs"
          sx={{ tableLayout: "fixed", minWidth: 700 }}
        >
          <thead>
            <tr>
              <Th
                sorted={sortBy === "familyName"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("familyName")}
              >
                {t("goldmanGraffTeam.familyName")}
              </Th>
              <Th
                sorted={sortBy === "givenName"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("givenName")}
              >
                {t("goldmanGraffTeam.givenName")}
              </Th>
              <Th
                sorted={sortBy === "title"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("title")}
              >
                {t("goldmanGraffTeam.title")}
              </Th>
              <Th
                sorted={sortBy === "ordinalNumber"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("ordinalNumber")}
              >
                {t("goldmanGraffTeam.ordinalNumber")}
              </Th>
              <th className={classes.buttonColumn}></th>
            </tr>
          </thead>
          <tbody>
            {status === "pending" && !modalVisible && (
              <tr>
                <td style={{ borderBottom: "none" }}>
                  <LoadingOverlay loader={CustomLoader()} visible />
                </td>
              </tr>
            )}
            {rows.length > 0 ? (
              rows
            ) : (
              <tr>
                <td colSpan={5}>
                  <Text weight={500} align="center">
                    {t("table.nothingFound")}
                  </Text>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </ScrollArea>

      <GoldmanGraffTeamModal
        opened={modalVisible}
        onClose={closeModal}
        updateData={updateData}
      />

      <DeleteBanner
        deleteMethod={deleteMember}
        centered={true}
        opened={deleteBannerVisible}
        onClose={closeDeleteBanner}
        message={t("deleteBanner.message")}
        okButtonText={t("deleteBanner.okButton")}
        cancelButtonText={t("deleteBanner.cancelButton")}
      />
    </>
  );
};

export default GoldmanGraffTeamTable;
