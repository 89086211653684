import {
  ActionIcon,
  Box,
  Button,
  Group,
  Image,
  Loader,
  Modal,
  NumberInput,
  Select,
  Stack,
  Text,
  TextInput,
  createStyles,
  Divider
} from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import React, { useRef, useState } from "react";

import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { showNotification } from "@mantine/notifications";
import { Editor } from "@tinymce/tinymce-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ArrowsLeftRight, Phone } from "tabler-icons-react";
import * as Yup from "yup";
import images from "../../assets/images/index";
import config from "../../config";
import { insertNewFileThunk } from "../../store/slices/file";
import {
  fetchProductByIdThunk,
  fetchProductDataThunk,
  updateProductThunk,
  generateProductSlugThunk
} from "../../store/slices/product";
import { BuildingFactory2 } from "tabler-icons-react";
import ProductPictureCard from "./ProductPicture/ProductPictureCard";

const useStyles = createStyles((theme) => ({
  buttonContainer: {
    display: "flex",

    justifyContent: "flex-end",
    paddingTop: `${theme.spacing.xs}px `,
    paddingRight: "10px",
  },
  root: {
    position: "relative",
    marginTop: 20,
  },

  input: {
    height: "auto",
    paddingTop: 18,
  },

  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: theme.spacing.sm / 2,
    zIndex: 1,
  },

  switch: {
    color: theme.colors.colorBlack,
    "& *": {
      cursor: "pointer",
    },
  },

  insertButton: {
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
  },
  title: {
    lineHeight: 1,
  },
  item: {
    "& + &": {
      borderTop: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[2]
      }`,
    },
  },
  modalTitle: {
    fontWeight: "bold",
  },
  selectInput: {
    marginTop: 20,
    zIndex: 2,
  },

  modalRoot: {
    width: "80vw",
  },
  formContainer: {
    display: "flex",
  },
  leftFormContainer: {
    width: "50%",
    padding: "10px",
  },
  rightFormContainer: {
    width: "50%",
    padding: "10px",
  },
  bottomFormContainer: {
    width: "100%",
    padding: "10px",
  },
  mainPictureContainer: {
    width: "100%",
    display: "flex",

    flexWrap: "none",
  },
  pictureUploadContainer: {
    width: "10%",
    marginRight: "15px",
    padding: "10px",
  },
  picturesContainers: {
    width: "90%",
    display: "flex",

    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  richTextEditorStyle: {
    height: "20vh",
  },
  errorText: {
    color: "#f03e3e",
  },
  errorRichText: {
    color: "#f03e3e",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "red",
    backgroundColor: "red",
  },
  addNewPictureImg: {
    width: "4vw",
    height: "10vh",
  },
  dropzoneHidden: {
    display: "none",
  },
  skuDataContainer: {
    paddingLeft: "10px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    fontSize: "13px",
  },
  skuLabel: {
    marginLeft: "10px",
    fontWeight: "bold",
    flex: "0 0 100px",
  },
  slugButton: {
    position: 'absolute',
    alignSelf: 'center',
    right: '1rem',
    top: 0,
    bottom: 0,
    paddingRight: 0, 
    paddingLeft: '0.6rem',
    margin: '0 !important',
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
  },
}));

const ProductsModal = ({
  opened,
  onClose,
  updateData,
  isUpdate,
  tableState,
}) => {
  const locale = useSelector((state) => state.settings.language);
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.productCategories.tableData);
  const manufacturers = useSelector((state) => state.manufacturers.tableData);
  const finesess = useSelector((state) => state.goldFineness.tableData);
  const openRef = useRef(null);

  const countries = useSelector((state) => state.countries?.tableData);
  const [isSlugGenerated, setIsSlugGenerated] = useState(true);
  const [photoAlts, setPhotoAlts] = useState({});

  const filteredCountriesOfOrigin = countries.filter(
    (item) => !!item?.applicability?.countryOfOrigin
  );

  const packageDimensions = useSelector(
    (state) => state.packageDimension.tableData
  );
  const productMaterials = useSelector(
    (state) => state.productMaterial.tableData
  );

  const schema = Yup.object().shape({
    name: Yup.string()
      .trim(t("productModal.whitespaceValidation"))
      .strict(true)
      .required(t("productModal.categoryValidation"))
      .min(1, t("productModal.nameValidation"))
      .max(255, t("productModal.stringLenghtValidation")),
    categoryId: Yup.string()
      .required(t("productModal.categoryValidation"))
      .nullable()
      .test(
        "isInactiveCategory",
        t("productModal.inactiveCategory"),
        function (value) {
          return (
            categories.find((element) => element?.id === value)?.activity ===
            "ACTIVE"
          );
        }
      )
      .test(
        "isEmptyCategory",
        t("productModal.categoryValidation"),
        function (value) {
          return categories?.find((element) =>
            element?.id === value ? true : false
          );
        }
      ),
    description: Yup.string().test(
      "description",
      t("productModal.descriptionValidation"),
      function (value) {
        return value === "" ? false : true;
      }
    ),
    shortDescription: Yup.string()
      .trim(t("productModal.whitespaceValidation"))
      .strict(true)
      .required(t("productModal.shortDescriptionRequired"))
      .max(1000, t("productModal.shortDescriptionLengthValidation")),

    dimension: Yup.string().max(
      255,
      t("productModal.dimensionLenghtValidation")
    ),
    packageDimensionId: "",
    countryOfOriginCode: Yup.string()
      .min(2, t("productModal.countryValidation"))
      .test(
        "isInactiveCountry",
        t("productModal.inactiveCountry"),
        function (value) {
          return (
            filteredCountriesOfOrigin?.find(
              (element) => element?.countryCode === value
            )?.activity === "ACTIVE"
          );
        }
      )
      .test(
        "isEmptyCountry",
        t("productModal.countryValidation"),
        function (value) {
          return filteredCountriesOfOrigin?.find(
            (element) => element?.countryCode === value
          );
        }
      ),
    finenessId: "",
    materialId: "",
    manufacturerId: "",
    weight: Yup.number()
      .typeError(t("productModal.positiveNumberValidation"))
      .min(0, t("productModal.positiveNumberValidation"))
      .max(2147483647, t("productModal.maxNumberValidation")),
    status: Yup.string().min(2, t("productModal.statusValidation")),
    title: Yup.string()
      .required(t("productModal.titleValidation"))
      .max(
        200,
        t("productModal.titleLength")
      )
      .trim(t("productModal.titleWhitespace"))
      .strict(true),
    metaKeywords: Yup.string()
      .required(t("productModal.keywordsValidation"))
      .max(
        2000,
        t("productModal.keywordsLength")
      )
      .trim(t("productModal.keywordsWhitespace"))
      .strict(true),
    metaDescription: Yup.string()
      .required(t("productModal.metaDescriptionValidation"))
      .max(
        2000,
        t("productModal.metaDescriptionLength")
      )
      .trim(t("productModal.metaDescriptionWhitespace"))
      .strict(true),
    metaRobots: Yup.string()
      .required(t("productModal.robotsValidation"))
      .max(
        200,
        t("productModal.robotsLength")
      )
      .trim(t("productModal.robotsWhitespace"))
      .strict(true),
    slug:Yup.string()
      .required(t("productModal.slugValidations"))
      .max(
        2000,
        t("productModal.slugLength")
      )
      .trim(t("productModal.slugWhitespace"))
      .strict(true),
  });

  const form = useForm({
    initialValues: {
      name: "",
      categoryId: "",
      description: "",
      shortDescription: "",
      dimension: "",
      packageDimensionId: "",
      countryOfOriginCode: "",
      finenessId: "",
      materialId: "",
      manufacturerId: "",
      mass: "",
      recommendOrdinal: "",
      status: "",
      pictures: [],
      title: "",
      metaKeywords: "",
      metaDescription: "",
      metaRobots: "",
      slug: "",
    },
    validate: yupResolver(schema),
  });

  const _generateSlug = async () => {
    return await dispatch(generateProductSlugThunk({text: form.values.slug, locale: 'en'}))
      .unwrap()
      .then((slug) => {
        setIsSlugGenerated(true);
        showNotification({
          message: t("posts.postDetailsForm.slugCreated"),
          color: "green",
        });

        form.setValues({slug: slug})
        return slug;
      })
      .catch((e) => {
        setIsSlugGenerated(false);
        showNotification({
          message: t("posts.postDetailsForm.slugFailedCreate"),
          color: "red",
        });
        return null;
      })
  }

  const prepareNamesForUpdate = (names, name) => {
    var filteredNames = names.filter((item) => item.locale !== "en");
    filteredNames = [...filteredNames, { locale: "en", name: name }];
    return filteredNames;
  };
  const prepareDescriptionsForUpdate = (categories, name) => {
    var filteredCategories = categories.filter((item) => item.locale !== "en");
    filteredCategories = [
      ...filteredCategories,
      { locale: "en", description: name },
    ];
    return filteredCategories;
  };

  const prepareShortDescriptionsForUpdate = (
    shortDescriptions = [],
    shortDescription
  ) => {
    var resultDescriptions = shortDescriptions.filter(
      (item) => item.locale !== "en"
    );
    resultDescriptions = [
      ...resultDescriptions,
      { locale: "en", description: shortDescription },
    ];
    return resultDescriptions;
  };

  const prepareSEOForUpdate = (
    seos = [],
    seo
  ) => {
    var resultSeos = seos.filter(
      (item) => item.locale !== "en"
    );
    resultSeos = [
      ...resultSeos,
      { 
        locale: "en",
        title: seo.title,
        metaDescription: seo.metaDescription,
        metaKeywords: seo.metaKeywords,
        metaRobots: seo.metaRobots
      },
    ];
    return resultSeos;
  };

  const prepareSlugForUpdate = (
    slugs = [],
    slug
  ) => {
    var resultSlugs = slugs.filter(
      (item) => item.locale !== "en"
    );
    resultSlugs = [
      ...resultSlugs,
      { 
        locale: "en",
        slug: slug
      },
    ];
    return resultSlugs;
  };

  const prepareUpdateObject = (formData, oldData, photoIds) => {
    var object = {
      categoryId: formData.categoryId,
      names: prepareNamesForUpdate(oldData.names, formData.name),
      descriptions: prepareDescriptionsForUpdate(
        oldData.descriptions,
        formData.description
      ),
      shortDescriptions: prepareShortDescriptionsForUpdate(
        oldData.shortDescriptions,
        formData.shortDescription
      ),
      dimension: formData.dimension,
      packageDimensionId: packageDimensions?.find(
        (element) => element?.id === formData?.packageDimensionId
      )
        ? formData?.packageDimensionId
        : null,
      countryOfOriginCode: formData.countryOfOriginCode,
      finenessId: finesess.find(
        (element) => element?.id === formData?.finenessId
      )
        ? formData.finenessId
        : null,
      manufacturerId: manufacturers.find(
        (element) => element?.id === formData?.manufacturerId
      )
        ? formData.manufacturerId
        : null,
      weight: formData.weight,
      recommendOrdinal: formData.recommendOrdinal,
      materialId: formData.materialId,
      status: formData.status,
      photoIds: photoIds,
      photoAlts: photoAlts,
      version: oldData?.version,
      seoData: prepareSEOForUpdate(
        oldData.seo,
        {
          title: formData.title,
          metaDescription: formData.metaDescription,
          metaKeywords: formData.metaKeywords,
          metaRobots: formData.metaRobots
        }
      ),
      slugs: prepareSlugForUpdate(oldData.slugs, formData.slug)
    };
    return object;
  };

  const productDataById = useSelector(
    (state) => state.product?.productDataById
  );

  const [uploadedImages, setUploadedImages] = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const uploadImage = async (files) => {
    setIsLoading(true);
    files.forEach(async (file) => {
      const formData = new FormData();

      formData.append("file", file, file.name);

      await dispatch(insertNewFileThunk(formData))
        .unwrap()
        .then((response) => {
          const url = response.location;
          const id = url.substring(url.lastIndexOf("/") + 1, url.length);

          setUploadedImages((prev) => [
            ...prev,
            { ...response, id, name: id, file: file },
          ]);
        });
      setIsLoading(false);
    });
  };

  const [isSubmit, setIsSubmit] = useState(false);

  const submitForm = async (data) => {
    setIsSubmit(true);

    if(!isSlugGenerated) {
      data.slug = (await _generateSlug());
    }

    const photoIds = uploadedImages?.map((image) => image.id);
    if (photoIds.length > 6) {
      setIsSubmit(false);
      return showNotification({
        message: t("productModal.imgError"),
        color: "red",
      });
    }
    var updateObject = prepareUpdateObject(data, updateData, photoIds);

    await dispatch(updateProductThunk({ updateData, updateObject }))
      .unwrap()
      .then(() => {
        showNotification({
          message: t("productModal.updateSuccessfull"),
          color: "green",
        });
        dispatch(fetchProductDataThunk(tableState));
        form.reset();
        setUploadedImages([]);
        onClose();
        setIsSubmit(false);
      })
      .catch(async (error) => {
        if (error === 412) {
          await dispatch(fetchProductByIdThunk(productDataById.id))
            .unwrap()
            .then(async (data) => {
              const photoIdsFromUpdate = uploadedImages?.map(
                (image) => image?.id
              );
              await dispatch(
                updateProductThunk({
                  updateData,
                  updateObject: {
                    ...updateObject,
                    version: data.version,
                    photoIds: photoIdsFromUpdate,
                  },
                })
              )
                .unwrap()
                .then(() => {
                  showNotification({
                    message: t("productModal.updateSuccessfull"),
                    color: "green",
                  });
                  form.reset();
                  setUploadedImages([]);
                  onClose();
                  setIsSubmit(false);
                });
              dispatch(fetchProductDataThunk(tableState));
              form.reset();
              setUploadedImages([]);
              onClose();
              setIsSubmit(false);
            })
            .catch((error) => {
              showNotification({
                message: t("productModal.updateFailed"),
                color: "red",
              });
            });
        } else {
          showNotification({
            message: t("productModal.updateFailed"),
            color: "red",
          });
        }
      });
  };

  const getLocalizedName = (inputArray) => {
    let defaultName = "";
    let localizedName = "";
    for (let i = 0; i < inputArray.length; i++) {
      if (inputArray[i].locale === locale) {
        localizedName = inputArray[i].name;
      } else if (inputArray[i].locale === "en") {
        defaultName = inputArray[i].name;
      }
    }
    return localizedName !== "" ? localizedName : defaultName;
  };
  const prepareCategoriesForSelect = () => {
    return categories.map((element) => ({
      label: getLocalizedName(element.names),
      value: element.id,
      disabled: element.activity === "INACTIVE",
    }));
  };
  const preparePackageDimenision = () => {
    return packageDimensions.map((element) => ({
      label: element.description,
      value: element.id,
    }));
  };
  const prepareCountryOfOrigin = () => {
    return filteredCountriesOfOrigin.map((element) => ({
      label: getLocalizedName(element.countryNames),
      value: element.countryCode,
      disabled: element.activity === "INACTIVE",
    }));
  };
  const prepareManufacturer = () => {
    return manufacturers.map((element) => ({
      label: element.name,
      value: element.id,
    }));
  };
  const prepareMaterial = () => {
    return productMaterials.map((element) => ({
      label: getLocalizedName(element.names),
      value: element.id,
    }));
  };
  const prepareFiness = () => {
    return finesess.map((element) => ({
      label: element.name,
      value: element.id,
    }));
  };
  const prepareStatus = () => {
    return [
      { label: "DRAFT", value: "DRAFT" },
      { label: "ACTIVE", value: "ACTIVE" },
      { label: "INACTIVE", value: "INACTIVE" },
    ];
  };

  useEffect(() => {
    form.clearErrors();
    if (isUpdate) {
      let seo = updateData.seo.find(x => x.locale === 'en');
      let slug = updateData.slugs.find(x => x.locale === 'en');
      form.setValues({
        name: updateData.name,
        categoryId: updateData.categoryId,
        description: updateData.description,
        shortDescription: updateData.shortDescription,
        dimension: updateData.dimension,
        packageDimensionId: updateData.packageDimensionId,
        countryOfOriginCode: updateData.countryOfOriginCode,
        finenessId: updateData.finenessId,
        materialId: updateData.materialId,
        manufacturerId: updateData.manufacturerId,
        weight: updateData.weight,
        recommendOrdinal: updateData.recommendOrdinal,
        status: updateData.status,
        title: seo?.title,
        metaKeywords: seo?.metaKeywords,
        metaDescription: seo?.metaDescription,
        metaRobots: seo?.metaRobots,
        slug: slug?.slug
      });

      setUploadedImages(!!productDataById.photos ? productDataById.photos : []);
    }
  }, [updateData]);

  const deletePicture = (id) => {
    //napomena!!! trenutno nije podrzano brisanje slike s apija

    setUploadedImages((prev) => prev.filter((picture) => picture.id !== id));
  };

  const onAltChange = (id, alt) => {
    if(Object.keys(photoAlts).length > 0) {
      let changedIndex =  Object.keys(photoAlts).indexOf(id);

      if(changedIndex > 0)
        delete photoAlts[Object.keys(photoAlts)[changedIndex]] 

      photoAlts[id] = [{
        locale: 'en',
        alt: alt
      }]

      setPhotoAlts({
          ...photoAlts,
        }
      )
    } else {
      setPhotoAlts({
        [id]: [{
          locale: 'en',
          alt: alt
        }]
      })
    }
  };

  const renderPhotos = uploadedImages.map((image, index) => {
    return (
      <Group key={index} spacing={"xs"}>
        <ProductPictureCard
          key={index}
          image={image}
          imageAlts={image.alts}
          onAltChange={onAltChange}
          name={image}
          onDelete={deletePicture}
        />
        {uploadedImages.length > 1 && index !== uploadedImages.length - 1 ? (
          <Stack>
            <ActionIcon
              onClick={() =>
                setUploadedImages((prev) => {
                  const tempArray = [...prev];
                  const tempValue = tempArray[index + 1];
                  tempArray[index + 1] = tempArray[index];
                  tempArray[index] = tempValue;
                  return tempArray;
                })
              }
            >
              <ArrowsLeftRight />
            </ActionIcon>
          </Stack>
        ) : null}
      </Group>
    );
  });

  const addPictures = (image) => {
    if (image.length + uploadedImages.length > 6) {
      showNotification({
        message: t("productModal.imgError"),
        color: "red",
      });
    } else {
      uploadImage(image);
    }
  };

  return (
    <Modal
      closeOnClickOutside={false}
      centered
      opened={opened}
      onClose={() => {
        setIsSubmit(false);
        setUploadedImages([]);
        form.reset();
        onClose();
      }}
      title={t("productModal.title")}
      classNames={{ title: classes.modalTitle, modal: classes.modalRoot }}
    >
      <form onSubmit={form.onSubmit(submitForm)}>
        {isUpdate ? (
          <Stack>
            <div style={{ display: "flex" }}>
              <Text className={classes.skuLabel}>{"SKU name: "}</Text>
              <Text className={classes.skuData}>{updateData.skuName}</Text>
            </div>
            <div style={{ display: "flex" }}>
              <Text className={classes.skuLabel}>{"SKU ID: "}</Text>
              <Text className={classes.skuData}>{updateData.skuId}</Text>
            </div>
          </Stack>
        ) : (
          ""
        )}
        <div className={classes.formContainer}>
          <div className={classes.leftFormContainer}>
            <TextInput
              classNames={classes}
              label={t("productModal.nameLabel")}
              placeholder={t("productModal.namePlaceholder")}
              {...form.getInputProps("name")}
            />
            <TextInput
              classNames={classes}
              label={t("productModal.dimensionLabel")}
              placeholder={t("productModal.dimensionPlaceholder")}
              {...form.getInputProps("dimension")}
            />
            <Select
              styles={(theme) => ({
                item: {
                  // applies styles to selected item
                  "&[data-selected]": {
                    "&, &:hover": {
                      backgroundColor: "#e7f5ff",
                      color: theme.colors.colorDarkGray,
                    },
                  },

                  // applies styles to hovered item (with mouse or keyboard)
                  "&[data-hovered]": {},
                },
              })}
              classNames={classes}
              data={prepareCountryOfOrigin()}
              searchable
              label={t("productModal.countryOfOriginLabel")}
              placeholder={t("productModal.countryOfOriginPLaceholder")}
              {...form.getInputProps("countryOfOriginCode")}
            />
            <Select
              styles={(theme) => ({
                item: {
                  // applies styles to selected item
                  "&[data-selected]": {
                    "&, &:hover": {
                      backgroundColor: "#e7f5ff",
                      color: theme.colors.colorDarkGray,
                    },
                  },

                  // applies styles to hovered item (with mouse or keyboard)
                  "&[data-hovered]": {},
                },
              })}
              classNames={classes}
              data={prepareMaterial()}
              searchable
              clearable
              label={t("productModal.materialLabel")}
              placeholder={t("productModal.materialPlaceholder")}
              {...form.getInputProps("materialId")}
            />
            <NumberInput
              label={t("productModal.massLabel")}
              placeholder={t("productModal.massPlaceholder")}
              classNames={classes}
              precision={4}
              min={0.0001}
              {...form.getInputProps("weight")}
            />
            <NumberInput
              label={t("productModal.recommendOrdinalLabel")}
              placeholder={t("productModal.recommendOrdinalLabel")}
              classNames={classes}
              precision={0}
              min={0}
              {...form.getInputProps("recommendOrdinal")}
            />
          </div>
          <div className={classes.rightFormContainer}>
            <Select
              styles={(theme) => ({
                item: {
                  // applies styles to selected item
                  "&[data-selected]": {
                    "&, &:hover": {
                      backgroundColor: "#e7f5ff",
                      color: theme.colors.colorDarkGray,
                    },
                  },

                  // applies styles to hovered item (with mouse or keyboard)
                  "&[data-hovered]": {},
                },
              })}
              classNames={classes}
              data={prepareCategoriesForSelect()}
              searchable
              label={t("productModal.categoryLabel")}
              placeholder={t("productModal.categoryDescription")}
              {...form.getInputProps("categoryId")}
            />
            <Select
              styles={(theme) => ({
                item: {
                  // applies styles to selected item
                  "&[data-selected]": {
                    "&, &:hover": {
                      backgroundColor: "#e7f5ff",
                      color: theme.colors.colorDarkGray,
                    },
                  },

                  // applies styles to hovered item (with mouse or keyboard)
                  "&[data-hovered]": {},
                },
              })}
              classNames={classes}
              data={preparePackageDimenision()}
              searchable
              label={t("productModal.packageDimensionLabel")}
              placeholder={t("productModal.packageDimensionPlaceholder")}
              {...form.getInputProps("packageDimensionId")}
            />
            <Select
              styles={(theme) => ({
                item: {
                  // applies styles to selected item
                  "&[data-selected]": {
                    "&, &:hover": {
                      backgroundColor: "#e7f5ff",
                      color: theme.colors.colorDarkGray,
                    },
                  },

                  // applies styles to hovered item (with mouse or keyboard)
                  "&[data-hovered]": {},
                },
              })}
              classNames={classes}
              data={prepareFiness()}
              searchable
              clearable
              label={t("productModal.finessLabel")}
              placeholder={t("productModal.finessPlaceholder")}
              {...form.getInputProps("finenessId")}
            />
            <Select
              styles={(theme) => ({
                item: {
                  // applies styles to selected item
                  "&[data-selected]": {
                    "&, &:hover": {
                      backgroundColor: "#e7f5ff",
                      color: theme.colors.colorDarkGray,
                    },
                  },

                  // applies styles to hovered item (with mouse or keyboard)
                  "&[data-hovered]": {},
                },
              })}
              classNames={classes}
              data={prepareManufacturer()}
              searchable
              clearable
              label={t("productModal.manufacturerLabel")}
              placeholder={t("productModal.manufacturerPlaceholder")}
              {...form.getInputProps("manufacturerId")}
            />
            <Select
              styles={(theme) => ({
                item: {
                  // applies styles to selected item
                  "&[data-selected]": {
                    "&, &:hover": {
                      backgroundColor: "#e7f5ff",
                      color: theme.colors.colorDarkGray,
                    },
                  },

                  // applies styles to hovered item (with mouse or keyboard)
                  "&[data-hovered]": {},
                },
              })}
              classNames={classes}
              data={prepareStatus()}
              searchable
              label={t("productModal.statusLabel")}
              placeholder={t("productModal.statusPlaceholder")}
              {...form.getInputProps("status")}
            />
          </div>
        </div>

        <div className={classes.bottomFormContainer}>
          <Text weight={500}>{t("productModal.seoSectionTitle")}</Text>
          <Divider />

          <div className={classes.formContainer}>
            <div 
              style={{padding: 0, paddingRight: 10}}
              className={classes.leftFormContainer}>
              <TextInput
                classNames={classes}
                label={t("productModal.seoTitle")}
                placeholder={t("productModal.seoTitleDescription")}
                {...form.getInputProps("title")}
              />
              <TextInput
                classNames={classes}
                label={t("productModal.seoKeywords")}
                placeholder={t("productModal.seoKeywordsPlaceholder")}
                {...form.getInputProps("metaKeywords")}
              />
            </div>

            <div 
              style={{padding: 0, paddingLeft: 10}}
              className={classes.rightFormContainer}>
              <TextInput
                classNames={classes}
                label={t("productModal.seoDescription")}
                placeholder={t("productModal.seoDescriptionPlaceholder")}
                {...form.getInputProps("metaDescription")}
              />
              <TextInput
                classNames={classes}
                label={t("productModal.seoRobots")}
                placeholder={t("productModal.seoRobotsPlaceholder")}
                {...form.getInputProps("metaRobots")}
              />
            </div>
          </div>

          <div style={{position: 'relative'}}>
            <TextInput
              classNames={classes}
              label={t("productModal.seoSlug")}
              placeholder={t("productModal.seoSlugPlaceholder")}
              onKeyDown={() => setIsSlugGenerated(false)}
              {...form.getInputProps("slug")}
            />{" "}
            <Button
              leftIcon={<BuildingFactory2 />}
              onClick={() => _generateSlug()}
              className={classes.slugButton}
            >{''}
            </Button>
          </div>
        </div>

        <div className={classes.bottomFormContainer}>
          <Text weight={500}>{t("productModal.descriptionTitle")}</Text>
          <Divider />

          <TextInput
            classNames={classes}
            label={t("productModal.shortDescriptionLabel")}
            placeholder={t("productModal.shortDescriptionPlaceholder")}
            {...form.getInputProps("shortDescription")}
          />
        </div>
        
        <div className={classes.bottomFormContainer}>
          <Editor
            apiKey={config.REACT_APP_TINY_MCE_EDITOR_API_KEY}
            value={form?.values?.description}
            onEditorChange={(content) =>
              form.setFieldValue("description", content)
            }
            init={{
              selector: "textarea",
              menubar: false,
              plugins: "link media advlist lists",
              toolbar:
                "bold italic | alignleft aligncenter alignright | numlist bullist outdent indent | link",
              branding: false,
            }}
          />
          {form.errors.description !== null ? (
            <p className={classes.errorText}>{form.errors.description}</p>
          ) : (
            " "
          )}
        </div>
        <Group sx={{ alignItems: "flex-start" }} noWrap>
          <Image
            src={images.addPicture}
            width={120}
            fit="cover"
            alt="logo"
            onClick={() => openRef.current()}
            sx={{ cursor: "pointer", padding: 8 }}
          />
          <Group>
            {renderPhotos}
            {isLoading && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: 120,
                  height: 100,
                  alignItems: "center",
                }}
              >
                <Loader />
              </Box>
            )}
          </Group>
        </Group>

        <Dropzone
          maxSize={1000000}
          onReject={() =>
            showNotification({
              message: t("productModal.imgSizeError"),
              color: "red",
            })
          }
          onDrop={addPictures}
          openRef={openRef}
          className={classes.dropzoneHidden}
          accept={IMAGE_MIME_TYPE}
        >
          <div></div>
        </Dropzone>
        <div className={classes.buttonContainer}>
          <Button
            loading={isSubmit}
            disabled={!uploadedImages.length}
            type="submit"
            className={classes.insertButton}
          >
            {t("modalCommon.saveButton")}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ProductsModal;
