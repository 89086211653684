import {
  LoadingOverlay,
  createStyles,
  Group,
  ScrollArea,
  Box,
} from "@mantine/core";
import { useSelector } from "react-redux";

import CustomLoader from "../common/CustomLoader";

import BuybackUpdates from "./BuybackUpdatesData";

import InfoSection from "./Sections/InfoSection";
import ApplicantSection from "./Sections/ApplicantSection";
import AcceptanceDataSection from "./Sections/AcceptanceDataSection";
import AppraisalAndTrackingSection from "./Sections/AppraisalAndTrackingSection";

const useStyles = createStyles((theme) => ({
  input: {
    height: "auto",
    paddingTop: 18,
  },
  insertButton: {
    alignSelf: "flex-end",
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
  },
  scrollArea: {
    height: "100%",
  },
  root: { marginTop: 10 },
  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: theme.spacing.sm / 2,
    zIndex: 1,
  },
  insertButton: {
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
    color: theme.colors.colorWhite,
  },
  tableIconsButton: {
    padding: "0px",
    "&:hover": {
      backgroundColor: theme.colors.colorGray,
    },
  },
  tableIconsLogo: {
    color: theme.colors.colorDarkGray,
    "&:hover": {
      color: theme.colors.colorBlack,
    },
  },
}));

const BuybackDetails = () => {
  const { classes } = useStyles();

  const buybackDetails =
    useSelector((state) => state?.buyback?.buybackByIdData) || [];

  const loadingDetails = useSelector((state) => state?.buyback?.status);

  return (
    <>
      <ScrollArea type="scroll" className={classes.scrollArea}>
        {loadingDetails === "pending" ? (
          <LoadingOverlay loader={CustomLoader()} visible />
        ) : (
          <Box>
            <Group position="right" className={classes.buttonContainer}>
              <BuybackUpdates detailsData={buybackDetails} />
            </Group>
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <InfoSection detailsData={buybackDetails} />
              <ApplicantSection detailsData={buybackDetails} />
              <AcceptanceDataSection detailsData={buybackDetails} />
              <AppraisalAndTrackingSection detailsData={buybackDetails} />
            </Box>
          </Box>
        )}
      </ScrollArea>
    </>
  );
};
export default BuybackDetails;
