import { createStyles } from "@mantine/core";
import HighlightsTable from "./HighlightsTable";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchHighlightsThunk } from "../../store/slices/highlights";

const useStyles = createStyles((theme) => ({
  mainContainer: {
    height: "100%",
    width: "100%",
  },
  manufacturersSelectContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingRight: `${theme.spacing.md}px`,
  },
}));

const Highlights = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchHighlightsThunk());
  }, [dispatch]);

  const { highlights } = useSelector((state) => state?.highlights || {});

  return (
    <div className={classes.mainContainer}>
      <HighlightsTable tableData={highlights} />
    </div>
  );
};

export default Highlights;
