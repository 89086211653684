import { Box, Button, Modal, MultiSelect, createStyles } from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { createSelector } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateCompositionParameters } from "../../store/slices/webComposition";
import { compositionDataByKey } from "./data";

const selectWebComposition = (state) => state.webComposition;

const selectCompositionParametersValue = createSelector(
  [selectWebComposition],
  (webComposition) => webComposition?.compositionParameters
);

const useStyles = createStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingTop: `${theme.spacing.xs}px `,
  },
  root: {
    position: "relative",
    marginTop: 20,
  },

  input: {
    height: "auto",
    paddingTop: 18,
  },

  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: theme.spacing.sm / 2,
    zIndex: 1,
  },

  insertButton: {
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
  },
  iconGreen: {
    color: theme.colors.green[5],
    "&:hover": {
      color: theme.colors.green[7],
    },
  },
  iconRed: {
    color: theme.colors.red[5],
    "&:hover": {
      color: theme.colors.red[7],
    },
  },
  itemWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },
  iconWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  formWrapper: {},
}));

const WebCompositionModal = (props) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const { opened, onClose, compositionBlockName, compositionBlockKey } = props;

  const data = useSelector(selectCompositionParametersValue);

  const form = useForm({
    initialValues: {
      valuesArray: "",
    },
  });

  useEffect(() => {
    form.setFieldValue("valuesArray", data.valuesArray);
  }, [data]);

  const submitForm = async (formData) => {
    await dispatch(
      updateCompositionParameters({
        key: data.key,
        valuesArray: formData.valuesArray,
      })
    )
      .unwrap()
      .then(() => {
        form.reset();
        onClose();
        showNotification({
          message: t("webComposition.success"),
          color: "green",
        });
      })
      .catch((e) => {
        showNotification({
          message: t("webComposition.error"),
          color: "red",
        });
      });
  };

  return (
    <Modal
      closeOnClickOutside={false}
      size={"xl"}
      centered
      opened={opened}
      onClose={() => {
        onClose();
      }}
      title={compositionBlockName}
      sx={() => ({
        ".mantine-Modal-title": {
          fontWeight: "bold",
        },
      })}
    >
      {compositionDataByKey[compositionBlockKey] ? (
        <form
          onSubmit={form.onSubmit(submitForm)}
          className={classes.formWrapper}
        >
          <MultiSelect
            maxDropdownHeight={160}
            disabled={
              compositionBlockKey === "UI_WEB_SERVICE_BUYBACK_COMPOSITION" ||
              compositionBlockKey === "UI_WEB_SERVICE_COUNSELING_COMPOSITION" ||
              compositionBlockKey === "UI_WEB_SERVICE_DELIVERY_COMPOSITION" ||
              compositionBlockKey === "UI_WEB_SERVICE_STORAGE_COMPOSITION"
            }
            data={compositionDataByKey[compositionBlockKey]}
            searchable
            styles={(theme) => ({
              root: { position: "relative" },
              input: {
                height: "auto",
                paddingTop: 30,
                paddingBottom: 12,
              },
              label: {
                pointerEvents: "none",
                fontSize: theme.fontSizes.xs,
                paddingLeft: theme.spacing.sm / 2,
                zIndex: 1,
              },
            })}
            label={
              <Box
                sx={(theme) => ({
                  position: "absolute",
                  paddingLeft: theme.spacing.sm / 2,
                  top: 28,
                  zIndex: 99,
                })}
              >
                {t("webComposition.selectLabel")}
              </Box>
            }
            placeholder={t("webComposition.selectPlaceholder")}
            {...form.getInputProps("valuesArray")}
          />
          <div className={classes.buttonContainer}>
            <Button className={classes.insertButton} type="submit">
              {t("webComposition.save")}
            </Button>
          </div>
        </form>
      ) : (
        <Box>{t("webComposition.noData")}</Box>
      )}
    </Modal>
  );
};

export default WebCompositionModal;
