import { useTranslation } from "react-i18next";
import { useForm, yupResolver } from "@mantine/form";
import * as Yup from "yup";
import { Box, Button, createStyles, Modal, TextInput } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { DatePicker } from "@mantine/dates";
import { Editor } from "@tinymce/tinymce-react";
import {
  createLegalDocumentsByTypeThunk,
  fetchLegalDocumentsByTypeIdThunk,
  fetchLegalDocumentsByTypeThunk,
  updateLegalDocumentsByTypeThunk,
} from "../../store/slices/legalDocuments";
import config from "../../config";
import { createFileThunk } from "../../store/slices/file";

const useStyles = createStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingTop: `${theme.spacing.xs}px `,
  },
  root: {
    position: "relative",
    marginTop: 20,
  },

  input: {
    height: "auto",
    paddingTop: 18,
  },

  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: theme.spacing.sm / 2,
    zIndex: 1,
  },
  errorText: {
    color: "#f03e3e",
    fontSize: "12px",
  },
  insertButton: {
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
  },
}));

const KYCModal = (props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { opened, onClose, isUpdate, updateData, KYC } = props;
  const locale = useSelector((state) => state.settings.language);

  const tableData = useSelector(
    (state) => state?.legalDocumentsByType?.tableData
  );

  const schema = Yup.object().shape({
    name: Yup.string()
      .trim(t("kyc.whitespaceValidation"))
      .strict(true)
      .required(t("kyc.required"))
      .min(1, t("kyc.nameValidation"))
      .max(255, t("kyc.nameLength")),
    validFrom: Yup.date()
      .typeError(t("kyc.invalidDate"))
      .test("isFreeTermPeriod", t("kyc.isFreeTermPeriod"), (value) => {
        const isEdit = updateData?.id;
        const data = isEdit
          ? tableData?.filter((item) => item.id !== updateData?.id)
          : tableData;

        const date = data?.find((item) => {
          return (
            new Date(value) >= new Date(item?.validFrom) &&
            new Date(value) <= new Date(item?.validTo)
          );
        });

        return date ? false : true;
      }),
    validTo: Yup.date()
      .typeError(t("kyc.invalidDate"))
      .test("isFreeTermPeriod", t("kyc.isFreeTermPeriod"), function (value) {
        const { validFrom } = this.parent;
        const isEdit = updateData?.id;

        const data = isEdit
          ? tableData?.filter((item) => item.id !== updateData?.id)
          : tableData;

        const checkAvailabiltyDate = data.find(
          (item) =>
            new Date(item.validFrom).getTime() >= validFrom.getTime() &&
            new Date(item.validTo).getTime() <= new Date(value).getTime()
        );

        return checkAvailabiltyDate ? false : true;
      })
      .test("isFreeTermPeriod", t("kyc.isFreeTermPeriod"), function (value) {
        const isEdit = updateData?.id;
        const data = isEdit
          ? tableData?.filter((item) => item.id !== updateData?.id)
          : tableData;

        const date = data?.find((item) => {
          return (
            new Date(value) >= new Date(item?.validFrom) &&
            new Date(value) <= new Date(item?.validTo)
          );
        });

        return date ? false : true;
      })
      .test("isGreaterThan", t("kyc.isGreaterThan"), function (value) {
        const { validFrom } = this.parent;
        return value.getTime() > validFrom.getTime();
      }),
    content: Yup.string()
      .trim(t("kyc.contentWhitespaceValidation"))
      .strict(true)
      .required(t("kyc.required"))
      .min(1, t("kyc.contentValidation"))
      .test("emptyContent", t("kyc.contentValidation"), function (value) {
        const parsedValue = value.replace(/(<([^>]+)>|&nbsp;)/gi, "");

        return parsedValue.trim() === "" ? false : true;
      }),
  });

  const form = useForm({
    initialValues: {
      name: "",
      type: KYC,
      validFrom: "",
      validTo: "",
      content: "",
    },
    validate: yupResolver(schema),
  });

  const handleImageUploadEditor = async (blobInfo, success, failure) => {
    const formData = new FormData();
    formData.append("file", blobInfo.blob());
    try {
      const response = await dispatch(createFileThunk(formData)).unwrap();
      success("Uploaded image!");
      return response["x-direct-location"];
    } catch (error) {
      failure("Error uploading image!");
    }
  };

  const submitForm = async (data) => {
    let updateItem = [];

    if (isUpdate) {
      await dispatch(fetchLegalDocumentsByTypeIdThunk(updateData.id))
        .unwrap()
        .then((response) => {
          updateItem = response;
        })
        .catch((e) => {
          showNotification({
            message: t("deliveryMethodModal.updateFailed"),
            color: "red",
          });
        });

      let updateArrayObject = updateItem.descriptions.filter(
        (item) => item.locale !== "en"
      );
      updateArrayObject = [
        ...updateArrayObject,
        { locale: "en", name: data.name, content: data.content },
      ];

      const objectToUpdate = {
        validFrom: data.validFrom,
        validTo: data.validTo,
        contents: updateArrayObject,
      };
      await dispatch(
        updateLegalDocumentsByTypeThunk({
          id: updateData.id,
          data: objectToUpdate,
        })
      )
        .unwrap()
        .then((response) => {
          showNotification({
            message: t("kyc.updateSuccessfull"),
            color: "green",
          });
        })
        .catch((e) => {
          showNotification({
            message: t("kyc.updateFailed"),
            color: "red",
          });
        });
    } else {
      await dispatch(createLegalDocumentsByTypeThunk({ locale, data }))
        .unwrap()
        .then(() => {
          form.reset();
          onClose();
          showNotification({
            message: t("kyc.insertSuccessfull"),
            color: "green",
          });
        })
        .catch((e) => {
          showNotification({
            message: t("kyc.insertFailed"),
            color: "red",
          });
        });
    }
    await dispatch(fetchLegalDocumentsByTypeThunk(KYC));

    onClose();
  };

  useEffect(() => {
    form.clearErrors();
    form.reset();
    if (isUpdate) {
      form.setValues({
        name: updateData.name,
        type: updateData.type,
        validFrom: new Date(updateData.validFrom),
        validTo: new Date(updateData.validTo),
        content: updateData.content,
      });
    }
  }, [updateData]);

  return (
    <>
      <Modal
        closeOnClickOutside={false}
        centered
        size="xl"
        opened={opened}
        onClose={() => {
          form.clearErrors();
          form.reset();
          onClose();
        }}
        title={t("kyc.modalTitle")}
        sx={() => ({
          ".mantine-Modal-title": {
            fontWeight: "bold",
          },
        })}
      >
        <form onSubmit={form.onSubmit(submitForm)}>
          <TextInput
            label={t("kyc.nameInputTitle")}
            placeholder={t("kyc.nameInputPlaceholder")}
            classNames={classes}
            {...form.getInputProps("name")}
          />
          <TextInput
            readOnly
            label={t("kyc.typeTitle")}
            placeholder={t("kyc.nameInputPlaceholder")}
            classNames={classes}
            defaultValue={form?.values?.type}
          />
          <DatePicker
            minDate={new Date()}
            classNames={classes}
            inputFormat="DD.MM.YYYY"
            label={t("kyc.dateFromTitle")}
            {...form.getInputProps("validFrom")}
          />

          <DatePicker
            minDate={new Date()}
            classNames={classes}
            inputFormat="DD.MM.YYYY"
            label={t("kyc.dateToTitle")}
            disabled={form.getInputProps("validFrom").value === ""}
            {...form.getInputProps("validTo")}
          />
          <Box style={{ marginTop: 20 }}>
            <Editor
              value={form.values.content}
              onEditorChange={(content) => {
                form.setFieldValue("content", content);
              }}
              apiKey={config.REACT_APP_TINY_MCE_EDITOR_API_KEY}
              init={{
                height: 300,
                menubar: false,
                plugins: "advlist lists image link preview",
                toolbar:
                  "bold italic | image link preview | casechange blocks | numlist bullist",
                branding: false,
                images_upload_handler: handleImageUploadEditor,
              }}
            />

            {form.errors.content !== null ? (
              <p className={classes.errorText}>{form.errors.content}</p>
            ) : (
              ""
            )}
          </Box>
          <div className={classes.buttonContainer}>
            <Button className={classes.insertButton} type="submit">
              {t("modalCommon.saveButton")}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default KYCModal;
