import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userPurchaseStatisticsApi } from "../../http/services";

const initialState = {
  data: {},
  status: "",
};

export const fetchUserPurchaseStatisticsThunk = createAsyncThunk(
  "userPurchaseStatistics/getData",
  async (args) => {
    return await userPurchaseStatisticsApi.getUserPurchaseStatistics(args);
  }
);

const userPurchaseStatistics = createSlice({
  name: "userPurchaseStatistics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserPurchaseStatisticsThunk.pending, (state) => {
      return { ...state, status: "pending" };
    });
    builder.addCase(
      fetchUserPurchaseStatisticsThunk.fulfilled,
      (state, action) => {
        return { ...state, status: "fulfilled", data: action.payload };
      }
    );
    builder.addCase(fetchUserPurchaseStatisticsThunk.rejected, (state) => {
      return { ...state, status: "rejected", data: [] };
    });
  },
});

const { reducer } = userPurchaseStatistics;
export { reducer as userPurchaseStatisticsReducer };
