import { createStyles } from "@mantine/core";
import { Card } from "@mantine/core";
import React  from "react";
import * as Icons from "tabler-icons-react";

const useStyles = createStyles((theme) => ({
  cardStyle: {
    borderRadius: "1rem",
    margin: "1rem",
    padding: "1.5rem !important"
  },
  titleDiv: {
    display: "flex",
    alignItems: "center"
  },
  titleFont: {
    fontSize: 24,
    width: "100%",
    textAlign: "center",
    fontWeight: 500
  },
  userCountFont: {
    display: "flex",
    fontSize: 32,
    width: "100%",
    justifyContent: "center",
    fontWeight: 700,
    marginTop: "2rem",
    marginBottom: "1.5rem"
  }
}));

const UserStatisticCard = ({label, userCount, iconName, backgroundColour, fontColour}) => {
  const { classes } = useStyles();
  const IconComponent = Icons[iconName];

  let readableUserCount = userCount?.toLocaleString(undefined, {maximumFractionDigits:2})

  return (
    <Card style={{background: backgroundColour}} className={classes.cardStyle}>
      <div className={classes.titleDiv}>
        <IconComponent style={{color: fontColour, position: "absolute"}} size={42}/>
        <span 
          style={{color: fontColour}}
          className={classes.titleFont}
        >
          {label}
        </span>
      </div>

      <span 
        style={{color: fontColour}}
        className={classes.userCountFont}
      >
        {readableUserCount || '-'}
      </span>
    </Card>
  );
};

export default UserStatisticCard;