import {
  Card,
  Divider,
  SimpleGrid,
  Table,
  Text,
  TextInput,
  createStyles,
} from "@mantine/core";

import { useTranslation } from "react-i18next";

import dayjs from "dayjs";

const useStyles = createStyles((theme) => ({
  input: {
    height: "auto",
    paddingTop: 18,
  },
  insertButton: {
    alignSelf: "flex-end",
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
  },
  root: { marginTop: 10 },
  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: theme.spacing.sm / 2,
    zIndex: 1,
  },
  insertButton: {
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
    color: theme.colors.colorWhite,
  },
  tableIconsButton: {
    padding: "0px",
    "&:hover": {
      backgroundColor: theme.colors.colorGray,
    },
  },
  tableIconsLogo: {
    color: theme.colors.colorDarkGray,
    "&:hover": {
      color: theme.colors.colorBlack,
    },
  },
}));

const InfoSection = (props) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { detailsData } = props;

  const dataObj = {
    number: detailsData?.number,
    createdAt: dayjs(detailsData?.createdAt).format("DD.MM.YYYY HH:mm:ss"),
    modifiedAt:
      dayjs(detailsData.modifiedAt).format("DD.MM.YYYY HH:mm:ss") || "",
    status: !!detailsData.status ? detailsData?.status : "",
    platformNotes: detailsData?.platformNotes
      ? detailsData?.platformNotes
          .map((n) => ({
            createdAt: new Date(n?.createdAt),
            value: n?.value,
            id: n?.id,
          }))
          .sort((a, b) => b?.createdAt - a?.createdAt)
      : [],
  };

  return (
    <>
      <Divider
        size="xl"
        my="xs"
        label={t("buybackDetails.info")}
        labelPosition="center"
      />
      <SimpleGrid cols={2}>
        <Card withBorder>
          <Text weight={500}>{t("buybackDetails.basicInfo")}</Text>
          <TextInput
            classNames={classes}
            readOnly
            label={t("buybackDetails.number")}
            defaultValue={dataObj?.number}
          />
          <TextInput
            classNames={classes}
            readOnly
            label={t("buybackDetails.createdAt")}
            defaultValue={dataObj?.createdAt}
          />
          <TextInput
            classNames={classes}
            readOnly
            label={t("buybackDetails.modifiedAt")}
            defaultValue={dataObj?.modifiedAt}
          />
          <TextInput
            classNames={classes}
            readOnly
            label={t("buybackDetails.status")}
            defaultValue={dataObj?.status}
          />
        </Card>
        <Card withBorder>
          <Text weight={500}>{t("buybackDetails.platformNotes")}</Text>

          <Table>
            <thead>
              <tr>
                <th>{t("buybackDetails.value")}</th>
                <th>{t("buybackDetails.createdAt")}</th>
                {dataObj?.platformNotes && <th></th>}
              </tr>
            </thead>
            <tbody>
              {dataObj?.platformNotes.map((n, index) => (
                <tr key={index}>
                  <td>{n.value}</td>
                  <td>{dayjs(n?.createdAt).format("DD.MM.YYYY")}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </SimpleGrid>
    </>
  );
};

export default InfoSection;
