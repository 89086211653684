import { useEffect } from "react";
import GoldmanGraffTeamTable from "./GoldmanGraffTeamTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchTeamMembersThunk } from "../../store/slices/teamMembers";

const { createStyles } = require("@mantine/core");

const useStyles = createStyles((theme) => ({
    mainContainer: {
      height: "100%",
      width: "100%",
    },
    manufacturersSelectContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      paddingRight: `${theme.spacing.md}px`,
    },
  }));

const GoldmanGraffTeam=()=>{
  const tableData = useSelector(
    (state) => state?.teamMembers?.data||[]
  );


  const dispatch=useDispatch()

  useEffect(() => {
    dispatch(fetchTeamMembersThunk());
  }, []);
    const { classes } = useStyles();
    return (
        <div className={classes.mainContainer}>
          <div>
            <GoldmanGraffTeamTable tableData={tableData}/>
          </div>
        </div>
      );
}



export default GoldmanGraffTeam