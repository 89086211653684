import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { paymentsApi } from "../../http/services";

export const createPaymentsConfirmThunk = createAsyncThunk(
  "payments/paymentsConfirm",
  async (args) => {
    return await paymentsApi.createPaymentsConfirm(args);
  }
);
const initialState = {
  open: false,
  orderId: "",
};

const paymentsConfirmSlice = createSlice({
  name: "paymentsConfirm",
  initialState,
  reducers: {
    setPaymentAction: (state, action) => {
      return {
        ...state,
        open:action.payload.open,
        orderId:action.payload.orderId
      };
    },
  },
});
const { actions, reducer } = paymentsConfirmSlice;
export const { setPaymentAction } = actions;
export { reducer as paymentsConfirmReducer };
