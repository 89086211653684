import {
  Button,
  Modal,
  Select,
  TextInput,
  Textarea,
  createStyles,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { languageLocaleObject } from "../common/parseLanguageNames";
import { useForm, yupResolver } from "@mantine/form";
import { useEffect } from "react";
import * as Yup from "yup";

import {
  fetchTeamMemberByIdThunk,
  updateTeamMemberThunk,
} from "../../store/slices/teamMembers";
import { showNotification } from "@mantine/notifications";

const useStyles = createStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingTop: `${theme.spacing.xs}px `,
  },
  root: {
    position: "relative",
    marginTop: 20,
  },

  input: {
    height: "auto",
    paddingTop: 18,
  },

  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: theme.spacing.sm / 2,
    zIndex: 1,
  },

  insertButton: {
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
  },
  iconGreen: {
    color: theme.colors.green[5],
    "&:hover": {
      color: theme.colors.green[7],
    },
  },
  iconRed: {
    color: theme.colors.red[5],
    "&:hover": {
      color: theme.colors.red[7],
    },
  },
  itemWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },
  iconWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  formWrapper: {},
}));
const GoldmanGraffTeamLocalizationModal = (props) => {
  const { opened, onClose, updateData } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const languageData = useSelector((state) => state.language.tableData);

  const tableData = useSelector((state) => state?.teamMembers?.dataById || []);

  const dispatch = useDispatch();

  const schema = Yup.object().shape({
    locale: Yup.string().min(2, t("goldmanGraffTeam.codeValidation")),
    title: Yup.string()
      .required(t("goldmanGraffTeam.titleRequired"))
      .trim(t("goldmanGraffTeam.titleWhitespaceValidation"))
      .strict(true)
      .max(255, t("goldmanGraffTeam.titleLength")),

    description: Yup.string()
      .required(t("goldmanGraffTeam.descriptionValidation"))
      .trim(t("goldmanGraffTeam.descriptionWhitespaceValidation"))
      .strict(true)
      .max(2000, t("goldmanGraffTeam.descriptionLength")),
  });

  const form = useForm({
    initialValues: {
      locale: "",
      title: "",
      description: "",
    },
    validate: yupResolver(schema),
  });

  const submitForm = async (data) => {
    if (updateData) {
      let dataCopy = JSON.parse(JSON.stringify(tableData));
      const preparedLocalizations = Object.assign(dataCopy.localizations, {
        [data.locale]: { title: data.title, description: data.description },
      });
      dataCopy.localizations = preparedLocalizations;

      await dispatch(updateTeamMemberThunk(dataCopy))
        .unwrap()
        .then(() => {
          showNotification({
            message: t("deliveryMethodModal.updateSuccessfull"),
            color: "green",
          });
        })
        .catch(() => {
          showNotification({
            message: t("deliveryMethodModal.updateFailed"),
            color: "red",
          });
        });
      await dispatch(fetchTeamMemberByIdThunk(dataCopy.id));
    } else {
      const obj = {
        ...tableData,
        localizations: {
          ...tableData.localizations,
          [`${data.locale}`]: {
            title: data.title,
            description: data.description,
          },
        },
      };

      await dispatch(updateTeamMemberThunk(obj))
        .unwrap()
        .then(() => {
          showNotification({
            message: t("deliveryMethodModal.updateSuccessfull"),
            color: "green",
          });
        })
        .catch(() => {
          showNotification({
            message: t("deliveryMethodModal.updateFailed"),
            color: "red",
          });
        });
      await dispatch(fetchTeamMemberByIdThunk(obj.id));
    }

    form.reset();
    onClose();
  };

  const extractKeyValue = (obj) => {
    for (let [key, values] of Object.entries(obj)) {
      form.setValues({
        locale: key,
        title: values.title,
        description: values.description,
      });

      return { key, values };
    }
  };

  useEffect(() => {
    form.clearErrors();
    form.reset();
    if (updateData) {
      extractKeyValue(updateData);
    }
  }, [updateData]);
  return (
    <>
      <Modal
        closeOnClickOutside={false}
        centered
        size="xl"
        opened={opened}
        onClose={() => {
          form.reset();
          onClose();
        }}
        title={t("generalTermsLocalizationModal.modalTitle")}
        sx={() => ({
          ".mantine-Modal-title": {
            fontWeight: "bold",
          },
        })}
      >
        <form onSubmit={form.onSubmit(submitForm)}>
          <Select
            style={{ marginBottom: 20, zIndex: 100 }}
            styles={(theme) => ({
              item: {
                // applies styles to selected item
                "&[data-selected]": {
                  "&, &:hover": {
                    backgroundColor: "#e7f5ff",
                    color: theme.colors.colorDarkGray,
                  },
                },
              },
            })}
            disabled={updateData}
            data={
              updateData
                ? languageData?.map((i) => ({
                    value: i?.code,
                    label:
                      i?.code +
                      " - " +
                      i?.names
                        .filter((n) => n?.locale === "en")
                        .map(({ name }) => name),
                  }))
                : languageLocaleObject(
                    languageData,
                    Object.keys(tableData?.localizations || [])
                  )
            }
            label={t("generalTermsLocalizationModal.codeInputTitle")}
            placeholder={t(
              "generalTermsLocalizationModal.codeInputPlaceholder"
            )}
            classNames={classes}
            searchable
            {...form.getInputProps("locale")}
          />
          <TextInput
            label={t("goldmanGraffTeam.title")}
            placeholder={t("goldmanGraffTeam.title")}
            classNames={classes}
            {...form.getInputProps("title")}
          />
          <Textarea
            label={t("goldmanGraffTeam.description")}
            placeholder={t("goldmanGraffTeam.description")}
            minRows={10}
            classNames={classes}
            {...form.getInputProps("description")}
          />

          <div className={classes.buttonContainer}>
            <Button type="submit" className={classes.insertButton}>
              {t("modalCommon.saveButton")}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default GoldmanGraffTeamLocalizationModal;
