import {
  Button,
  Card,
  Divider,
  Group,
  SimpleGrid,
  Table,
  Text,
  TextInput,
  Textarea,
  createStyles,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Download } from "tabler-icons-react";
import axios from "../../../http/axios";
import { showNotification } from "@mantine/notifications";
import { useState } from "react";

const useStyles = createStyles((theme) => ({
  input: {
    height: "auto",
    paddingTop: 18,
  },
  insertButton: {
    alignSelf: "flex-end",
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
  },
  root: { marginTop: 10 },
  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: theme.spacing.sm / 2,
    zIndex: 1,
  },
  insertButton: {
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
    color: theme.colors.colorWhite,
  },
  tableIconsButton: {
    padding: "0px",
    "&:hover": {
      backgroundColor: theme.colors.colorGray,
    },
  },
  tableIconsLogo: {
    color: theme.colors.colorDarkGray,
    "&:hover": {
      color: theme.colors.colorBlack,
    },
  },
}));

const ApplicantSection = (props) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { detailsData } = props;

  const [buttonIndex, setButtonIndex] = useState({ disabledButtonIndex: -1 });

  const dataObj = {
    customerGivenName: detailsData?.customerName?.customerGivenName
      ? detailsData?.customerName?.customerGivenName
      : "",
    customerFamilyName: detailsData?.customerName?.customerFamilyName
      ? detailsData?.customerName?.customerFamilyName
      : "",
    customerBusinessName: detailsData?.customerName?.customerBusinessName
      ? detailsData?.customerName?.customerBusinessName
      : "",
    customerEntityType: detailsData?.customerEntityType
      ? detailsData?.customerEntityType
      : "",
    userNote: detailsData?.userNote ? detailsData?.userNote : "",
    serialNumber: detailsData?.serialNumber ? detailsData?.serialNumber : "",
    productCategory: !!detailsData?.productCategory
      ? detailsData?.productCategory?.names.find((n) => n.locale === "en")?.name
      : "",
    manufacturer: !!detailsData?.manufacturer?.name
      ? detailsData?.manufacturer?.name
      : "",
    productSkuId: !!detailsData?.product?.skuId
      ? detailsData?.product?.skuId
      : "",
    productSkuName: detailsData?.product?.skuName
      ? detailsData?.product?.skuName
      : "",
    documentation: detailsData?.documentation || [],
  };

  const download = async (index, fileId, name) => {
    setButtonIndex({ ...buttonIndex, disabledButtonIndex: index });

    await axios
      .get(`aurodomus-file/api/v1/files/${fileId}`, {
        responseType: "blob",
      })
      .then((res) => {
        const data = res.data;
        const objectUrl = URL.createObjectURL(data);

        const link = document.createElement("a");

        link.setAttribute("href", objectUrl);
        link.setAttribute("download", name);
        link.style.display = "none";

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);

        showNotification({
          message: t("ordersDetailsTable.downloadSuccessfully"),
          color: "green",
        });
      })
      .catch(() => {
        showNotification({
          message: t("ordersDetailsTable.downloadError"),
          color: "red",
        });
      });
    setButtonIndex({ ...buttonIndex, disabledButtonIndex: -1 });
  };
  return (
    <>
      <Divider
        size="xl"
        my="xs"
        label={t("buybackDetails.applicant")}
        labelPosition="center"
      />
      <SimpleGrid cols={3}>
        <Card withBorder>
          <Text weight={500}>{t("buybackDetails.customerInfo")}</Text>
          <TextInput
            classNames={classes}
            readOnly
            label={t("buybackDetails.customerName")}
            defaultValue={dataObj?.customerGivenName + " " + dataObj?.customerFamilyName}
          />
          <TextInput
            classNames={classes}
            readOnly
            label={t("buybackDetails.customerBusinessName")}
            defaultValue={dataObj?.customerBusinessName}
          />
          <TextInput
            classNames={classes}
            readOnly
            label={t("buybackDetails.customerEntityType")}
            defaultValue={dataObj?.customerEntityType}
          />
          <Textarea
            classNames={classes}
            readOnly
            minRows={4}
            label={t("buybackDetails.userNote")}
            defaultValue={dataObj?.userNote}
          />
        </Card>
        <Card withBorder>
          <Text weight={500}>{t("buybackDetails.productInfo")}</Text>
          <TextInput
            classNames={classes}
            readOnly
            label={t("buybackDetails.serialNumber")}
            defaultValue={dataObj?.serialNumber}
          />
          <TextInput
            classNames={classes}
            readOnly
            label={t("buybackDetails.productCategory")}
            defaultValue={dataObj?.productCategory}
          />

          <TextInput
            classNames={classes}
            readOnly
            label={t("buybackDetails.manufacturer")}
            defaultValue={dataObj?.manufacturer}
          />
          <TextInput
            classNames={classes}
            readOnly
            label={t("buybackDetails.productSkuId")}
            defaultValue={dataObj?.productSkuId}
          />
          <Textarea
            classNames={classes}
            minRows={4}
            readOnly
            label={t("buybackDetails.productSkuName")}
            defaultValue={dataObj?.productSkuName}
          />
        </Card>
        <Card withBorder>
          <Text weight={500}>{t("buybackDetails.documentation")}</Text>
          <Table>
            <thead>
              <tr>
                <th>{t("buybackDetails.value")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {dataObj?.documentation.map((n, index) => (
                <tr key={index}>
                  <td>{n?.docCategory}</td>
                  <td>
                    <Group position="right">
                      <Button
                        loading={buttonIndex?.disabledButtonIndex === index}
                        className={classes.insertButton}
                        compact
                        onClick={() => download(index, n?.fileId, n?.docCategory)}
                      >
                        <Download />
                      </Button>
                    </Group>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </SimpleGrid>
    </>
  );
};

export default ApplicantSection;
