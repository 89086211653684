import {
  Button,
  Group,
  ScrollArea,
  Table,
  Text,
  createStyles,
} from "@mantine/core";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Pencil } from "tabler-icons-react";
import {
  fetchCompositionParametersByKey,
  resetCompositionParameters,
} from "../../store/slices/webComposition";
import TableCell from "../common/TableCell";
import WebCompositionModal from "./WebCompositionModal";

const useStyles = createStyles((theme) => ({
  th: {
    padding: "0 !important",
  },
  control: {
    width: "100%",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  scrollArea: {
    height: "85vh",
  },
  searchAndButtonContainter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
  },

  searchInput: {
    width: "25vw",
    marginBottom: "0px !important",
  },
  searchInputMargin: {
    marginBottom: "0px",
  },
  thead: {
    borderBottom: `1px solid #dee2e6`,
  },
  tableIconsButton: {
    padding: "0px",
    "&:hover": {
      backgroundColor: theme.colors.colorGray,
    },
  },
  tableIconsLogo: {
    color: theme.colors.colorDarkGray,
    "&:hover": {
      color: theme.colors.colorBlack,
    },
  },
}));

const WebCompositionTable = ({ tableData }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [modalVisible, setModalVisible] = useState(false);
  const [sectionName, setSectionName] = useState("");
  const [sectionKey, setSectionKey] = useState("");
  const dispatch = useDispatch();

  const openModal = async (key, name) => {
    await dispatch(fetchCompositionParametersByKey(key)).unwrap();
    setSectionKey(key);
    setSectionName(name);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    dispatch(resetCompositionParameters());
  };

  const rows = useMemo(() => {
    return tableData.map((row) => (
      <tr key={row.key}>
        {TableCell(row.name)}
        {TableCell(
          JSON.parse(row.value).map((item, index) => {
            if (index === 0) {
              return item;
            } else {
              return " " + item;
            }
          })
        )}
        <td className={classes.buttonColumn}>
          <Group position="right" className={classes.buttonContainer}>
            <Button
              className={classes.tableIconsButton}
              variant="subtle"
              onClick={() => openModal(row.key, row.name)}
            >
              <Pencil className={classes.tableIconsLogo} />
            </Button>
          </Group>
        </td>
      </tr>
    ));
  }, [tableData]);

  return (
    <>
      <ScrollArea className={classes.scrollArea}>
        <div className={classes.searchAndButtonContainter}></div>
        <Table
          horizontalSpacing="md"
          verticalSpacing="xs"
          sx={{ tableLayout: "fixed", minWidth: 700 }}
        >
          <thead>
            <tr>
              <Th>{t("webComposition.page")}</Th>
              <Th>{t("webComposition.sections")}</Th>
              <th className={classes.buttonColumn}></th>
            </tr>
          </thead>
          <tbody>
            {rows.length > 0 ? (
              rows
            ) : (
              <tr>
                <td colSpan={2}>
                  <Text weight={500} align="center">
                    {t("table.nothingFound")}
                  </Text>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </ScrollArea>
      <WebCompositionModal
        opened={modalVisible}
        onClose={closeModal}
        compositionBlockName={sectionName}
        compositionBlockKey={sectionKey}
      />
    </>
  );
};

export default WebCompositionTable;

const Th = ({ children }) => {
  const { classes } = useStyles();
  return (
    <th className={classes.th}>
      <div className={classes.control}>
        <Group position="apart">
          <Text weight={500} size="sm">
            {children}
          </Text>
        </Group>
      </div>
    </th>
  );
};
