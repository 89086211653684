import { createStyles, Text } from "@mantine/core";

import { useTranslation } from "react-i18next";
import { useState, useEffect, useCallback } from "react";
import { DatePicker } from "@mantine/dates";

import UserStatisticCard from "./UserStatisticCard"
import { Calendar } from "tabler-icons-react";
import { useDispatch } from "react-redux";
import { fetchUserPurchaseStatisticsThunk } from "../../store/slices/userPurchaseStatistics";

const useStyles = createStyles(() => ({
  mainContainer: {
    height: "100%",
    width: "100%",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "2rem",
    gap: "1rem"
  },
  titleFont: {
    fontWeight: 500,
    fontSize: "1.75rem"
  },
  datesContainer: {
    display: "flex",
    gap: "2rem"
  },
  dateFieldDiv: {
    display: "flex",
    alignItems: "center",
    gap: "0.75rem"
  }
}));

const UserStatisticLayout = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  
  const [statistics, setStats] = useState({});

  const [startDate, setStartDate] = useState(new Date(
    new Date().getFullYear(), 
    new Date().getMonth(), 
    1
  ),);
  const [endDate, setEndDate] = useState(new Date());

  const fetchStats = useCallback(() => {
    if(startDate && endDate) {
      let _startDate = `${startDate.getFullYear()}-${("0" + (startDate.getMonth() + 1)).slice(-2)}-${("0" + (startDate.getDate())).slice(-2)}`;
      let _endDate = `${endDate.getFullYear()}-${("0" + (endDate.getMonth() + 1)).slice(-2)}-${("0" + (endDate.getDate())).slice(-2)}`;

      return dispatch(fetchUserPurchaseStatisticsThunk({startDate: _startDate, endDate: _endDate}))
        .unwrap()
        .then((stats) => {
          setStats(stats)
        })
    }
  }, [startDate, endDate, dispatch])

  useEffect(()=>{
    fetchStats();
  }, [fetchStats, startDate, endDate]) 

  

  return (
    <div className={classes.mainContainer}>
      <div className={classes.titleContainer}>
        <Text className={classes.titleFont}>{t("userStatistic.title")}</Text>
        <div className={classes.datesContainer}>
          <div className={classes.dateFieldDiv}>
            <span>
              {t("userStatistic.dateFromTitle")}
            </span>
            <DatePicker
              maxDate={endDate}
              clearable={false}
              inputFormat="DD.MM.YYYY"
              onChange={setStartDate}
              icon={<Calendar size={16} />}
              value={startDate}
            />
          </div>

          <div className={classes.dateFieldDiv}>
            <span>
              {t("userStatistic.dateToTitle")}
            </span>
            <DatePicker
              maxDate={new Date()}
              clearable={false}
              inputFormat="DD.MM.YYYY"
              onChange={setEndDate}
              icon={<Calendar size={16} />}
              value={endDate}
            />
          </div>
        </div>
      </div>

      <UserStatisticCard 
        label={t("userStatistic.totalUsers")}
        userCount={statistics?.totalUsers}
        iconName={"Users"}
        backgroundColour={'#002147'}
        fontColour={'#FFFFFF'}
      />

      <UserStatisticCard 
        label={t("userStatistic.tacUsers")}
        userCount={statistics?.totalUsersWithAcceptedTAC}
        iconName={"UserCheck"}
        backgroundColour={'#525C6A'}
        fontColour={'#FFFFFF'}
      />

      <UserStatisticCard 
        label={t("userStatistic.purchasesUsers")}
        userCount={statistics?.totalUsersWithPurchases}
        iconName={"ReportMoney"}
        backgroundColour={'#DCB77A'}
        fontColour={'#FFFFFF'}
      />
    </div>
  );
};

export default UserStatisticLayout;
