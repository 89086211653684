import {
  Button,
  Center,
  Group,
  ScrollArea,
  Table,
  Text,
  TextInput,
  Tooltip,
  UnstyledButton,
  createStyles,
} from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ChevronDown,
  ChevronUp,
  Pencil,
  Plus,
  Search,
  Selector,
  Trash,
  World,
} from "tabler-icons-react";
import {
  deleteHighlightThunk,
  fetchHighlightByIdThunk,
  fetchHighlightsThunk,
} from "../../store/slices/highlights";
import DeleteBanner from "../common/DeleteBanner";
import TableCell from "../common/TableCell";
import HighlightsModal from "./HighlightsModal";
import { showNotification } from "@mantine/notifications";

const useStyles = createStyles((theme) => ({
  th: {
    padding: "0 !important",
  },
  control: {
    width: "100%",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  scrollArea: {
    height: "85vh",
  },
  searchAndButtonContainter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
  },

  searchInput: {
    width: "25vw",
    marginBottom: "0px !important",
  },
  searchInputMargin: {
    marginBottom: "0px",
  },
  thead: {
    borderBottom: `1px solid #dee2e6`,
  },
  tableIconsButton: {
    padding: "0px",
    "&:hover": {
      backgroundColor: theme.colors.colorGray,
    },
  },
  tableIconsLogo: {
    color: theme.colors.colorDarkGray,
    "&:hover": {
      color: theme.colors.colorBlack,
    },
  },
  insertButton: {
    alignSelf: "flex-end",
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
  },
  tableIconsTrash: {
    color: theme.colors.colorRed,
    "&:hover": {
      color: theme.colors.colorRed,
    },
  },
}));

const Th = ({ children, reversed, sorted, onSort }) => {
  const { classes } = useStyles();
  const Icon = sorted ? (reversed ? ChevronUp : ChevronDown) : Selector;
  return (
    <th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group position="apart">
          <Text weight={500} size="sm">
            {children}
          </Text>
          <Center className={classes.icon}>
            <Icon size={14} />
          </Center>
        </Group>
      </UnstyledButton>
    </th>
  );
};

const HighlightsTable = ({ tableData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { classes } = useStyles();

  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState(null);

  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [updateData, setUpdateData] = useState(null);

  const [deleteData, setDeleteData] = useState(null);
  const [deleteBannerVisible, setDeleteBannerVisible] = useState(false);

  const closeInsertModal = () => {
    setModalVisible(false);
    setIsUpdate(false);
    setUpdateData([]);
  };
  const updateModal = (data) => {
    setIsUpdate(true);
    setUpdateData(data);
    setModalVisible(true);
  };

  const searchTableData = (data) => {
    if (search.trim === "") return data;

    const filteredData = data.filter((item) => {
      const searchLower = search.toLowerCase();
      const checkIncludes = (value) =>
        value && value.toString().toLowerCase().includes(searchLower);

      return (
        checkIncludes(item.category) ||
        checkIncludes(item.description) ||
        checkIncludes(item.title) ||
        checkIncludes(item.ordinal) ||
        checkIncludes(item.status)
      );
    });
    return filteredData;
  };

  const handleSearchChange = (event) => {
    const { value } = event.currentTarget;
    setSearch(value);
  };

  const setSorting = (field) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
  };

  const sortTableData = (tableData) => {
    const data = [...tableData];

    const safeCompare = (a, b, key) => {
      if (!a || !a[key]) return -1; // a is "less" if it or its key is undefined
      if (!b || !b[key]) return 1; // b is "less" if it or its key is undefined
      return a[key].toLowerCase().localeCompare(b[key].toLowerCase());
    };

    if (sortBy === "category") {
      data.sort((a, b) => safeCompare(a, b, "category"));
    } else if (sortBy === "title") {
      data.sort((a, b) => safeCompare(a, b, "title"));
    } else if (sortBy === "description") {
      data.sort((a, b) => safeCompare(a, b, "description"));
    } else if (sortBy === "ordinal") {
      data.sort((a, b) => {
        if (!a || !a.ordinal) return -1;
        if (!b || !b.ordinal) return 1;
        return a.ordinal
          .toString()
          .toLowerCase()
          .localeCompare(b.ordinal.toString().toLowerCase());
      });
    } else if (sortBy === "status") {
      data.sort((a, b) => safeCompare(a, b, "status"));
    }

    if (reverseSortDirection) {
      data.reverse();
    }

    return data;
  };

  const onDelete = async () => {
    try {
      await dispatch(deleteHighlightThunk(deleteData.id)).unwrap();
      showNotification({
        message: t("highlights.delete.success"),
        color: "green",
      });
      await dispatch(fetchHighlightsThunk()).unwrap();
    } catch {
      showNotification({
        message: t("highlights.delete.error"),
        color: "red",
      });
    } finally {
      setDeleteBannerVisible(false);
    }
  };

  const rows = useMemo(() => {
    return searchTableData(sortTableData(tableData)).map((row) => (
      <tr key={row?.id}>
        {TableCell(row?.category || t("common.dash"))}
        {TableCell(row?.title || t("common.dash"))}
        {TableCell(row?.description || t("common.dash"))}
        {TableCell(row?.ordinal || t("common.dash"))}
        {TableCell(row?.status || t("common.dash"))}
        <td className={classes.buttonColumn}>
          <Group position="right" className={classes.buttonContainer}>
            <Tooltip label={t("tooltips.localization")}>
              <Button
                variant="subtle"
                className={classes.tableIconsButton}
                onClick={async () => {
                  await dispatch(
                    fetchHighlightByIdThunk(row?.id.toString())
                  ).unwrap();
                  navigate(row.id.toString());
                }}
              >
                <World className={classes.tableIconsLogo} />
              </Button>
            </Tooltip>
            <Tooltip label={t("tooltips.edit")}>
              <Button
                className={classes.tableIconsButton}
                variant="subtle"
                onClick={async () => {
                  console.log(row.id);
                  await dispatch(fetchHighlightByIdThunk(row.id)).unwrap();
                  updateModal(row);
                }}
              >
                <Pencil className={classes.tableIconsLogo} />
              </Button>
            </Tooltip>
            <Tooltip label={t("tooltips.delete")}>
              <Button
                disabled={row.locale === "en" ? true : false}
                variant="subtle"
                className={classes.tableIconsButton}
                onClick={() => {
                  setDeleteBannerVisible(true);
                  setDeleteData(row);
                }}
              >
                <Trash
                  className={
                    row.locale === "en" ? null : classes.tableIconsTrash
                  }
                />
              </Button>
            </Tooltip>
          </Group>
        </td>
      </tr>
    ));
  }, [
    sortBy,
    search,
    reverseSortDirection,
    tableData,
    classes.buttonColumn,
    classes.tableIconsButton,
    classes.tableIconsLogo,
    classes.tableIconsTrash,
    classes.buttonContainer,
    dispatch,
    t,
    navigate,
  ]);

  return (
    <>
      <ScrollArea className={classes.scrollArea}>
        <div className={classes.searchAndButtonContainter}>
          <TextInput
            className={classes.searchInput}
            placeholder={t("table.search")}
            mb="md"
            icon={<Search size={14} />}
            value={search}
            onChange={handleSearchChange}
          />
          <Button
            className={classes.insertButton}
            onClick={() => {
              setIsUpdate(false);
              setModalVisible(true);
            }}
          >
            <Plus size={18} />
          </Button>
        </div>
        <Table
          horizontalSpacing="md"
          verticalSpacing="xs"
          sx={{ tableLayout: "fixed", minWidth: 700 }}
        >
          <thead>
            <tr>
              <Th
                sorted={sortBy === "category"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("category")}
              >
                {t("highlights.category")}
              </Th>
              <Th
                sorted={sortBy === "title"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("title")}
              >
                {t("highlights.title")}
              </Th>
              <Th
                sorted={sortBy === "description"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("description")}
              >
                {t("highlights.description")}
              </Th>
              <Th
                sorted={sortBy === "ordinal"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("ordinal")}
              >
                {t("highlights.ordinalNumber")}
              </Th>
              <Th
                sorted={sortBy === "status"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("status")}
              >
                {t("highlights.status")}
              </Th>

              <th className={classes.buttonColumn}></th>
            </tr>
          </thead>
          <tbody>
            {rows.length > 0 ? (
              rows
            ) : (
              <tr>
                <td colSpan={4}>
                  <Text weight={500} align="center">
                    {t("table.nothingFound")}
                  </Text>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </ScrollArea>
      <HighlightsModal
        opened={modalVisible}
        onClose={closeInsertModal}
        isUpdate={isUpdate}
        updateData={updateData}
        tableData={tableData}
      />
      <DeleteBanner
        message={t("deleteBanner.message")}
        okButtonText={t("deleteBanner.okButton")}
        cancelButtonText={t("deleteBanner.cancelButton")}
        deleteMethod={onDelete}
        centered={true}
        onClose={() => setDeleteBannerVisible(false)}
        opened={deleteBannerVisible}
      />
    </>
  );
};

export default HighlightsTable;
