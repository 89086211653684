import { createStyles } from "@mantine/core";
import CookiePolicyTable from "./CookiePolicyTable";


const useStyles = createStyles((theme) => ({
    mainContainer: {
      height: "100%",
      width: "100%",
    },
   
  }));
  

const CookiePolicy=()=>{
    const { classes } = useStyles();
    return (
      <div className={classes.mainContainer}>
       <CookiePolicyTable/>
      </div>
    );
}


export default CookiePolicy