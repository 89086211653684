import axios from "../axios";

export const getAllPageSeo = async () => {
  let response = await axios.get("gg2/admin/api/v1/page-seo");
  return response.data;
};

export const getPageSeoById = async (id) => {
  const response = await axios.get(`gg2/admin/api/v1/page-seo/${id}`);
  return response.data;
};

export const updatePageSeo = async (key, value) => {
  const response = await axios.put(`gg2/admin/api/v1/page-seo/${key}`, value);
  return response.data;
};
