import { createStyles, Text, TextInput, Button, Group, ScrollArea, Table } from "@mantine/core";

import { useTranslation } from "react-i18next";
import { useState, useEffect, useMemo, useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Pencil, Search, Plus, Trash } from "tabler-icons-react";
import TableCell from "../common/TableCell";
import { getPageSeoByIdThunk, resetSeoParameters, updatePageSeoThunk } from "../../store/slices/WebSeo";
import { useParams } from "react-router-dom";
import WebSeoLocalizationModal from "./WebSeoLocalizationModal";
import DeleteBanner from "../common/DeleteBanner";
import { showNotification } from "@mantine/notifications";

const useStyles = createStyles((theme) => ({
  tableTitle: {
    fontSize: "1.5rem",
    color: "#000000",
    fontWeight: 500,
    marginTop: "1.5rem",
    marginBottom: "1.5rem"
  },
  th: {
    padding: "0 !important",
  },
  control: {
    width: "100%",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },
  scrollArea: {
    height: "95vh",
  },
  searchAndButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
  },
  thead: {
    borderBottom: `1px solid #dee2e6`,
  },
  tableIconsButton: {
    padding: "0px",
    "&:hover": {
      backgroundColor: theme.colors.colorGray,
    },
  },
  tableIconsLogo: {
    color: theme.colors.colorDarkGray,
    "&:hover": {
      color: theme.colors.colorBlack,
    },
  },
  tableIconsTrash: {
    color: theme.colors.colorRed,
    "&:hover": {
      color: theme.colors.colorRed,
    },
  },
  searchInput: {
    width: "25vw",
    marginBottom: "0px !important",
  },
	insertButton: {
    alignSelf: "flex-end",
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
  },
}));

const WebSeoLocalizationLayout = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const [modalVisible, setModalVisible] = useState(false);
  const [deleteBannerVisible, setDeleteBannerVisible] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [selectedLocale, setLocale] = useState({});
  const [selectedLocaleCode, setLocaleCode] = useState("");
  const [isEdit, setIsEdit] = useState(true);
	const tableState = useSelector((state) => state?.webSeo?.webSeo);
  const [tableData, setTableData] = useState({});
  const [unfilteredTableData, setUnfiltered] = useState({});
  const [search, setSearch] = useState("");
  const { id } = useParams()

  const fetchPageSeo = useCallback(() => {
    return dispatch(getPageSeoByIdThunk(id))
      .unwrap()
      .then((seoData) => {
        setUnfiltered(seoData);
        setTableData(seoData);
      })
  }, [dispatch, id])

  const filterTable = useCallback(() => {
    if(search.trim === "") return;
    if(!unfilteredTableData.length) return;

    const filteredData = unfilteredTableData?.filter((item) => {
      const searchLower = search.toLowerCase();
      const checkIncludes = (value) =>
        value && value.toString().toLowerCase().includes(searchLower);
      return (
        checkIncludes(item.code)
      );
    });
    setTableData(filteredData);
  }, [search, unfilteredTableData])

  useEffect(() => {
		if(tableState) {
			setUnfiltered(tableState);
			setTableData(tableState);
		} else {
			fetchPageSeo();
		}
  }, [tableState, fetchPageSeo]);

  useEffect(() => {
    filterTable()
  }, [filterTable]);

  const handleSearchChange = (event) => {
    const { value } = event.currentTarget;
    setSearch(value);
  };

  const openModal = (key, value, isEdit) => {
    setLocaleCode(key);
    setLocale(value);
    setIsEdit(isEdit);
    setModalVisible(true);
  };

  const closeDeleteBanner = () => {
    setDeleteBannerVisible(false);
  };

  const closeModal = () => {
    setModalVisible(false);
    dispatch(resetSeoParameters());
  };

  const deleteLocale = async () => {
    // Deep clone tableData to avoid direct mutation (consider if necessary)
    let obj = JSON.parse(JSON.stringify(tableData));

    // Iterate over localizations to find and delete the matching one
    for (const [langKey, value] of Object.entries(obj.localizations)) {
      if (JSON.stringify(value) === JSON.stringify(deleteData[langKey])) {
        delete obj.localizations[langKey];
        break; // Exit loop after finding the match
      }
    }

    try {
      await dispatch(updatePageSeoThunk(obj)).unwrap();
      // Show success notification
      showNotification({
        message: t("highlights.delete.success"),
        color: "green",
      });
    } catch (e) {
      // Show error notification on failure
      showNotification({
        message: t("highlights.delete.error"),
        color: "red",
      });
    }

    closeDeleteBanner(); // Close the delete confirmation banner
  };

  const rows = useMemo(() => {
    if(!tableData.localizations) return;
    let rows = [];

    for (const [key, value] of Object.entries(tableData.localizations)) {
      rows.push(
        <tr key={key}>
          {TableCell(key)}
          <td className={classes.buttonColumn}>
            <Group position="right" className={classes.buttonContainer}>
              <Button
                className={classes.tableIconsButton}
                variant="subtle"
                onClick={() => openModal(key, value, true)}
              >
                <Pencil className={classes.tableIconsLogo} />
              </Button>

              <Button
                variant="subtle"
                className={classes.tableIconsButton}
                onClick={() => {
                  setDeleteBannerVisible(true);

                  setDeleteData({ [key]: value });
                }}
              >
                <Trash className={classes.tableIconsTrash} />
              </Button>
            </Group>
          </td>
        </tr>
      )
    }
    return rows;
  }, [tableData, classes]);

  return (
    <>
      <div className={classes.searchAndButtonContainer}>
        <TextInput
          className={classes.searchInput}
          placeholder={t("table.search")}
          icon={<Search size={14} />}
          defaultValue={search}
          onChange={handleSearchChange}
        />

				<Button
					className={classes.insertButton}
					onClick={() => {
						openModal(undefined, undefined, false)
					}}
				>
					<Plus size={18} />
				</Button>
      </div>
      <ScrollArea className={classes.scrollArea}>
        <div className={classes.tableTitle}>
          {tableData.code}
        </div>

        <Table
          horizontalSpacing="md"
          verticalSpacing="xs"
          sx={{ tableLayout: "fixed", minWidth: 700 }}
        >
          <tbody>
            {rows?.length > 0 ? (
              rows
            ) : (
              <tr>
                <td colSpan={2}>
                  <Text weight={500} align="center">
                    {t("table.nothingFound")}
                  </Text>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </ScrollArea>

      <WebSeoLocalizationModal
        opened={modalVisible}
        isEdit={isEdit}
        onClose={closeModal}
        selectedLocale={selectedLocale}
        selectedLocaleCode={selectedLocaleCode}
      />

      <DeleteBanner
        deleteMethod={deleteLocale}
        centered={true}
        opened={deleteBannerVisible}
        onClose={closeDeleteBanner}
        message={t("deleteBanner.message")}
        okButtonText={t("deleteBanner.okButton")}
        cancelButtonText={t("deleteBanner.cancelButton")}
      />
    </>
  );
};

export default WebSeoLocalizationLayout;
