export const compositionDataByKey = {
  UI_WEB_BLOG_POST_COMPOSITION: [
    "gg-blog-view",
    "gg-newsletter",
    "gg-download",
  ],
  UI_WEB_BLOG_LISTING_COMPOSITION: ["gg-blogs", "gg-newsletter", "gg-download"],
  UI_WEB_SERVICES_COMPOSITION: [
    "gg-services-view",
    "gg-customer-support",
    "gg-newsletter",
  ],
  UI_WEB_PRODUCT_LISTING_COMPOSITION: [
    "gg-latest-offers",
    "gg-product-grid",
    "gg-stock-market-price",
    "gg-users-guaranteed",
    "gg-become-user",
    "gg-customer-support",
    "gg-product-bestsellers",
    "gg-services-info",
    "gg-latest-blog-posts",
    "gg-newsletter",
    "gg-download",
    "gg-highlights",
    "gg-calculator-ad",
  ],
  UI_WEB_HOME_COMPOSITION: [
    "gg-latest-offers",
    "gg-products",
    "gg-stock-market-price",
    "gg-price-history-charts",
    "gg-users-guaranteed",
    "gg-become-user",
    "gg-customer-support",
    "gg-product-bestsellers",
    "gg-download",
    "gg-services-info",
    "gg-latest-blog-posts",
    "gg-newsletter",
    "gg-highlights",
    "gg-calculator-ad",
  ],
  UI_WEB_WISHLIST_COMPOSITION: [
    "gg-related-products",
    "gg-wishlist-items",
    "gg-product-bestsellers",
    "gg-stock-market-price",
    "gg-users-guaranteed",
    "gg-become-user",
    "gg-customer-support",
    "gg-newsletter",
    "gg-download",
  ],
  UI_WEB_PRICE_HISTORY_COMPOSITION: [
    "gg-stock-market-price",
    "gg-price-history-charts",
    "gg-product-bestsellers",
    "gg-become-user",
    "gg-services-info",
    "gg-latest-blog-posts",
    "gg-newsletter",
    "gg-download",
    "gg-calculator-ad",
  ],
  UI_WEB_PRODUCT_DETAILS_COMPOSITION: [
    "gg-related-products",
    "gg-product-bestsellers",
    "gg-details-view",
    "gg-stock-market-price",
    "gg-users-guaranteed",
    "gg-become-user",
    "gg-customer-support",
    "gg-newsletter",
    "gg-download",
    "gg-calculator-ad",
  ],
  UI_WEB_CHECKOUT_COMPOSITION: [
    "gg-related-products",
    "gg-product-bestsellers",
    "gg-checkout-view",
    "gg-stock-market-price",
    "gg-users-guaranteed",
    "gg-become-user",
    "gg-customer-support",
    "gg-newsletter",
    "gg-download",
  ],
  UI_WEB_ABOUT_US_COMPOSITION: [
    "gg-about-us",
    "gg-customer-support",
    "gg-newsletter",
  ],
  UI_WEB_CONTACT_COMPOSITION: [
    "gg-contact",
    "gg-customer-support",
    "gg-newsletter",
  ],
  UI_WEB_SERVICE_BUYBACK_COMPOSITION: [
    "gg-service-buyback",
    "gg-users-guaranteed",
    "gg-become-user",
    "gg-customer-support",
  ],
  UI_WEB_SERVICE_COUNSELING_COMPOSITION: [
    "gg-service-counseling",
    "gg-customer-support",
  ],
  UI_WEB_SERVICE_DELIVERY_COMPOSITION: [
    "gg-service-delivery",
    "gg-customer-support",
  ],
  UI_WEB_SERVICE_STORAGE_COMPOSITION: [
    "gg-service-storage",
    "gg-customer-support",
  ],
  UI_WEB_SEARCH_RESULTS_COMPOSITION: ["gg-search-results", "gg-download"],
  UI_WEB_CALCULATOR_COMPOSITION: [
    "gg-calculator",
    "gg-product-bestsellers",
    "gg-become-user",
    "gg-services-info",
    "gg-latest-blog-posts",
    "gg-newsletter",
    "gg-download",
  ],
};
