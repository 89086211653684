import axios from "../axios";

export const getACSIdentityAndTokens = async () => {
  const response = await axios.get(
    "/aurodomus-comms/api/v1/identities/current"
  );
  return response.data;
};

export const getChatThreadsByCustomer = async (args) => {
  const response = await axios.get("/aurodomus-comms/api/v1/chat/threads", {
    params: {
      noneOfParticipantType: args?.noneOfParticipantType,
      participantName: args?.participantName || "",
      sortBy: args?.sortBy.toString(),
      archived:args?.archived,
      page:args?.page?args.page:null,
      size:args?.size?args.size:null,
    },
  });
  return response.data;
};
export const getChatThreadsByAgent = async (args) => {
  const response = await axios.get("/aurodomus-comms/api/v1/chat/threads", {
    params: {
      participantIdentityId: args?.participantIdentityId,
      hasAnyOfParticipantType: args?.hasAnyOfParticipantType,
      participantName: args?.participantName || "",
      archived:args?.archived,
      page:args?.page?args.page:null,
      size:args?.size?args.size:null,
      sortBy: args?.sortBy.toString(),
    },
  });
  return response.data;
};

export const getChatThreadsArchive = async (args) => {
  const response = await axios.get("/aurodomus-comms/api/v1/chat/threads", {
    params: {
     
      participantName: args?.participantName || "",
      archived:args?.archived,
      sortBy: args?.sortBy.toString(),
      page:args.page,
      size:args.size
    },
  });
  return response.data;
};
export const getChatThreadsById = async (args) => {
  const response = await axios.get(
    `/aurodomus-comms/api/v1/chat/threads/${args.id}`
  );
  return response.data;
};

export const getChatThreadsMsgs = async (args) => {
  const response = await axios.get(
    `/aurodomus-comms/api/v1/chat/threads/${args.id}/messages`,
    {
      params: {
        startTime: args.startTime,
        participantIdentityId: args.participantIdentityId,
      },
    }
  );
  return response.data;
};

export const createChatThreadsMsg = async (args) => {
  const response = await axios.post(
    `/aurodomus-comms/api/v1/chat/threads/${args.id}/messages`,
    {
      content: args.content,
      senderIdentityId: args.senderIdentityId,
    }
  );
  return response.data;
};

export const joinChatThread = async (args) => {
  const response = await axios.put(
    `/aurodomus-comms/api/v1/chat/threads/${args.id}/join`,
    {
      participantName: args.participantName,
      participantChatDisplayName: args.participantChatDisplayName,
    }
  );
  return response.data;
};

export const leaveChatThread = async (args) => {
  const response = await axios.put(
    `/aurodomus-comms/api/v1/chat/threads/${args.id}/leave`
  );
  return response.data;
};
