import { ChatClient } from "@azure/communication-chat";
import { AzureCommunicationTokenCredential } from "@azure/communication-common";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Group,
  Loader,
  ScrollArea,
  Text,
  TextInput,
  createStyles,
} from "@mantine/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { X } from "tabler-icons-react";
import {
  createChatThreadsMsgThunk,
  fetchChatThreadsMsgsThunk,
} from "../../../store/slices/chat";
import * as Yup from "yup";
import config from "../../../config";
import dayjs from "dayjs";
import images from "../../../assets/images";
import { useForm, yupResolver } from "@mantine/form";
import { useTranslation } from "react-i18next";

import InfoBanner from "./InfoBanner";

const useStyles = createStyles((theme) => ({
  mainChatDiv: {
    height: "100vh",
  },
  scrollArea: {
    borderRight: "1px solid #EBEEF0",
    height: "90vh",
    overflowY: "auto",
    position: "relative",
  },
  systemDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    opacity: 0.5,
    flexWrap: "inherit",
  },
  btnDiv: {
    background: "#000000",
    borderRadius: 16,
  },
  agentDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "inherit",
  },
  agentCard: {
    background: "#CCD2DA",
    width: "50%",
    borderRadius: 30,
    marginLeft: 10,
    marginTop: 10,
    marginBottom: 10,
    overflowWrap: "anywhere",
  },

  userDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "inherit",
  },
  userCard: {
    background: "#EBEEF0",
    borderRadius: 30,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10,
    overflowWrap: "anywhere",
  },
  chatHeader: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: "#FFFFFF",
    padding: 16,
  },

  chatHeaderDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  chatMsgsDiv: {
    display: "flex",
    flexDirection: "column-reverse",
    alignItems: "flex-start",
  },
  iconMsgsDiv: {
    display: "flex",
  },
  imgDiv: {
    display: "flex",
    height: 35,
    width: 35,
    borderRadius: "50%",
    background: "#A7ADB9",
    marginRight: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  loaderDiv: {
    alignItems: "center",
    alignSelf: "center",
    marginLeft: "50%",
  },
  formDiv: {
    display: "flex",
    background: "#EAEBEC",
    height: "10vh",
    justifyContent: "center",
    alignItems: "center",
  },
  btnSubmit: {
    borderRadius: "50%",
    width: 35,
    height: 35,
    padding: 0,
    marginLeft: 5,
  },
}));

const Chat = (props) => {
  const { classes } = useStyles();
  const { openChat } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const bottomRef = useRef(null);

  const [checked, setChecked] = useState(false);

  const acsIdentityAndTokens = useSelector(
    (state) => state.chat.acsIdentityAndTokens
  );
  const chatThreadDataById = useSelector(
    (state) => state?.chat?.chatThreadDataById || ""
  );

  const chatThreadMsgs = useSelector(
    (state) => state?.chat?.chatThreadMsgs || []
  );
  const status = useSelector((state) => state.chat.status);

  const userAccessToken = acsIdentityAndTokens?.commsToken;
  const acsEndpointUrl = config.REACT_APP_ACS_URL;

  const schema = Yup.object().shape({
    content: Yup.string().required(t("customerSupport.contentRequired")),
  });

  const form = useForm({
    initialValues: {
      content: "",
    },
    validate: yupResolver(schema),
  });

  const chatClient = React.useMemo(() => {
    return new ChatClient(
      acsEndpointUrl,
      new AzureCommunicationTokenCredential(userAccessToken)
    );
  }, [userAccessToken, acsEndpointUrl]);

  useEffect(() => {
    // open notifications channel
    chatClient.startRealtimeNotifications().then(() => {
      // subscribe to new notification
      chatClient.on("chatMessageReceived", async (e) => {
        if (
          chatThreadDataById.chatThreadId === e.threadId &&
          e.type === "Text"
        ) {
          dispatch(
            fetchChatThreadsMsgsThunk({
              id: chatThreadDataById?.id,
              startTime: chatThreadDataById?.createdOn,
              participantIdentityId: acsIdentityAndTokens?.id,
            })
          ).unwrap();
        }
      });
    });

    return () => {
      chatClient.stopRealtimeNotifications();
    };
  }, [chatThreadDataById?.id]);

  useEffect(() => {
    bottomRef?.current?.scrollIntoView();
  }, [chatThreadMsgs]);

  const submitForm = async (data) => {
    await dispatch(
      createChatThreadsMsgThunk({
        id: chatThreadDataById?.id,
        content: data?.content,
        senderIdentityId: acsIdentityAndTokens?.id,
      })
    ).unwrap();
    form.reset();
  };

  const checkMsgType = (msg) => {
    switch (true) {
      case msg?.metadata?.messageSenderType === "SYSTEM":
        return (
          <Box className={classes.systemDiv}>
            <Text weight={500}>{msg?.content?.message}</Text>
          </Box>
        );

      case msg?.metadata?.messageSenderType === "AGENT":
        return (
          <Box className={classes.agentDiv}>
            <Card className={classes.agentCard}>
              <Box className={classes.iconMsgsDiv}>
                <Box className={classes.imgDiv}>
                  <img src={images.agent} style={{ height: 20, width: 35 }} />
                </Box>
                <Box>
                  <Text weight={500}>{msg?.senderDisplayName}</Text>

                  <Text>{msg?.content?.message}</Text>
                </Box>
              </Box>
              <Text
                size={"xs"}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                {dayjs(msg.createdOn).format("DD.MM.YYYY HH:mm")}
              </Text>
            </Card>
          </Box>
        );

      case msg?.metadata?.messageSenderType === "CUSTOMER":
        return (
          <Box className={classes.userDiv}>
            <Card className={classes.userCard}>
              <Box className={classes.iconMsgsDiv}>
                <Box className={classes.imgDiv}>
                  <img src={images.user} style={{ height: 20, width: 35 }} />
                </Box>
                <Box>
                  <Text weight={500}>{msg?.senderDisplayName}</Text>

                  <Text>{msg?.content?.message}</Text>
                </Box>
              </Box>
              <Text
                size={"xs"}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                {dayjs(msg.createdOn).format("DD.MM.YYYY HH:mm")}
              </Text>
            </Card>
          </Box>
        );
      default:
        return <div />;
    }
  };

  return (
    <div className={classes.mainChatDiv}>
      <ScrollArea className={classes.scrollArea}>
        <Group className={classes.chatHeader} position="apart">
          <Text weight={650} size={"lg"}>
            {
              chatThreadDataById?.participants?.find(
                (t) => t?.type === "CUSTOMER"
              )?.name
            }
          </Text>
          <Box className={classes.chatHeaderDiv}>
            <Button
              className={classes.btnDiv}
              color="dark"
              onClick={() => setChecked(true)}
            >
              {t("customerSupport.finishChat")}
            </Button>
            {/* <Checkbox
              sx={() => ({
                ".mantine-Checkbox-input:checked": {
                  background: "#000000",
                  borderColor: "#000000",
                },
              })}
              checked={checked}
              onChange={(event) => setChecked(event.currentTarget.checked)}
            /> */}

            <Button
              compact
              color="dark"
              variant="subtle"
              onClick={() => {
                openChat(false);
              }}
            >
              <X />
            </Button>
          </Box>
        </Group>
        <Box className={classes.chatMsgsDiv}>
          {chatThreadMsgs?.map((msg) => (
            <Box style={{ display: "flex", width: "100%" }} key={msg?.id}>
              {checkMsgType(msg)}
            </Box>
          ))}
        </Box>
        {checked && (
          <InfoBanner
            setChecked={setChecked}
            chatThreadDataById={chatThreadDataById}
            participantIdentityId={acsIdentityAndTokens}
            openChat={openChat}
          />
        )}
        {status === "pending" && (
          <div className={classes.loaderDiv}>
            <Loader />
          </div>
        )}
        <div ref={bottomRef} />
      </ScrollArea>
      <form onSubmit={form.onSubmit(submitForm)}>
        <Box className={classes.formDiv}>
          {" "}
          <TextInput
            style={{ width: "90%" }}
            {...form.getInputProps("content")}
          />
          <Button className={classes.btnSubmit} type="submit" color="dark">
            <img src={images.iosSend} height={15} />
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default Chat;
