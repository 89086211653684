import { createStyles } from "@mantine/core";
import PrivacyStatementTable from "./PrivacyStatementTable";

const useStyles = createStyles((theme) => ({
  mainContainer: {
    height: "100%",
    width: "100%",
  },

}));

const PrivacyStatement = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.mainContainer}>
      <PrivacyStatementTable />
    </div>
  );
};

export default PrivacyStatement;
