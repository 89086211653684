import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { chatApi } from "../../http/services";

const initialState = {
  openedArchive: false,
  totalPages: 0,
  page: 0,
  totalPagesCustomer: 0,
  totalPagesArchive:0,
  pageAgent: 0,
  pageCustomer: 0,
  pageArchive:0,
  status: "",
  statusAgent: "",
  statusCustomer: "",
  statusArchive: "",
  acsIdentityAndTokens: {
    commsUserId: "",
    commsToken: "",
    commsTokenExpiresAt: "",
    id: "",
  },
  chatThreadDataByAgent: [],
  chatThreadDataByCustomer: [],
  chatThreadArchive: [],
  chatThreadDataById: null,
  chatThreadMsgs: [],
};

export const fetchACSIdentityAndTokensThunk = createAsyncThunk(
  "chat/getACSIdentityAndTokens",
  async () => {
    return await chatApi.getACSIdentityAndTokens();
  }
);
export const fetchChatThreadsByAgentThunk = createAsyncThunk(
  "chat/getChatThreadsByAgent",
  async (args) => {
   
    return await chatApi.getChatThreadsByAgent(args);
  }
);

export const fetchChatThreadsByAgentSearchThunk = createAsyncThunk(
  "chat/getChatThreadsByAgentSearch",
  async (args) => {
    return await chatApi.getChatThreadsByAgent(args);
  }
);
export const fetchChatThreadsByCustomerSearchThunk = createAsyncThunk(
  "chat/getChatThreadsByCustomerSearch",
  async (args) => {
    return await chatApi.getChatThreadsByCustomer(args);
  }
);
export const fetchChatThreadsByCustomerThunk = createAsyncThunk(
  "chat/getChatThreadsByCustomer",
  async (args) => {
    return await chatApi.getChatThreadsByCustomer(args);
  }
);

export const fetchChatThreadsByArchiveThunk = createAsyncThunk(
  "chat/getChatThreadsArchive",
  async (args) => {
    return await chatApi.getChatThreadsArchive(args);
  }
);
export const fetchChatThreadsArchiveSearch = createAsyncThunk(
  "chat/getChatThreadsArchiveSearch",
  async (args) => {
    return await chatApi.getChatThreadsArchive(args);
  }
);

export const fetchChatThreadsMsgsThunk = createAsyncThunk(
  "chat/getChatThreadsMsgs",
  async (args) => {
    const response=await chatApi.getChatThreadsMsgs(args)
    return response?.filter((n) => n?.type === "text");
  }
);

export const fetchChatThreadsThunkById = createAsyncThunk(
  "chat/getChatThreadsById",
  async (args) => {
    return await chatApi.getChatThreadsById(args);
  }
);

export const createChatThreadsMsgThunk = createAsyncThunk(
  "chat/createChatThreadsMsgs",
  async (args) => {
    return await chatApi.createChatThreadsMsg(args);
  }
);

export const joinChatThreadThunk = createAsyncThunk(
  "chat/joinChatThread",
  async (args) => {
    return await chatApi.joinChatThread(args);
  }
);

export const leaveChatThreadThunk = createAsyncThunk(
  "chat/leaveChatThread",
  async (args) => {
    return await chatApi.leaveChatThread(args);
  }
);

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setOpenedArchive: (state, action) => {
      return { ...state, openedArchive: action.payload };
    },
    clearData: (state) => {
      return { ...state, chatThreadDataByAgent: [] };
    },
    setPage: (state, action) => {
      return { ...state, page: action.payload };
    },
   
    clearDataCustomer: (state) => {
      return { ...state, chatThreadDataByCustomer: [] };
    },

    setPageCustomer: (state, action) => {
      return { ...state, pageCustomer: action.payload };
    },
    setPageArchive: (state, action) => {
      return { ...state, pageArchive: action.payload };
    },
    clearDataArchive: (state) => {
      return { ...state, chatThreadArchive: [] };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchACSIdentityAndTokensThunk.pending, (state) => {
      return { ...state };
    });
    builder.addCase(
      fetchACSIdentityAndTokensThunk.fulfilled,
      (state, action) => {
        return {
          ...state,
          status: "fulfilled",
          acsIdentityAndTokens: {
            ...state.acsIdentityAndTokens,
            commsUserId: action.payload.commsUserId,
            commsToken: action.payload.commsToken,
            commsTokenExpiresAt: action.payload.commsTokenExpiresAt,
            id: action.payload.id,
          },
        };
      }
    );
    builder.addCase(fetchACSIdentityAndTokensThunk.rejected, (state) => {
      return { ...state };
    });
    builder.addCase(fetchChatThreadsByAgentThunk.pending, (state) => {
      return { ...state, statusAgent: "pending" };
    });
    builder.addCase(fetchChatThreadsByAgentThunk.fulfilled, (state, action) => {
      
      return {
        ...state,
        statusAgent: "fulfilled",
        totalPages: action.payload.totalPages,
        chatThreadDataByAgent:
          action.meta.arg.page !== 0
            ? [...state.chatThreadDataByAgent, ...action.payload.content]
            : action.payload.content,
      };
    });
    builder.addCase(fetchChatThreadsByAgentSearchThunk.pending, (state) => {
      return { ...state, statusAgent: "pending" };
    });
    builder.addCase(
      fetchChatThreadsByAgentSearchThunk.fulfilled,
      (state, action) => {
        return {
          ...state,
          statusAgent: "fulfilled",
          totalPages: action.payload.totalPages,
          chatThreadDataByAgent: action.payload.content,
        };
      }
    );

    builder.addCase(fetchChatThreadsByCustomerSearchThunk.pending, (state) => {
      return { ...state, statusCustomer: "pending" };
    });
    builder.addCase(
      fetchChatThreadsByCustomerSearchThunk.fulfilled,
      (state, action) => {
        return {
          ...state,
          statusCustomer: "fulfilled",
          totalPagesCustomer: action.payload.totalPages,
          chatThreadDataByCustomer: action.payload.content,
        };
      }
    );
    builder.addCase(fetchChatThreadsByCustomerThunk.pending, (state) => {
      return { ...state, statusCustomer: "pending" };
    });
    builder.addCase(
      fetchChatThreadsByCustomerThunk.fulfilled,
      (state, action) => {
       
        return {
          ...state,
          statusCustomer: "fulfilled",
          chatThreadDataByCustomer:
            action.meta.arg.page !== 0
              ? [...state.chatThreadDataByCustomer, ...action.payload.content]
              : action.payload.content,
          totalPagesCustomer: action.payload.totalPages,
        };
      }
    );
    builder.addCase(fetchChatThreadsByArchiveThunk.pending, (state) => {
      return { ...state, statusArchive: "pending" };
    });
    builder.addCase(
      fetchChatThreadsByArchiveThunk.fulfilled,
      (state, action) => {
        return {
          ...state,
          statusArchive: "fulfilled",
          chatThreadArchive:
            action.meta.arg.page !== 0
              ? [...state.chatThreadArchive, ...action.payload.content]
              : action.payload.content,
              totalPagesArchive:action.payload.totalPages
        };
      }
    );
    builder.addCase(fetchChatThreadsArchiveSearch.pending, (state) => {
      return { ...state, statusArchive: "pending" };
    });
    builder.addCase(
      fetchChatThreadsArchiveSearch.fulfilled,
      (state, action) => {
        return {
          ...state,
          statusArchive: "fulfilled",
          totalPagesArchive: action.payload.totalPagesArchive,
          chatThreadArchive: action.payload.content,
        };
      }
    );
    builder.addCase(fetchChatThreadsThunkById.pending, (state, action) => {
      return {
        ...state,
        status: "pending",
      };
    });
    builder.addCase(fetchChatThreadsThunkById.fulfilled, (state, action) => {
      return {
        ...state,
        status: "fulfilled",
        chatThreadDataById: action.payload,
      };
    });
    builder.addCase(fetchChatThreadsMsgsThunk.pending, (state, action) => {
      return {
        ...state,
        status: "pending",
      };
    });
    builder.addCase(fetchChatThreadsMsgsThunk.fulfilled, (state, action) => {
      return {
        ...state,
        status: "fulfilled",
        chatThreadMsgs: action.payload,
      };
    });
  },
});
const { actions, reducer } = chatSlice;
export const {
  setOpenedArchive,
  clearData,
  clearDataCustomer,
  setPage,
  setPageCustomer,
  setPageArchive,
  clearDataArchive
} = actions;
export { reducer as chatReducer };
